import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { blankLink } from 'config';
import { JsonObject } from '@cover42/protobuf-util';
import { TooltipCore } from './TooltipCore';
import { renderLabelHtml } from '../../core-hooks';
import { useLocale } from '../../../booking-funnel/booking-funnel-hooks';

export interface CoreLabelProps {
  leadData?: ILeadData;
  stepItem: ContainerFieldItem;
  className: string;
  style?: JsonObject;
}

export const CoreLabel: React.FC<CoreLabelProps> = ( { leadData, stepItem, className, style } ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const locale = useLocale();

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltip: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  return (
    <div className={ `label-text ${className}` }
      style={ style }
    >
      <div className={ stepItem.tooltip ? 'tooltip-label' : '' }>
        <div
          dangerouslySetInnerHTML={
            { __html: `${renderLabelHtml( stepItem.textHTML ? stepItem.textHTML : '', locale, leadData )}` }
          }
        >
        </div>
        { stepItem.tooltip && (
          <a
            className="tooltip-info"
            href={ blankLink }
            role='button'
            onClick={ ( e ) => {
              e.preventDefault();
              onShowTooltip( true, stepItem.tooltip!, e.target as unknown as React.ReactInstance );
            } }
          >
            { t( 'bookingFunnel.tooltipHelp' ) }
          </a>
        ) }
      </div>
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </div>
  );
};
