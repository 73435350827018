import React, { Fragment, ReactElement } from 'react';
import plusIcon from 'assets/bf-images/funk/plus_icon.png';
import minusIcon from 'assets/bf-images/funk/minus_icon.svg';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DATETIME,
  FIELD_TYPE_NUMBER,
  isObject,
} from '../../../bf-hooks';
import { ContainerFieldItem, IBFProductVariable, ILeadData } from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';
import dayjs from 'dayjs';
import { defaultDateFormat } from 'App/ui-utils';
import { blankLink } from 'config';
import { useCoreActions } from '../../DynamicCore';
import {
  addressTypes,
  convertValueToNumber,
  currencyFormatter,
  defaultCurrency,
  keyMultiInsuredObjects,
} from '../../core-hooks';
import { evalFunction } from '../../../booking-funnel/functions';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { AddressList } from './AddressList';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { ObjectList } from './ObjectList';
import { ProductList } from './ProductList';

export interface TariffStepInfoProps {
  isOpened: boolean;
  index: number;
  nameStep: string;
  productData: IDataFactorsAndVariables;
  lead: ILeadData;
  isRenderedProduct: boolean;
}

export const TariffStepInfo: React.FC<TariffStepInfoProps> = ( {
  isOpened, index, nameStep, productData, lead, isRenderedProduct,
} ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const locale = useLocale();
  const actions = useCoreActions();
  const { factors, variables } = productData;
  const tariffData = lead[nameStep];
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( isOpened );
  const multiInsuredObjects = lead[keyMultiInsuredObjects];
  const allSteps = actions.getStepsInfo();
  const currentStep = allSteps?.find( ( step ) => step.nameStep === nameStep );

  const collapseToggleHandler = React.useCallback( () => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const getValueFactorField = React.useCallback( (
    variable: IBFProductVariable, containerField: ContainerFieldItem,
  ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( selectedVal && containerField && containerField.uiType === JSONItemUIType.MultiSelect ) {
      const selectedValues: string[] = typeof selectedVal === 'string' ? JSON.parse( selectedVal ) : null;

      if ( selectedValues ) {
        const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
        if ( findFactor ) {
          const factorList = findFactor?.values.filter( ( f ) => selectedValues.includes( String( f.key ) ) );

          return factorList.map( ( item ) => {
            return item.name;
          } ).join( ', ' );
        }
      }

      if ( typeof selectedVal === 'object' ) {
        return selectedVal.map( ( item ) => {
          return item.name;
        } ).join( ', ' );
      }
    }

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, tariffData ] );

  const getValueBaseField = React.useCallback( ( variable: IBFProductVariable, containerField: ContainerFieldItem ) => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
    }

    if ( variable.typeId === FIELD_TYPE_NUMBER && selectedVal ) {
      const isCurrency: boolean = containerField?.uiType === JSONItemUIType.Currency ? true : false;
      if ( isCurrency ) {
        const converValue = convertValueToNumber( selectedVal );
        return `${ currencyFormatter( converValue, defaultCurrency, locale, false ) } Euro`;
      }

      return selectedVal;
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return dayjs( selectedVal ).format( defaultDateFormat );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';
    return !isEmpty( resVal ) ? resVal : '-';
  }, [ locale, t, tariffData ] );

  const onClickEditStep = React.useCallback ( (
    event: React.MouseEvent<HTMLAnchorElement>,
    groupName?: string,
  ) => {
    event.preventDefault();
    if ( groupName ) {
      actions.goToEditStep( nameStep, groupName, false );
    } else {
      actions.goToStep( nameStep, false );
    }
  }, [ actions, nameStep ] );

  return (
    <Fragment>
      { currentStep && currentStep.steps.map( ( stepItem, itemIdx ) => (
        <Fragment key={ itemIdx }>
          { ( !stepItem.visibleCriteria || evalFunction( lead || [], stepItem.visibleCriteria ) ) && (
            <Accordion defaultActiveKey={ isOpened ? nameStep : '' }>
              <Card>
                <Accordion.Toggle
                  className="py-3 px-0"
                  as={ Card.Header }
                  variant="link"
                  eventKey={ nameStep }
                  onClick={ collapseToggleHandler }
                >
                  <span className="align-middle bf-title-text">{ `${index}. ${stepItem.stepTitleToSummary}` }</span>
                  <img src={ isCollapsed ? minusIcon : plusIcon } className="align-middle" alt="chevron" />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={ nameStep }>
                  <Card.Body>
                    { isRenderedProduct && (
                      <ProductList />
                    ) }
                    <div className="d-flex justify-content-end mx-4 my-3">
                      <a
                        className="bf-link-edit"
                        href={ blankLink }
                        onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
                          onClickEditStep( e );
                        } }
                        title={ t( 'base:forms.edit' ) }
                      >
                        { t( 'base:forms.edit' ) }
                      </a>
                    </div>
                    { stepItem.containers.map( ( container, containerIdx ) => (
                      <Fragment key={ containerIdx }>
                        { evalFunction( lead || [], container.visibleCriteria ) && (
                          <Fragment>
                            { container.items && container.title && (
                              <div className="d-flex justify-content-space-between mx-4 my-3">
                                <div className="bf-group-name"
                                  dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                                >
                                </div>
                                <a
                                  className="bf-link-edit"
                                  href={ blankLink }
                                  onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
                                    onClickEditStep( e, container.groupName! );
                                  } }
                                  title={ t( 'base:forms.edit' ) }
                                >
                                  { t( 'base:forms.edit' ) }
                                </a>
                              </div>
                            ) }
                            { container.items && container.items.map( ( c, idx ) => (
                              <Fragment key={ idx }>
                                { !c.fieldName && c.isMulti && c.uiType && multiInsuredObjects
                                && multiInsuredObjects.length > 0 && (
                                  <Fragment>
                                    { addressTypes.includes( c.uiType ) && (
                                      <AddressList
                                        stepItem={ c }
                                        tariffData={ lead[nameStep]! }
                                        multiInsuredObjects={ multiInsuredObjects }
                                      />
                                    ) }
                                    { c.uiType === JSONItemUIType.AutoRenderObject && (
                                      <ObjectList
                                        stepItem={ c }
                                        factors={ factors }
                                        tariffData={ lead[nameStep]! }
                                        multiInsuredObjects={ multiInsuredObjects }
                                      />
                                    ) }
                                  </Fragment>
                                ) }
                                { variables.filter(
                                  ( v ) => `${v.name}_${v.groupName}` === `${c.fieldName}_${c.insuredObjectName}`
                                  && !v.isHidden,
                                )
                                  .map( ( variable, cIdx ) => (
                                    <Fragment key={ cIdx }>
                                      <div className="details-section d-flex justify-content-space-between mx-4">
                                        <p>{ variable.label }</p>
                                        { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                                          <p className="bf-selected-val">{ getValueBaseField( variable, c ) }</p>
                                        ) }
                                        { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                                          <p className="bf-selected-val">{ getValueFactorField( variable, c ) }</p>
                                        ) }
                                      </div>
                                    </Fragment>
                                  ) ) }
                              </Fragment>
                            ) ) }
                          </Fragment>
                        ) }
                      </Fragment>
                    ) ) }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) }
        </Fragment>
      ) ) }
    </Fragment>
  );
};
