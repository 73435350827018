import React, { Fragment } from 'react';
import { isBoolean } from 'lodash';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from '../../../AsteriskRequiredField';
import { checkedUnderwriting, isRequiredField, useLocale } from '../../../booking-funnel/booking-funnel-hooks';
import {
  convertValueToNumber,
  currencyFormatter,
  defaultCurrency,
  renderFieldLabel,
  renderPlaceholder,
} from '../../core-hooks';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';

export interface NumberFieldProps {
  valueField: number;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

const regInt = /^[0-9\b]+$/;
const defaultIncrement = 1;

export const NumberField: React.FC<NumberFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const locale = useLocale();
  const { errors, control, setValue } = useFormContext();
  const actions = useCoreActions();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};

  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const isCurrency: boolean = stepItem?.uiType === JSONItemUIType.Currency ? true : false;
  const { isIntegers, increment, suffix } = stepItem || {};

  const getDefaultValue = ( numberValue: JsonValue ): number | string => {
    if ( typeof valueField === 'number' && !isNaN( valueField ) ) {
      return valueField;
    }

    if ( typeof valueField === 'string' ) {
      return valueField;
    }

    if ( productFieldValue ) {
      return productFieldValue as number;
    }

    if ( stepItem && stepItem.defaultValue ) {
      const defaultValueSC = stepItem.defaultValue;

      if ( isCurrency ) {
        return defaultValueSC !== null ?
          currencyFormatter( defaultValueSC as number, defaultCurrency, locale, false ) : '';
      }

      return defaultValueSC as number || '';
    }

    if ( numberValue && numberValue !== undefined ) {
      return isCurrency ?
        currencyFormatter( numberValue as number, defaultCurrency, locale, false ) :
        numberValue as number;
    }

    const defaultValue: JsonValue = variable.defaultValue;

    if ( isCurrency ) {
      return defaultValue !== null ? currencyFormatter( numberValue as number, defaultCurrency, locale, false ) : '';
    }

    return '';
  };

  const isValidateValue = ( value: string ): string | boolean => {
    if ( !isCurrency ) {
      if ( isIntegers && value ) {
        if ( !regInt.test( value ) ) {
          const errMessage = t( 'base:forms.messages.invalidNumber',
            {
              fieldLabel: variable.label,
              minNum: variable.minValue,
              maxNum: variable.maxValue,
            },
          );

          return errMessage;
        }
      }

      if ( increment && value ) {
        const valueNum = Number( value );
        const incrementNum = Number( increment );

        if ( valueNum % incrementNum !== 0 ) {

          const errMessage = t( 'base:forms.messages.invalidIncrement',
            {
              fieldLabel: variable.label,
              increment,
            },
          );

          return errMessage;
        }
      }
    }

    const converValue = convertValueToNumber( value );

    if ( typeof variable.minValue === 'number' ) {
      const minValue = variable.minValue as number;

      if ( converValue < minValue ) {
        const errMessage = t( 'bookingFunnel.numberMin',
          { fieldLabel: variable.label, minValue: variable.minValue },
        );

        return errMessage;
      }
    }

    if ( typeof variable.maxValue === 'number' ) {
      const maxValue = variable.maxValue as number;

      if ( converValue > maxValue ) {
        const errMessage = t( 'bookingFunnel.numberMax',
          { fieldLabel: variable.label, maxValue: variable.maxValue },
        );

        return errMessage;
      }
    }

    return true;
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return `${classNameField} read-only`;
    }

    if ( stepItem?.isReadOnly ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  const onBlurHandle = React.useCallback ( ( ): void => {
    const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
    actions.recalculationPremium( isCheckedUnderwriting );
  }, [ actions, stepItem ] );

  const handleChange = React.useCallback ( (
    event: React.ChangeEvent<HTMLInputElement>,
    renderProps: ControllerRenderProps<Record<string, any>>,
  ): void => {
    let { value, min } = event.target;

    if ( value === '' ) {
      renderProps.onChange( value );
      return;
    }

    let valueInput: number = Number( value );
    if ( value.length === min.length ) {
      valueInput = Math.max( Number( min ), Number( value ) );
    }

    renderProps.onChange( valueInput );
  }, [] );

  const setIncrement = ( ): number | string => {
    if ( isIntegers && increment && !regInt.test( increment as string ) ) {
      return defaultIncrement;
    }

    if ( increment ) {
      return increment;
    }

    return defaultIncrement;
  };

  const onKeyPress = React.useCallback ( ( event: React.KeyboardEvent<HTMLElement> ) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode( keyCode );

    if ( /\+/.test( keyValue ) ) {
      event.preventDefault();
    }
  }, [] );

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isDisabled ? false : isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }
            return isValidateValue( value );
          },
        } }
        defaultValue={ getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup className={ stepItem?.classNameBox ? stepItem?.classNameBox : '' } controlId={ props.name }>
            <label id={ `${props.name}-label` }
              className={ `form-label${variable.bfTooltip ? ' tooltip-label' : ''}` }
            >
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            { isCurrency ? (
              <div
                className={ `${suffix ? 'group-input-suffix ' : '' }d-flex
                ${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
              >
                <NumericFormat
                  className={ `form-control ${renderClassName()}
                    ${ errors[props.name] !== undefined ? ' is-invalid' : ''}`
                  }
                  name={ props.name }
                  min={ variable.minValue as number }
                  max={ variable.maxValue as number }
                  value={ props.value }
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={ 2 }
                  allowNegative={ false }
                  placeholder={ renderPlaceholder( variable.label, stepItem ) }
                  onChange={ props.onChange }
                  onBlur={ onBlurHandle }
                  disabled={ isDisabled }
                  readOnly={ isReadOnlyInput() }
                />
                { suffix && (
                  <span className="ml-2 d-flex align-items-end suffix-info">
                    { suffix }
                  </span>
                ) }
              </div>
            ) : (
              <div
                className={ `${suffix ? 'group-input-suffix ' : '' }d-flex
                ${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
              >
                <Form.Control
                  { ...props }
                  className={ renderClassName() }
                  type="number"
                  inputMode="numeric"
                  readOnly={ isReadOnlyInput() }
                  step={ setIncrement() }
                  placeholder={ renderPlaceholder( variable.label, stepItem ) }
                  isInvalid={ errors[props.name] !== undefined }
                  onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => handleChange( e, props ) }
                  onBlur={ onBlurHandle }
                  disabled={ isDisabled }
                  min={ variable.minValue as number }
                  max={ variable.maxValue as number }
                  onKeyPress={ onKeyPress }
                />
                { suffix && (
                  <span className="ml-2 d-flex align-items-end suffix-info">
                    { suffix }
                  </span>
                ) }
              </div>
            ) }
            <Form.Control
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
