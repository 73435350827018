import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { useCoreActions, RecalculationTariff } from '../../../DynamicCore';
import { ILeadData, ITariffDataStep, StepItem, SubStepItem } from 'Services/widgets/interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { IDataFactorsAndVariables } from '../../../../booking-funnel/BookingFunnel';
import { isEmpty } from 'lodash';
import { keyCoreInvoiceResult } from '../../core-ui-components/CoreTariffInfo';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { StepInfoItem } from '../../../interfaces';
import { TooltipPopupCore } from '../../../ui-components/TooltipPopupCore';
import {
  formatFormData,
  generateRules,
  isIgnoreOnRecalculationFieldCore,
  preparePayloadForLead,
  systemFields,
  generateCrossPolicyFields,
  limitRequest,
  generateSavedData,
  generateSteps,
  generateMultiObjects,
  generatePolicyObject,
  keyMultiInsuredObjects,
} from '../../../core-hooks';
import { COREBFStep } from '../../../enums';
import { CoreCirclesNavigationMobile } from '../../core-ui-components/CoreCirclesNavigationMobile';
import { IPersonalDataStep } from 'App/components/widgets/booking-funnel/steps/PersonalDataStep2';
import { getFieldDiff } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { useAppLogger } from 'Services/logger';
import { CoreRenderContainer } from '../../core-ui-components/CoreRenderContainer';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';
import { CoreSavedDataPopup } from '../../core-ui-components/CoreSavedDataPopup';
import { htmlTagsRegex } from '../../core-ui-components/extra/CoreAddressAutoComplete';

export interface RegistrationCoreStepProps {
  productCode: string;
  currentStep: SubStepItem;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  recalculationTariff: RecalculationTariff;
  stepData: StepInfoItem;
  policyStepNames: string[];
  isParentStep: boolean;
  isDisabledForm: boolean;
}

export const personalDataCoreKey = 'personalData';
const addressField = 'address';

export const RegistrationCoreStep: React.FC<RegistrationCoreStepProps> = (
  { productCode, currentStep, lead, productData, recalculationTariff,
    stepData, policyStepNames, isParentStep, isDisabledForm = false },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const logger = useAppLogger();
  const keyStep = personalDataCoreKey;

  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );
  const [ isUpdatedLead, setUpdatedLead ] = React.useState<boolean>( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ itemErrorId, setItemErrorId ] = React.useState<string>( '' );
  const [ itemErrorIds, setItemErrorIds ] = React.useState<string[]>( lead[`${keyStep}_error_ids`] || [] );
  const { saveForLaterButton } = stepData || {};

  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const previousFormData = React.useRef<ITariffDataStep>( {} );
  const previousLimitRequest = React.useRef<number>( 0 );

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState, getValues } = formContext;

  const rejectionPopup = React.useCallback( async ( isClickNextBtn: boolean = false ): Promise<void> => {
    actions.setValidForm( isEmpty( formState.errors ) );

    if ( stepData && stepData.steps ) {
      const leadStore: ILeadData = await service.getLead();
      const rules = generateRules( stepData.steps, currentStep?.name );

      actions.setRejected( false );
      setIsRejected( false );

      if ( rules ) {
        rules.forEach( async ( item ) => {
          const rejectionData = evalFunction( leadStore || [], item );
          if ( rejectionData && rejectionData['rejectionMessage'] ) {
            setIsRejected( true );
            const rejectionMessage = rejectionData['rejectionMessage'];
            if ( !rejectionData['suppressPopup'] || isClickNextBtn ) {
              if ( !isClickNextBtn && item.itemId && itemErrorIds.includes( item.itemId ) ) {
                actions.setRejected( true );
                return;
              }

              setItemErrorId( item.itemId! );
              setMessageError( rejectionMessage );
              setShowErrorPopup( true );
            } else {
              const infoMess = rejectionMessage.replace( htmlTagsRegex, '' );
              logger.warn( infoMess );
            }
            actions.setRejected( true );

            return;
          } else {
            const cloneIds = itemErrorIds;

            if ( cloneIds && item.itemId && cloneIds.includes( item.itemId ) ) {
              const errorIds = [ ...cloneIds.filter( ( id ) => id !== item.itemId ) ];
              setItemErrorIds( errorIds );

              await service.savedInfo( `${keyStep}_error_ids`, errorIds );
            }
          }
        } );
      }
    }
  }, [ actions, currentStep, formState.errors, itemErrorIds, keyStep, logger, service, stepData ] );

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    if ( itemErrorId ) {
      const errorIds = [ ...itemErrorIds, itemErrorId ];
      setItemErrorIds( errorIds );

      await service.savedInfo( `${keyStep}_error_ids`, errorIds );
    }

    setShowErrorPopup( false );
    setMessageError( '' );
  }, [ itemErrorId, itemErrorIds, keyStep, service ] );

  const goToPreviousStep = React.useCallback ( ( ): void => {
    if( isParentStep ) {
      actions.goToPersonalStep( stepData.nameStep, null, true );
    } else {
      actions.goToStep( stepData.previousStep!, true );
    }
  }, [ actions, isParentStep, stepData.nameStep, stepData.previousStep ] );

  const updateLeadData = React.useCallback( async (): Promise<void> => {
    const { saveLeadOnComplete } = stepData;

    if ( saveLeadOnComplete && saveLeadOnComplete.enabled && saveLeadOnComplete.endStatus ) {
      try {
        setUpdatedLead( true );

        const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

        if ( savedLeadData ) {
          const { status, policy } = savedLeadData;
          const { stepsConfig, insuredObjects } = productData;

          if ( saveLeadOnComplete.endStatus === status && policy && policy.policyObjects && stepsConfig ) {

            const policyObject = generatePolicyObject( policy.policyObjects, insuredObjects );

            if ( !isEmpty( policyObject ) ) {
              const multiInsuredObjects = generateMultiObjects( policy.policyObjects, insuredObjects );
              await service.savedInfo( keyMultiInsuredObjects, multiInsuredObjects );

              let allSteps = stepsConfig.steps;
              const steps = generateSteps( allSteps as StepItem[] );

              const policySteps = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy );

              if ( policySteps && policySteps.length ) {
                policySteps.forEach( async ( item ) => {
                  const policyStepData = generateSavedData( policyObject, item.steps, multiInsuredObjects );

                  await service.savedInfo( item.nameStep, policyStepData );
                } );
              }
            }
            setUpdatedLead( false );
            actions.goToStep( stepData.nextStep!, true );
          } else {
            setTimeout( async () => {
              if ( previousLimitRequest.current < limitRequest ) {
                previousLimitRequest.current = previousLimitRequest.current + 1;

                await updateLeadData();
              }
              if ( previousLimitRequest.current === limitRequest ) {
                setUpdatedLead( false );
                logger.error( `Update lead: ${t( 'base:forms.messages.errorSave' )}` );
                actions.goToStep( stepData.nextStep!, true );
              }
            }, 1500 );
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          logger.error( e );
        } else {
          throw e;
        }

        actions.goToStep( stepData.nextStep!, true );
      }
    } else {
      setUpdatedLead( false );
      actions.goToStep( stepData.nextStep!, true );
    }
  }, [ actions, logger, productData, service, stepData, t ] );

  const saveLeadOnComplete = React.useCallback( async (): Promise<void> => {
    if ( stepData.saveLeadOnComplete &&
      stepData.saveLeadOnComplete.enabled &&
      stepData.saveLeadOnComplete.beginStatus ) {
      try {
        const leadStore: ILeadData = await service.getLead();
        const { beginStatus, defaultAccountEmail } = stepData.saveLeadOnComplete;

        let payloadLead = preparePayloadForLead(
          leadStore, productData, beginStatus, productCode, false, defaultAccountEmail,
        );

        const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

        if ( savedLeadData ) {
          payloadLead.productVersionId = savedLeadData.policy.productVersionId;

          await service.updateLead( payloadLead, false );
        } else {
          await service.createLead( payloadLead, false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          logger.error( e );
        } else {
          throw e;
        }
      }
    }
  }, [ logger, productCode, productData, service, stepData.saveLeadOnComplete ] );

  const onSubmit = React.useCallback( async ( formData ) => {
    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    if ( !leadData[keyStep] ) {
      return;
    }

    if ( formData[addressField] ) {
      delete formData[addressField];
    }

    const savedFormData = formatFormData( formData );

    await service.saveLoginData( false );
    const result = await service.savedInfo( keyStep, savedFormData );

    if ( result.errorCode === 0 && !isRejected ) {
      await saveLeadOnComplete();
      await updateLeadData();
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, isRejected, keyStep, leadData, rejectionPopup,
    saveLeadOnComplete, service, showAlert, t, updateLeadData ] );

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    if ( isEmpty( previousFormData.current ) ) {
      previousFormData.current = formValues;

      if ( isEmpty( lead[keyStep] ) ) {
        setLeadData( ( prevLead ) => ( {
          ...prevLead,
          [keyStep]: { ...prevLead[keyStep], ...previousFormData.current } as IPersonalDataStep,
        } ) );
      }

      rejectionPopup();

      return;
    }

    const saveFormValues = async () => {
      try {
        if ( !isIgnoreOnRecalculation.current ) {
          await service.savedInfo( keyCoreInvoiceResult, null );
        }
        await service.savedInfo( keyStep, previousFormData.current );
        const storeData: ILeadData = await service.getLead( 0 );

        setLeadData( storeData );

      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    const changedField = getFieldDiff( formValues, previousFormData.current );

    if ( changedField && isMounted ) {
      previousFormData.current = formValues;

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationFieldCore(
          changedField, currentStep!, productData.stepsConfig?.steps! );
      }

      const crossPolicyFields = generateCrossPolicyFields(
        productData.stepsConfig?.steps!, keyStep, changedField,
      );

      if ( actions.updatedStoreData && crossPolicyFields ) {
        actions.updatedStoreData( crossPolicyFields, previousFormData.current[changedField] );
      }

      saveFormValues( );
    }

    return () => {
      isMounted = false;
    };
  }, [ actions, currentStep, formState.errors, formState.isValid, formState.isValidating,
    getValues, keyStep, lead, productData.stepsConfig, rejectionPopup, service, showAlert, t ] );

  if ( productData === null ) {
    return <div className="bf-loading"><LoadingSpinner /></div>;
  }

  if ( lead[stepData.previousStep!] === null ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  return (
    <FormProvider { ...formContext }>
      <Row id="registration-step" className="mt-3 mx-0">
        <Col md={ 12 } className="px-0">
          <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
            { stepData && (
              <Fragment>
                { stepData.title && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                    >
                    </Col>
                  </Row>
                ) }
                { stepData.subTitle && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                    >
                    </Col>
                  </Row>
                ) }
              </Fragment>
            ) }
            { currentStep && (
              <Fragment>
                { currentStep.title && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                    >
                    </Col>
                  </Row>
                ) }
                { currentStep.subTitle && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                    >
                    </Col>
                  </Row>
                ) }
                <CoreRenderContainer
                  storeLeadData={ lead }
                  leadData={ leadData }
                  productData={ productData }
                  productCode={ productCode }
                  stepData={ stepData }
                  currentStep={ currentStep }
                  recalculationTariff={ recalculationTariff }
                  containerName={ '' }
                  policyStepNames={ policyStepNames }
                  isIgnoreOnRecalculation={ isIgnoreOnRecalculation.current }
                  isDisabledForm={ isDisabledForm }
                  nameStep={ keyStep }
                />
              </Fragment>
            ) }
            <Row className="justify-content-center align-items-center mt-0 mx-0">
              <CoreCirclesNavigationMobile lead={ lead } />
            </Row>
            <Row className="bf-footer-btn-panel justify-content-center align-items-center mx-0 mt-4">
              { saveForLaterButton && saveForLaterButton.enabled && (
                <Button
                  id={ `${stepData.nextStep}_save_for_later` }
                  type="button"
                  variant="primary"
                  disabled={ formState.isSubmitting || isUpdatedLead }
                  onClick={ () => onSaved( true ) }
                  className="col-sm-12 mr-0"
                >
                  { saveForLaterButton.buttonText ?
                    saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                </Button>
              ) }
              <Button
                id={ `${COREBFStep.RegistrationStep}_next` }
                type="submit"
                variant="primary"
                disabled={ formState.isSubmitting || isUpdatedLead }
                className="col-sm-12 mr-0"
              >
                { t( 'bookingFunnel.nextBtn' ) }
              </Button>
              <Button
                id={ `${COREBFStep.RegistrationStep}_back` }
                type="button"
                variant="link"
                className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                onClick={ goToPreviousStep }
              >
                { t( 'bookingFunnel.previousBtn' ) }
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <CoreSavedDataPopup
          currentStep={ stepData.nameStep }
          productCode={ productCode }
          productData={ productData! }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
