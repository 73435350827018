import React, { Fragment } from 'react';
import { isBoolean } from 'lodash';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import {
  isCheckedByField,
  renderClassNameBox,
  renderPlaceholder,
} from 'App/components/widgets/dynamic-core/core-hooks';
import { blankLink } from 'config';
import { TooltipCore } from '../../../core-ui-components/TooltipCore';

export interface EmailFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export const EmailField: React.FC<EmailFieldProps> = ( {
  fieldValue,
  fieldName,
  stepItem,
  isDisabled,
  isReadOnly,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltip: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const isValidEmail = ( email: string ): boolean => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( email );
  };

  const isValidateEmail = ( value: string ): string | boolean => {
    if ( value && !isValidEmail( value ) ) {
      const errMessage = t( 'bookingFunnel.personalData.emailFormatError' );
      return errMessage;
    }

    return true;
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( stepItem?.isReadOnly || isBoolean( isReadOnly ) ? isReadOnly : false ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( `bookingFunnel.personalData.${fieldName}` );
  }, [ fieldName, stepItem, t ] );

  const getDefaultValue = (): string => {
    if ( fieldValue ) {
      return fieldValue;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    return '';
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled ) {
      const defaultValue = stepItem.defaultValue ? stepItem.defaultValue : fieldValue;

      setValue( fieldName, defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, fieldValue, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            return isValidateEmail( value );
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => (
          <FormGroup className={ renderClassNameBox( stepItem ) } controlId={ props.name }>
            <Form.Label className={ `bf-main-label${stepItem!.tooltip ? ' tooltip-label' : '' }` }>
              { fieldLabel }
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> )
              }
              { stepItem && stepItem.tooltip && (
                <a
                  className="tooltip-info"
                  id={ `tooltip-${props.name}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    onShowTooltip( true, stepItem.tooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            <Form.Control
              { ...props }
              type="text"
              className={ renderClassName() }
              readOnly={ stepItem?.isReadOnly || isBoolean( isReadOnly ) ? isReadOnly : false }
              placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
              isInvalid={ errors[props.name] !== undefined }
              disabled={ isDisabled }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </Fragment>
  );
};
