import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import downloadGreenIconUrl from 'assets/bf-images/funk/download_green.svg';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { blankLink } from 'config';
import {
  IDocumentDownload,
  ILeadData,
  IPayloadForDocumentPdf,
  IPolicyObject,
  IProductDataForInvoice,
} from 'Services/widgets/interfaces';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { PdfDocumentType } from 'Services/widgets/enums';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import { useAppLogger } from 'Services/logger';
import { JsonObject } from '@cover42/protobuf-util';
import { InsuredObjectTypes } from 'Services/widgets/enums';
import {
  formatDatePolicy,
  formatInvoiceItems,
  generateVariables,
  keyMultiInsuredObjects,
  prepareAccountForInvoice,
  prepareDataForTariff,
  preparedDefaultGroup,
  prepareDefaulGroupAplication,
  preparePolicyPayload,
} from '../../core-hooks';
import { cloneDeep, groupBy } from 'lodash';
import { generateBillingTo } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { defaultDateFormat, invoiceDateFormat } from 'App/ui-utils';

export interface IDocumentDownloadInfoProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  iconCard: string;
  isOpened: boolean;
  policyStepNames: string[];
}

export const DocumentDownloadInfo: React.FC<IDocumentDownloadInfoProps> = ( {
  productCode, lead, productData, policyStepNames,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const service = useWidgetService();
  const logger = useAppLogger();
  const { hideAlert, showAlert } = useAppAlertService();
  const [ isLoading, setIsLoading ] = React.useState( false );
  const documentDataList = productData.documentSettings ? productData.documentSettings : [];

  const downloadPdfHandler = React.useCallback(
    async ( docItem: IDocumentDownload ): Promise<void> => {
      hideAlert();
      if ( docItem.isDisabled ) {
        return;
      }

      setIsLoading( true );
      const accountData = { ...lead.personalData, ...lead.paymentData } as JsonObject;
      const prepareAccountData = prepareAccountForInvoice( accountData );
      const leadData: ILeadData = await service.getLead();
      const nameFile = !docItem.templateSlug ? t( `${docItem.documentNameKey}` ) : docItem.documentNameKey;
      const entityType: PdfDocumentType = docItem.documentType;
      const tariffData = preparePolicyPayload( leadData, policyStepNames! );
      const cloneInsured = cloneDeep( productData.insuredObjects );
      const filteredObjects = cloneInsured.filter( ( item ) => item.name !== InsuredObjectTypes.Default );

      let tariffDataForMultiInsured: IPolicyObject[] = [];
      const multiInsuredObjects = leadData[keyMultiInsuredObjects];

      if ( multiInsuredObjects ) {
        const variables = generateVariables( multiInsuredObjects );

        tariffDataForMultiInsured = prepareDataForTariff(
          tariffData,
          variables,
          multiInsuredObjects,
          false,
        );
      }

      const prepareTariff = prepareDataForTariff(
        tariffData,
        productData.variables,
        filteredObjects,
        false,
      );

      const policyObjectsData: IPolicyObject[] = [ ...prepareTariff, ...tariffDataForMultiInsured ];

      const preparedGroup = preparedDefaultGroup(
        tariffData,
        productData.insuredObjects,
        false,
      );

      const defaultGroupData = prepareDefaulGroupAplication(
        preparedGroup,
        productData,
      );

      const prepareTariffToInvoice = prepareDataForTariff(
        tariffData,
        productData.variables,
        productData.insuredObjects,
        false,
      );

      const policyObjects = policyObjectsData.map( ( item ) => {
        const insuredObject = productData.insuredObjects.find( ( obj ) => item.insuredObjectId === obj.id );

        if ( !insuredObject ) {
          return item;
        }

        return {
          ...item,
          insuredObjectName: insuredObject.name,
        };
      } );

      const billingDateTo = generateBillingTo( productData.insuredObjects );
      const policyStartDate = defaultGroupData?.data.policyStartDate as string;

      const updatedDefaultGroup = {
        ...defaultGroupData,
        data: {
          ...defaultGroupData?.data,
          policyEndDate: dayjs( policyStartDate ).add( 1, 'year' ).format( formatDatePolicy ),
        },
      };

      const formattedObjects = groupBy( policyObjects,
        ( policyObject: IPolicyObject ) => policyObject.insuredObjectName );

      const formattedTimeline = {
        from: dayjs( policyStartDate ).format( formatDatePolicy ),
        to: dayjs( policyStartDate ).add( 1, 'year' ).format( formatDatePolicy ),
        policyObjects,
        defaultPolicyObject: { ...updatedDefaultGroup },
        formattedObjects,
      };

      const payloadInvoice: IProductDataForInvoice = {
        policyObjects: [ ...prepareTariffToInvoice, ...tariffDataForMultiInsured ],
      };

      const invoiceResult = await service.getProductInvoice( productCode, payloadInvoice );
      let payloadPdf: JsonObject = {
        account: prepareAccountData,
        date: dayjs().format( invoiceDateFormat ),
        city: 'Berlin',
        dueDate: dayjs( billingDateTo ).format( defaultDateFormat ),
        policy: {
          productName: productData.productName,
          createdAt: dayjs().toISOString(),
        },
        invoice: {
          ...invoiceResult,
          formattedInvoiceItems: formatInvoiceItems( invoiceResult.invoiceItems ) } as unknown as JsonObject,
        formattedTimeline: { ...formattedTimeline } as unknown as JsonObject,
      };

      const payloadForDocumentPdf: IPayloadForDocumentPdf = {
        templateSlug: docItem.templateSlug!,
        entityType,
        description: `Pdf document ${entityType}`,
        requester: 'publicapi',
        payload: {
          ...payloadPdf,
          productName: productData.productName,
        },
        productSlug: productData.productSlug,
      };

      return service.downloadPdfDocument( payloadForDocumentPdf )
        .then( ( res ) => {
          const blob = new Blob( [ res ], {
            type: 'application/pdf',
          } );
          FileSaver.saveAs( blob, `${nameFile}.pdf` );
          setIsLoading( false );
        } )
        .catch( ( error ) => {
          logger.error( error );
          showAlert( {
            message: t( 'base:forms.messages.error' ),
            type: 'danger',
          } );
          setIsLoading( false );
        } );
    }, [ hideAlert, lead.paymentData, lead.personalData, logger,
      policyStepNames, productCode, productData, service, showAlert, t ] );

  const downloadStaticPdfHandler = React.useCallback(
    async ( docItem: IDocumentDownload ): Promise<void> => {
      hideAlert();

      if ( docItem.isDisabled ) {
        return;
      }

      const nameFile = t( `${docItem.documentNameKey}` );
      const entityType = docItem.entityType!;
      const document = productData.staticDocuments.find( ( d ) => d.entityType === entityType );

      if( !document ) {
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );

        return;
      }

      setIsLoading( true );

      return service.downloadStaticPdfDocument( document.code )
        .then( ( res ) => {
          const blob = new Blob( [ res ], {
            type: 'application/pdf',
          } );
          FileSaver.saveAs( blob, `${nameFile}.pdf` );
          setIsLoading( false );
        } )
        .catch( ( error ) => {
          logger.error( error );
          showAlert( {
            message: t( 'base:forms.messages.error' ),
            type: 'danger',
          } );
          setIsLoading( false );
        } );
    }, [ hideAlert, logger, productData.staticDocuments, service, showAlert, t ] );

  const getLinkToFile = React.useCallback( ( docItem: IDocumentDownload ): string => {
    return docItem.linkToFile ? docItem.linkToFile : '';
  }, [] );

  const staticTypes: string[] = [ PdfDocumentType.Link, PdfDocumentType.Static ];

  return (
    <Accordion className="max-w-610">
      <Card>
        <Card.Body className="px-2">
          { isLoading && loadingOnFullContainer }
          { documentDataList.map( ( d, idx ) => {
            return (
              <Fragment key={ idx }>
                { !staticTypes.includes( d.documentType! ) && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ blankLink }
                      role='button'
                      onClick={ ( e ) => {
                        e.preventDefault();
                        downloadPdfHandler( d );
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
                { d.documentType === PdfDocumentType.Link && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ getLinkToFile( d ) }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={ ( e ) => {
                        if ( d.isDisabled ) {
                          return;
                        }
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
                { d.documentType === PdfDocumentType.Static && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ blankLink }
                      role='button'
                      onClick={ ( e ) => {
                        e.preventDefault();
                        downloadStaticPdfHandler( d );
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
              </Fragment>
            );
          } ) }
        </Card.Body>
      </Card>
    </Accordion>
  );
};
