import React, { Fragment } from 'react';
import documentIcon from 'assets/bf-images/document_icon.svg';
import {
  ContainerFieldItem,
  DocumentsSettings,
  FileUploadSettings,
  ILeadData,
  ITariffDataStep,
  PremiumSettings,
  SubStepItem,
  SummaryLabel,
  SummarySettings,
} from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { StepInfoItem } from '../../interfaces';
import { AddressType, LayoutTypes } from '../../enums';
import {
  addressTypes,
  keyMultiInsuredObjects,
  renderContainerClass,
  renderContainerItemClassName,
  renderGridStyles,
} from '../../core-hooks';
import { FieldType, LayoutLevel } from 'Services/widgets/enums';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { isEmpty } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { AddressPanel } from '../../ui-elements/AddressPanel';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { AutoRenderObjectPanel } from '../../ui-elements/AutoRenderObjectPanel';
import { DownloadLink } from '../../ui-elements/DownloadLink';
import { CoreLabel } from './CoreLabel';
import { CoreTariffInfo } from './CoreTariffInfo';
import { CoreAccountPanel } from './CoreAccountPanel';
import { CoreCheckbox } from './CoreCheckbox';
import { FileUpload } from '../../ui-components/FileUpload';
import { CoreDynamicField } from './CoreDynamicField';
import { CoreFieldPanel } from './CoreFieldPanel';
import { RecalculationTariff } from '../../DynamicCore';
import { CoreAddressSwitcher } from './CoreAddressSwitcher';
import { CoreViewSummary } from './CoreViewSummary';
import { CoreDocumentDownloadInfo } from './summary-views/CoreDocumentDownloadInfo';
import { CoreMultiInsuredObject } from './CoreMultiInsuredObject';
import { CoreAddressAutoComplete } from './extra/CoreAddressAutoComplete';
import { CoreExtraListPanel } from './extra/CoreExtraListPanel';
import { CoreAccountSelect } from './CoreAccountSelect';
import { CoreEmilFunctionLookup } from './CoreEmilFunctionLookup';

export interface CoreRenderContainerProps {
  storeLeadData: ILeadData;
  leadData: ILeadData;
  productData: IDataFactorsAndVariables;
  productCode: string;
  stepData: StepInfoItem;
  currentStep: SubStepItem | undefined;
  recalculationTariff: RecalculationTariff;
  containerName: string;
  policyStepNames: string[];
  isIgnoreOnRecalculation: boolean;
  isDisabledForm: boolean;
  onSaved?: ( isShow: boolean ) => void;
  nameStep?: string;
}

export const CoreRenderContainer: React.FC<CoreRenderContainerProps> = ( {
  storeLeadData, leadData, productData, productCode, stepData,
  currentStep, recalculationTariff, containerName, policyStepNames,
  isIgnoreOnRecalculation,
  isDisabledForm, onSaved, nameStep,
} ) => {
  const { fieldsDefaultValues } = productData.configuration || {};
  const keyStep = nameStep || stepData.nameStep;
  const defaultLayoutData = productData.stepsConfig && productData.stepsConfig.layout
    ? productData.stepsConfig.layout[LayoutTypes.Default] : null;

  const isDisabledField = React.useCallback ( ( item: ContainerFieldItem ): boolean => {
    if( isDisabledForm ) {
      return isDisabledForm;
    }

    if ( item.disableCriteria ) {
      return evalFunction( leadData || [], item.disableCriteria ) as boolean;
    }

    return false;
  }, [ isDisabledForm, leadData ] );

  if ( !currentStep ) {
    return <></>;
  }

  return (
    <div
      className={ `${defaultLayoutData && defaultLayoutData[stepData.id] ? 'layout-container' : ''}` }
      style={
        renderGridStyles(
          defaultLayoutData,
          LayoutLevel.SubStep,
          currentStep.id,
        )
      }
    >
      { currentStep.containers.map( ( container, index ) => (
        <Fragment key={ index }>
          { evalFunction( leadData || [], container.visibleCriteria ) && (
            <Row
              hidden={ !isEmpty( containerName ) && containerName !== container.groupName }
              id={ `${ container.groupName ? `${container.groupName}-${index}` : index }-box` }
              className={ renderContainerClass( container, defaultLayoutData ) }
              style={
                renderGridStyles(
                  defaultLayoutData,
                  LayoutLevel.Container,
                  currentStep.id,
                  container,
                )
              }
            >
              { ( container.title || container.subTitle ) && (
                <Col
                  md={ 12 }
                  className="p-0 title-box"
                  style={
                    renderGridStyles(
                      defaultLayoutData,
                      LayoutLevel.TitelContainer,
                      currentStep.id,
                      container,
                    )
                  }
                >
                  { container.title && (
                    <div
                      className="container-title"
                      dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                    >
                    </div>
                  ) }
                  { container.subTitle && (
                    <div
                      className="container-sub-title"
                      dangerouslySetInnerHTML={ { __html: `${container.subTitle}` } }
                    >
                    </div>
                  ) }
                </Col>
              ) }
              { container.items && container.items.map( ( item, itemIdx ) => (
                <Fragment key={ itemIdx }>
                  { evalFunction( leadData || [], item.visibleCriteria ) && (
                    <Fragment>
                      { !item.fieldName && item.isMulti && addressTypes.includes( item.uiType! ) && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <AddressPanel
                            stepItem={ item }
                            formData={ leadData[keyStep]! }
                            insuredObjects={ productData.insuredObjects }
                            multiInsuredObjects={ leadData[keyMultiInsuredObjects] }
                          />
                        </div>
                      ) }
                      { !item.fieldName && item.isMulti && item.uiType! === JSONItemUIType.AutoRenderObject && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <AutoRenderObjectPanel
                            stepItem={ item }
                            lead={ storeLeadData }
                            factors={ productData.factors }
                            formData={ leadData[keyStep]! }
                            insuredObjects={ productData.insuredObjects }
                            multiInsuredObjects={ leadData[keyMultiInsuredObjects] }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.DownloadLink && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <DownloadLink
                            downloadLinks={ item.downloadLinks }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.Label && (
                        <CoreLabel
                          leadData={ { ...storeLeadData, [keyStep]: leadData[keyStep] } }
                          className={ renderContainerItemClassName( item ) }
                          stepItem={ item }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        />
                      ) }
                      { item.type && item.type === FieldType.PremiumPreview && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreTariffInfo
                            lead={ storeLeadData }
                            productData={ productData }
                            productCode={ productCode }
                            isIgnoreOnRecalculation={ isIgnoreOnRecalculation }
                            showSavePopup={ isEmpty( containerName ) ? onSaved : undefined }
                            showSaveForLaterButton={ ( item.settings as PremiumSettings )?.saveForLater }
                            recalculationTariff={ recalculationTariff }
                            subSteps={ stepData.steps }
                            keyStep={ keyStep }
                            policyStepNames={ policyStepNames }
                            settings={ item.settings as PremiumSettings }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.Account && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreAccountPanel
                            lead={ leadData }
                            formData={ leadData[keyStep]! }
                            stepItem={ item }
                            isDisabled={ isDisabledField( item ) }
                            fieldDefaultValue={
                              fieldsDefaultValues && fieldsDefaultValues.find(
                                ( f ) => f.insuredObjectName.toLowerCase() === keyStep.toLowerCase() &&
                                f.productFieldName.toLowerCase() === item.fieldName.toLowerCase() )
                            }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.AddressSwitcher && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreAddressSwitcher
                            lead={ leadData }
                            personalDataFields={ leadData[keyStep] as ITariffDataStep }
                            stepItem={ item }
                            isDisabled={ isDisabledField( item ) }
                            defaultValue={ isDisabledForm ? AddressType.AccountAddress : null }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.SummaryPreview && (
                        <div
                          className={ `bf-data-info ${renderContainerItemClassName( item )}` }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreViewSummary
                            lead={ leadData }
                            productData={ productData! }
                            policyStepNames={ policyStepNames }
                            settings={ item.settings as SummarySettings }
                            labels={ item.labels as SummaryLabel[] }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.DownloadInfo && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreDocumentDownloadInfo
                            isOpened={ false }
                            iconCard={ documentIcon }
                            productCode={ productCode }
                            lead={ leadData }
                            productData={ productData! }
                            policyStepNames={ policyStepNames }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.DownloadInfoExtended && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreDocumentDownloadInfo
                            isOpened={ false }
                            iconCard={ documentIcon }
                            productCode={ productCode }
                            lead={ leadData }
                            productData={ productData! }
                            policyStepNames={ policyStepNames }
                            settings={ item.settings as DocumentsSettings }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.Consent && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreCheckbox
                            stepItem={ item }
                            productCode={ productCode }
                            productData={ productData }
                            formData={ leadData[keyStep] }
                            isDisabled={ isDisabledField( item ) }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.FileUpload && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <FileUpload
                            stepItem={ item }
                            productData={ productData }
                            stepKey={ keyStep }
                            settings={ item.settings as FileUploadSettings }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.StateVariable && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreDynamicField
                            lead={ { ...storeLeadData, [keyStep]: leadData[keyStep] } }
                            stepItem={ item }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.MultiInsuredObject && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreMultiInsuredObject
                            productData={ productData }
                            stepItem={ item }
                            leadData={ leadData }
                            formData={ leadData[keyStep] }
                            currentStepId={ currentStep.id }
                            container={ container }
                            nameStep={ keyStep }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.AddressAutoComplete && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreAddressAutoComplete
                            formData={ leadData[keyStep] }
                            insuredObjects={ productData.insuredObjects }
                            stepItem={ item }
                            isDisabled={ isDisabledField( item ) }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.Policy && productData.variables.filter(
                        ( v ) =>
                          `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                      )
                        .map( ( variable, idx ) => (
                          <div
                            key={ idx }
                            className={ renderContainerItemClassName( item ) }
                            style={
                              renderGridStyles(
                                defaultLayoutData,
                                LayoutLevel.Item,
                                currentStep.id,
                                container,
                                item.id,
                              )
                            }
                          >
                            <CoreFieldPanel
                              key={ idx }
                              formData={ leadData[keyStep]! }
                              factors={ productData.factors }
                              variable={ variable }
                              stepItem={ item }
                              fieldDefaultValue={
                                fieldsDefaultValues && fieldsDefaultValues.find(
                                  ( f ) => f.insuredObjectName.toLowerCase() === variable.groupName?.toLowerCase() &&
                                  f.productFieldName.toLowerCase() === variable.name.toLowerCase() )
                              }
                              isDisabled={ isDisabledField( item ) }
                            />
                          </div>
                        ) ) }
                      { item.type && item.type === FieldType.List && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreExtraListPanel
                            stepItem={ item }
                            leadData={ { ...storeLeadData, [keyStep]: leadData[keyStep] } }
                            formData={ leadData[keyStep]! }
                            productCode={ productCode }
                            productData={ productData }
                            isDisabled={ isDisabledField( item ) }
                            stepData={ stepData }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.AccountSelect && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreAccountSelect
                            formData={ leadData[keyStep]! }
                            stepItem={ item }
                            isDisabled={ isDisabledField( item ) }
                            optionLabel={ item.optionLabel }
                            mapping={ item.mapping }
                          />
                        </div>
                      ) }
                      { item.type && item.type === FieldType.EmilFunctionLookup && (
                        <div
                          className={ renderContainerItemClassName( item ) }
                          style={
                            renderGridStyles(
                              defaultLayoutData,
                              LayoutLevel.Item,
                              currentStep.id,
                              container,
                              item.id,
                            )
                          }
                        >
                          <CoreEmilFunctionLookup
                            formData={ leadData[keyStep]! }
                            stepItem={ item }
                            isDisabled={ isDisabledField( item ) }
                            emilFunctionSlug={ item.emilFunctionSlug }
                            placeholder={ item.placeholder }
                            mapping={ item.mapping }
                          />
                        </div>
                      ) }
                    </Fragment>
                  ) }
                </Fragment>
              ) ) }
            </Row>
          ) }
        </Fragment>
      ) ) }
    </div>
  );
};
