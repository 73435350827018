import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILeadData } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { preparePayloadForLead } from '../../core-hooks';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useAppLogger } from 'Services/logger';
import { LeadStatus } from '@cover42/ts-contracts';
import { StepInfoItem } from '../../interfaces';
import { useCoreActions } from '../../DynamicCore';

export interface CoreSaveLeadNavProps {
  stepData: StepInfoItem;
  productCode: string;
  productData: IDataFactorsAndVariables;
  disabled: boolean;
  rejectionPopup?: ( isClickNextBtn: boolean ) => Promise<void>;
  isRejected?: boolean;
}

export const CoreSaveLeadNav: React.FC<CoreSaveLeadNavProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const actions = useCoreActions();
  const logger = useAppLogger();
  const service = useWidgetService();

  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const [ isSavedData, setIsSavedData ] = React.useState<boolean>( false );
  const { stepData, productCode, productData, disabled, rejectionPopup, isRejected } = dataProps;
  const { saveForLaterButton } = stepData || {};

  const saveForLaterSettings = productData.stepsConfig?.saveForLaterSettings;

  const { formState, trigger } = useFormContext();

  const onSaveLead = React.useCallback( async () => {
    setIsSuccess( false );

    if ( !formState.isValid ) {
      trigger();
      return;
    }

    if ( rejectionPopup ) {
      rejectionPopup( true );
    }

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    try {
      setIsSavedData( true );

      const leadData: ILeadData = await service.getLead();
      let leadStatus: string = LeadStatus.Created;
      if ( saveForLaterSettings && saveForLaterSettings.leadStatus ) {
        leadStatus = saveForLaterSettings.leadStatus;
      } else {
        const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
        leadStatus = initialLeadStatus ? initialLeadStatus : leadStatus;
      }

      let payloadLead = preparePayloadForLead(
        leadData, productData, leadStatus, productCode, true,
      );

      const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

      if ( savedLeadData ) {
        payloadLead.productVersionId = savedLeadData.policy.productVersionId;
      }

      const result = savedLeadData ?
        await service.updateLead( payloadLead ) : await service.createLead( payloadLead, false );

      if ( result ) {
        setIsSuccess( true );
        setTimeout( () => {
          const resLead = result.lead;
          actions.onSubmit( resLead.leadNumber );
        }, 500 );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );

        setIsSuccess( false );
      }
      setIsSavedData( false );
    } catch( e ) {
      if ( e instanceof Error ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
        logger.error( e );
      } else {
        throw e;
      }
      setIsSavedData( false );
    }
  }, [ actions, formState.isValid, logger, productCode, productData,
    saveForLaterSettings, service, showAlert, t, trigger, isRejected, rejectionPopup ] );

  return (
    <Fragment>
      { isSuccess && (
        <>
          { saveForLaterButton?.successMessageText ? (
            <div className="message-success"
              dangerouslySetInnerHTML={ { __html: saveForLaterButton?.successMessageText } }
            >
            </div>
          ) : (
            <div className="message-success">
              <p>{ t( 'base:forms.messages.success' ) }</p>
            </div>
          ) }
        </>
      ) }
      { saveForLaterButton && saveForLaterButton.enabled && (
        <Button
          id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
          type="button"
          variant="primary"
          disabled={ disabled || isSavedData }
          onClick={ () => onSaveLead() }
          className="col-sm-12 mr-0"
        >
          { saveForLaterButton.buttonText ?
            saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
        </Button>
      ) }
    </Fragment>
  );
};
