import React, { Fragment } from 'react';
import { isBoolean } from 'lodash';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import {
  isCheckedByField,
  renderClassNameBox,
  renderPlaceholder,
} from 'App/components/widgets/dynamic-core/core-hooks';

export interface NumberFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export const NumberField: React.FC<NumberFieldProps> = ( {
  fieldValue,
  fieldName,
  stepItem,
  isDisabled,
  isReadOnly,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( stepItem?.isReadOnly || isBoolean( isReadOnly ) ? isReadOnly : false ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( `bookingFunnel.personalData.${fieldName}` );
  }, [ fieldName, stepItem, t ] );

  const getDefaultValue = (): string => {
    if ( fieldValue ) {
      return fieldValue;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    return '';
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }
    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }
    return false;
  };


  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, fieldValue, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ { required: isCheckedByField( 'isRequired', stepItem! ) } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => (
          <FormGroup className={ renderClassNameBox( stepItem ) } controlId={ props.name }>
            <Form.Label>
              { fieldLabel }
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> )
              }
            </Form.Label>
            <Form.Control
              { ...props }
              type="number"
              inputMode="numeric"
              className={ renderClassName() }
              readOnly={ isReadOnlyInput() }
              placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
              isInvalid={ errors[props.name] !== undefined }
              disabled={ isDisabled }
            />
            <Form.Control.Feedback type="invalid">
              { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
