import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import downloadGreenIconUrl from 'assets/bf-images/funk/download_green.svg';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { blankLink } from 'config';
import {
  DocumentsSettings,
  IDocumentDownload,
  ILeadData,
  IPayloadForDocumentPdf,
  IPolicyObject,
  IProductDataForInvoice,
  IProductDocumentItem,
} from 'Services/widgets/interfaces';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { PdfDocumentType } from 'Services/widgets/enums';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import { useAppLogger } from 'Services/logger';
import { JsonObject } from '@cover42/protobuf-util';
import { InsuredObjectTypes } from 'Services/widgets/enums';
import {
  formatDatePolicy,
  formatInvoiceItems,
  generateVariables,
  keyMultiInsuredObjects,
  prepareDataForTariff,
  preparedDefaultGroup,
  prepareDefaulGroupAplication,
  prepareValuesForDocument,
  preparePolicyPayload,
  getAllStepNames,
  prepareAccountData,
} from '../../../core-hooks';
import { cloneDeep, groupBy } from 'lodash';
import { generateBillingTo } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { defaultDateFormat, invoiceDateFormat } from 'App/ui-utils';
import { PersonalDataStepCore } from '../../../steps/personal-sub-steps/RegistrationStep';
import { PolicyEditData } from '../../../PolicyEdit';

export interface CoreDocumentDownloadInfoProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables | PolicyEditData;
  iconCard: string;
  isOpened: boolean;
  policyStepNames: string[];
  settings?: DocumentsSettings;
}

export const CoreDocumentDownloadInfo: React.FC<CoreDocumentDownloadInfoProps> = ( {
  productCode, lead, productData, policyStepNames, settings,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const service = useWidgetService();
  const logger = useAppLogger();
  const { hideAlert, showAlert } = useAppAlertService();
  const [ isLoading, setIsLoading ] = React.useState( false );
  let documentDataList = productData.documentSettings ? productData.documentSettings : [];

  if ( settings && settings.documents ) {
    documentDataList = settings.documents;
  }

  const downloadPdfHandler = React.useCallback(
    async ( docItem: IDocumentDownload ): Promise<void> => {
      hideAlert();
      if ( docItem.isDisabled ) {
        return;
      }

      setIsLoading( true );

      const leadData: ILeadData = await service.getLead();
      const prepareAccount = leadData.personalData ?
        prepareAccountData( leadData.personalData as PersonalDataStepCore, productData.stepsConfig! ) : {};
      const accountData = { ...prepareAccount, ...leadData.paymentData } as JsonObject;
      const nameFile = !docItem.templateSlug ? t( `${docItem.documentNameKey}` ) : docItem.documentNameKey;
      const entityType: PdfDocumentType = docItem.documentType;
      const allStepNames: string[] = getAllStepNames( productData.stepsConfig! );
      const tariffData = preparePolicyPayload( leadData, allStepNames, productData.stepsConfig );
      const cloneInsured = cloneDeep( productData.insuredObjects );
      const filteredObjects = cloneInsured.filter( ( item ) => item.name !== InsuredObjectTypes.Default );

      let tariffDataForMultiInsured: IPolicyObject[] = [];
      const multiInsuredObjects = leadData[keyMultiInsuredObjects];

      if ( multiInsuredObjects ) {
        const variables = generateVariables( multiInsuredObjects );

        tariffDataForMultiInsured = prepareDataForTariff(
          tariffData,
          variables,
          multiInsuredObjects,
          false,
        );
      }

      const prepareTariff = prepareDataForTariff(
        tariffData,
        productData.variables,
        filteredObjects,
        false,
      );

      const policyObjectsData: IPolicyObject[] = [ ...prepareTariff, ...tariffDataForMultiInsured ];

      const preparedGroup = preparedDefaultGroup(
        tariffData,
        productData.insuredObjects,
        false,
      );

      const defaultGroupData = prepareDefaulGroupAplication(
        preparedGroup,
        productData,
      );

      const prepareTariffToInvoice = prepareDataForTariff(
        tariffData,
        productData.variables,
        productData.insuredObjects,
        false,
      );

      const policyObjects = policyObjectsData.map( ( item ) => {
        const insuredObject = productData.insuredObjects.find( ( obj ) => item.insuredObjectId === obj.id );

        if ( !insuredObject ) {
          return item;
        }

        return {
          ...item,
          insuredObjectName: insuredObject.name,
        };
      } );

      const billingDateTo = generateBillingTo( productData.insuredObjects );
      const policyStartDate = defaultGroupData?.data.policyStartDate as string;

      const updatedDefaultGroup = {
        ...defaultGroupData,
        data: {
          ...defaultGroupData?.data,
          policyEndDate: dayjs( policyStartDate ).add( 1, 'year' ).format( formatDatePolicy ),
        },
      };

      const formattedObjects = groupBy( policyObjects,
        ( policyObject: IPolicyObject ) => policyObject.insuredObjectName );

      const formattedTimeline = {
        from: dayjs( policyStartDate ).format( formatDatePolicy ),
        to: dayjs( policyStartDate ).add( 1, 'year' ).format( formatDatePolicy ),
        policyObjects,
        defaultPolicyObject: { ...updatedDefaultGroup },
        formattedObjects,
      };

      const payloadInvoice: IProductDataForInvoice = {
        policyObjects: [ ...prepareTariffToInvoice, ...tariffDataForMultiInsured ],
      };

      const invoiceResult = await service.getProductInvoice( productCode, payloadInvoice );

      const prepareObjects = prepareValuesForDocument( policyObjects, productData, t );

      const newFormattedObjects = groupBy( prepareObjects,
        ( policyObject: IPolicyObject ) => policyObject.insuredObjectName );

      let payloadPdf: JsonObject = {
        account: accountData,
        date: dayjs().format( invoiceDateFormat ),
        city: 'Berlin',
        dueDate: dayjs( billingDateTo ).format( defaultDateFormat ),
        policy: {
          productName: productData.productName,
          createdAt: dayjs().toISOString(),
        },
        invoice: {
          ...invoiceResult,
          formattedInvoiceItems: formatInvoiceItems( invoiceResult.invoiceItems ) } as unknown as JsonObject,
        formattedTimeline: { ...formattedTimeline } as unknown as JsonObject,
        formattedObjects: { ...newFormattedObjects },
      };

      const payloadForDocumentPdf: IPayloadForDocumentPdf = {
        templateSlug: docItem.templateSlug!,
        entityType,
        description: `Pdf document ${entityType}`,
        requester: 'publicapi',
        payload: {
          ...payloadPdf,
          productName: productData.productName,
        },
        productSlug: productData.productSlug,
      };

      if ( settings && typeof settings.shouldSkipDefaultMargins === 'boolean' ) {
        payloadForDocumentPdf.shouldSkipDefaultMargins = settings.shouldSkipDefaultMargins;
      }

      if ( entityType === PdfDocumentType.Docx ) {
        payloadForDocumentPdf.engine = PdfDocumentType.Docx;
      }

      return service.downloadPdfDocument( payloadForDocumentPdf )
        .then( ( res ) => {
          const blob = new Blob( [ res ], {
            type: 'application/pdf',
          } );
          FileSaver.saveAs( blob, `${nameFile}.pdf` );
          setIsLoading( false );
        } )
        .catch( ( error ) => {
          logger.error( error );
          showAlert( {
            message: t( 'base:forms.messages.error' ),
            type: 'danger',
          } );
          setIsLoading( false );
        } );
    }, [ hideAlert, logger, productCode, productData, service, settings, showAlert, t ] );

  const downloadStaticPdfHandler = React.useCallback(
    async ( docItem ): Promise<void> => {
      hideAlert();

      if ( docItem.isDisabled ) {
        return;
      }

      const entityType = docItem.entityType;
      const documentDescriptionKey = docItem.documentDescriptionKey;
      let document: IProductDocumentItem | undefined = undefined;

      if ( documentDescriptionKey ) {
        document = productData.productDocuments.find( ( d ) => d.description === documentDescriptionKey );
      }

      if( !documentDescriptionKey && entityType ) {
        document = productData.productDocuments.find( ( d ) => d.entityType === entityType );
      }

      if( !document ) {
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );

        return;
      }

      setIsLoading( true );

      try {
        const { url } = await service.getProductDocumentUrl( productCode, document.code );

        window.open( url, '_blank', 'noreferrer' );

        setIsLoading( false );
      } catch ( error ) {
        logger.error( error );
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
        setIsLoading( false );
      }
    }, [ hideAlert, logger, productCode, productData.productDocuments, service, showAlert, t ] );

  const getLinkToFile = React.useCallback( ( docItem: IDocumentDownload ): string => {
    return docItem.linkToFile ? docItem.linkToFile : '';
  }, [] );

  const staticTypes: string[] = [ PdfDocumentType.Link, PdfDocumentType.Static ];

  return (
    <Accordion>
      <Card>
        <Card.Body className="px-2">
          { isLoading && loadingOnFullContainer }
          { documentDataList.map( ( d, idx ) => {
            return (
              <Fragment key={ idx }>
                { !staticTypes.includes( d.documentType! ) && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ blankLink }
                      onClick={ ( e ) => {
                        e.preventDefault();
                        downloadPdfHandler( d );
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
                { d.documentType === PdfDocumentType.Link && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ getLinkToFile( d ) }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={ ( e ) => {
                        if ( d.isDisabled ) {
                          return;
                        }
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
                { d.documentType === PdfDocumentType.Static && (
                  <div className="download-section">
                    <a
                      className={ d.isDisabled ? 'disabled-link' : '' }
                      href={ blankLink }
                      onClick={ ( e ) => {
                        e.preventDefault();
                        downloadStaticPdfHandler( d );
                      } }
                    >
                      <img src={ downloadGreenIconUrl } className="mr-3" alt="download" />
                      { d.documentNameKey }
                    </a>
                  </div>
                ) }
              </Fragment>
            );
          } ) }
        </Card.Body>
      </Card>
    </Accordion>
  );
};
