import React, { Fragment } from 'react';
import { FormGroup, Form, FormControl } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { blankLink } from 'config';
import { ContainerFieldItem, IDocumentDownload, IProductDocumentItem } from 'Services/widgets/interfaces';
import { LinkType } from 'Services/widgets/enums';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useAppLogger } from 'Services/logger';
import { useWidgetService } from 'Services/widget';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { TooltipCore } from './TooltipCore';

export interface CoreFactorCheckboxProps {
  stepItem: ContainerFieldItem;
  productCode: string;
  productDocuments: IProductDocumentItem[];
  fieldName: string;
  fieldValue: string;
  isDisabled?: boolean;
}

const yesValue = '0';
const noValue = '1';

export const CoreFactorCheckbox: React.FC<CoreFactorCheckboxProps> = (
  { stepItem, productCode, productDocuments, fieldName, fieldValue, isDisabled },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { showAlert, hideAlert } = useAppAlertService();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const service = useWidgetService();
  const logger = useAppLogger();

  const [ isLoading, setIsLoading ] = React.useState( false );
  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const { errors, control } = useFormContext();
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltip: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const downloadStaticPdfHandler = React.useCallback(
    async ( e: React.MouseEvent<HTMLAnchorElement>, docItem: IDocumentDownload,
    ): Promise<void> => {
      e.preventDefault();
      hideAlert();

      const entityType = docItem.entityType;
      const documentDescriptionKey = docItem.documentDescriptionKey;
      let document: IProductDocumentItem | undefined = undefined;

      if ( documentDescriptionKey ) {
        document = productDocuments.find( ( d ) => d.description === documentDescriptionKey );
      }

      if( !documentDescriptionKey && entityType ) {
        document = productDocuments.find( ( d ) => d.entityType === entityType );
      }

      if( !document ) {
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );

        return;
      }

      setIsLoading( true );

      try {
        const { url } = await service.getProductDocumentUrl( productCode, document.code );

        window.open( url, '_blank', 'noreferrer' );

        setIsLoading( false );
      } catch ( error ) {
        logger.error( error );
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
        setIsLoading( false );
      }
    }, [ hideAlert, logger, productCode, productDocuments, service, showAlert, t ] );

  const renderComponents = React.useMemo( () => {
    let res = {};

    if ( stepItem && stepItem['linkItems'] ) {
      stepItem['linkItems'].forEach( ( item ) => {
        if ( item.linkType === LinkType.StaticPdf ) {
          res[item.linkKey] =
          <a
            id={ `download-pdf-${stepItem['name']}` }
            href={ blankLink }
            role='button'
            title={ item.name }
            onClick={ ( e ) => {
              e.preventDefault();
              downloadStaticPdfHandler( e, item );
            } }
          >Link
          </a>;
        }
        if ( item.linkType === LinkType.LinkToSite ) {
          res[item.linkKey] =
          <a rel="noopener noreferrer"
            className="font-weight-bold"
            target="_blank"
            title={ item.name }
            href={ item.link }
          >
          Link
          </a>;
        }
      } );
    }

    return res;
  }, [ downloadStaticPdfHandler, stepItem ] );

  const isRequiredCheckbox = (): boolean => {
    if ( stepItem && 'isRequired' in stepItem ) {
      return stepItem.isRequired! as boolean;
    }

    return false;
  };

  const handleChange = React.useCallback( (
    e: React.ChangeEvent<HTMLInputElement>,
    renderProps: ControllerRenderProps<Record<string, any>>,
  ) => {
    let value = e.target.value;

    if( value === yesValue || value === noValue ) {
      value = value === yesValue ? noValue : yesValue;
    }

    renderProps.onChange( value );

  }, [] );

  const onChecked = ( value: string ): boolean => {
    if( value === yesValue || value === noValue ) {
      return value === yesValue ? true : false;
    }

    return false;
  };

  return (
    <Fragment>
      { isLoading && loadingOnFullContainer }
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isRequiredCheckbox(),
          validate: ( value ) => {
            if ( isRequiredCheckbox() && ( value === null || value === noValue ) ) {
              return false;
            }

            return true;
          },
        } }
        defaultValue={ fieldValue || noValue }
        render={ ( props ) => (
          <FormGroup className="mb-0" controlId={ props.name }>
            <FormControl
              { ...props }
              className="form-check-input m-0 wd-24"
              type="checkbox"
              isInvalid={ errors[props.name] !== undefined }
              onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => handleChange( e, props ) }
              checked={ onChecked( props.value ) }
              onBlur={ props.onBlur }
              disabled={ isDisabled }
            />
            <Form.Label className={
              `f-16 core-checkbox-label ${stepItem.tooltip ? ' tooltip-checkbox-label' : '' }` }
            >
              <Trans
                i18nKey={ stepItem['title'] }
                ns="widgets"
                components={ renderComponents }
              />
              { stepItem.tooltip && (
                <a
                  className="tooltip-info"
                  id={ `tooltip-${props.name}` }
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    onShowTooltip( true, stepItem.tooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { errors[props.name] !== undefined && (
              <div className="col-sm-12 px-0 mt-2 d-inline-flex">
                <span className="form-check-input-error">{ t( 'bookingFunnel.summary.checkBoxErr' ) }</span>
              </div>
            ) }
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </Fragment>
  );
};
