import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { isBoolean } from 'lodash';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, FieldStringSettings } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import {
  isCheckedByField,
  renderClassNameBox,
  renderPlaceholder,
} from 'App/components/widgets/dynamic-core/core-hooks';
import { InputType } from 'Services/widgets/enums';
import { formatChars } from '../../../../core-hooks';

export interface StringFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export const StringField: React.FC<StringFieldProps> = ( {
  fieldValue,
  fieldName,
  stepItem,
  isDisabled,
  isReadOnly,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const { inputType, settings } = stepItem || {};
  const { mask, maskChar } = settings as FieldStringSettings || {};

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( stepItem?.isReadOnly || isBoolean( isReadOnly ) ? isReadOnly : false ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( `bookingFunnel.personalData.${fieldName}` );
  }, [ fieldName, stepItem, t ] );

  const getDefaultValue = (): string => {
    if ( fieldValue ) {
      return fieldValue;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    return '';
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  const isUseMaskInput = ( ): boolean => {
    if ( inputType === InputType.Mask && mask ) {
      return true;
    }

    return false;
  };

  const isValidateString = ( value: string ): string | boolean => {
    if ( !value ) {
      return true;
    }

    // TODO: Commenting on this code, will need to redo the validation for the mask
    // if ( isUseMaskInput() ) {
    //   const maskLength = mask.length;
    //   const currentValueLength = value.replace( maskChar, '' ).length;
    //   const errMessage = t( 'base:forms.messages.dateInvalid', {
    //     fieldLabel: fieldLabel,
    //     fieldFormat: mask,
    //   } );

    //   return currentValueLength < maskLength ? errMessage : true;
    // }

    return true;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, fieldValue, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            return isValidateString( value );
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => (
          <FormGroup className={ renderClassNameBox( stepItem ) } controlId={ props.name }>
            <Form.Label>
              { fieldLabel }
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> )
              }
            </Form.Label>
            { isUseMaskInput() ? (
              <InputMask
                mask={ mask }
                maskChar={ maskChar || null }
                disabled={ isDisabled }
                name={ props.name }
                value={ props.value }
                readOnly={ isReadOnlyInput() }
                className={ renderClassName() }
                placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
                isInvalid={ errors[props.name] !== undefined }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
                formatChars={ formatChars }
              >
                { ( inputProps ) =>
                  <Form.Control
                    { ...inputProps }
                    type="text"
                  />
                }
              </InputMask>
            ) : (
              <Form.Control
                { ...props }
                type="text"
                className={ renderClassName() }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
                isInvalid={ errors[props.name] !== undefined }
                disabled={ isDisabled }
              />
            ) }
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
