import React from 'react';
import { Row, Col, FormGroup, Form, FormControl } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { blankLink } from 'config';
import { LinkItem } from 'Services/widgets/interfaces';
import { LinkType } from 'Services/widgets/enums';

export interface ICheckboxProps {
  name: string;
  label: string;
  defaultValue: boolean | undefined;
  formContext: any;
  linkItems?: LinkItem[];
  downloadStaticPdfHandler: (
    e: React.MouseEvent<HTMLAnchorElement>, name: string, entityType: string
  ) => Promise<void>;
}

export const Checkbox: React.FC<ICheckboxProps> = ( {
  name,
  label,
  defaultValue,
  formContext,
  linkItems,
  downloadStaticPdfHandler,
} ) => {
  const { errors, control } = formContext;
  const { t } = useTranslation( [ 'widgets' ] );

  const renderComponents = React.useMemo( () => {
    let res = {};

    if ( linkItems ) {
      linkItems.forEach( ( item ) => {
        if ( item.linkType === LinkType.StaticPdf ) {
          res[item.linkKey] =
          <a
            id={ `download-pdf-${name}` }
            href={ blankLink }
            role='button'
            title={ item.name }
            onClick={ ( e ) => {
              e.preventDefault();
              downloadStaticPdfHandler( e, item.name, item.entityType! );
            } }
          >Link
          </a>;
        }
        if ( item.linkType === LinkType.LinkToSite ) {
          res[item.linkKey] =
          <a rel="noopener noreferrer"
            className="font-weight-bold"
            target="_blank"
            title={ item.name }
            href={ item.link }
          >
          Link
          </a>;
        }
      } );
    }

    return res;
  }, [ downloadStaticPdfHandler, linkItems, name ] );

  return (
    <Col sm={ 12 } className="mb-4 px-0">
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        render={ ( props ) => (
          <FormGroup as={ Row } controlId={ props.name }>
            <Col className="col-auto">
              <FormControl
                { ...props }
                className="form-check-input m-0 wd-24"
                type="checkbox"
                isInvalid={ errors[name] !== undefined }
                onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => props.onChange( e.target.checked ) }
                checked={ props.value }
                onBlur={ props.onBlur }
              />
            </Col>
            <Form.Label className="f-16 checkbox-label w-100">
              <Trans
                i18nKey={ label }
                ns="widgets"
                components={ renderComponents }
              />
            </Form.Label>
            { errors[name] !== undefined && (
              <div className="col-sm-12 mt-3 d-inline-flex">
                <span className="form-check-input-error">{ t( 'bookingFunnel.summary.checkBoxErr' ) }</span>
              </div>
            ) }
          </FormGroup>
        ) }
      />
    </Col>
  );};
