import React, { Fragment } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressSettings, ContainerFieldItem, ILeadData, ITariffDataStep } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import { isCheckedByField, renderClassNameBox } from 'App/components/widgets/dynamic-core/core-hooks';
import { AddressType } from 'App/components/widgets/dynamic-core/enums';
import { AddressFields } from 'Services/widgets/enums';
import { HiddenField } from '../steps/personal-sub-steps/form-fields/HiddenField';
import { useAppLogger } from 'Services/logger';

export interface CoreAddressSwitcherProps {
  lead: ILeadData;
  personalDataFields: ITariffDataStep;
  stepItem: ContainerFieldItem;
  isDisabled?: boolean;
  defaultValue: AddressType | null;
}

export interface SwitcherOption {
  value: AddressType;
}

const switcherOptions: SwitcherOption[] = [
  { value: AddressType.InsuredAddress },
  { value: AddressType.AccountAddress },
];

const hiddenFields: AddressFields[] = [
  AddressFields.City,
  AddressFields.Street,
  AddressFields.HouseNumber,
  AddressFields.ZipCode,
  AddressFields.Country,
];

export const CoreAddressSwitcher: React.FC<CoreAddressSwitcherProps> = (
  { lead, personalDataFields, stepItem, isDisabled, defaultValue },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const logger = useAppLogger();
  const { errors, control, setValue } = useFormContext();

  const isFirstRender = React.useRef<boolean>( true );
  const fieldValue = personalDataFields && personalDataFields[stepItem.fieldName];
  const fieldName = stepItem.fieldName;

  const [ selectedType, setSelectedType ] = React.useState<AddressType>(
    fieldValue as AddressType || AddressType.InsuredAddress );

  const settings = stepItem?.settings as AddressSettings;
  const pathToAddressObject = settings && settings.pathToAddressObject;

  let switcherList = switcherOptions;

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return '';
  }, [ stepItem ] );

  const getDefaultValue = (): AddressType => {
    if ( fieldValue ) {
      return fieldValue as AddressType;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as AddressType;
    }

    return AddressType.InsuredAddress;
  };

  const getHiddenValue = ( name ): string => {
    if ( pathToAddressObject ) {
      const splitPath = pathToAddressObject.split( '.' );

      if ( splitPath.length >= 2 ) {
        const hiddenField = name === AddressFields.ZipCode ? AddressFields.Zip : name;
        const fieldKeyFirstItem = `${hiddenField}_${splitPath[1]}1`;
        const foundValueFirstItem = lead[splitPath[0]] ? lead[splitPath[0]][fieldKeyFirstItem] : undefined;

        if ( foundValueFirstItem ) {
          return foundValueFirstItem;
        }

        const fieldKey = `${hiddenField}_${splitPath[1]}`;
        const foundFieldVal = lead[splitPath[0]] ? lead[splitPath[0]][fieldKey] : undefined;

        if ( foundFieldVal ) {
          return foundFieldVal;
        }
      }

    }

    return '';
  };

  React.useEffect( () => {
    if ( defaultValue !== null && isFirstRender.current ) {
      isFirstRender.current = false;

      setValue( fieldName, defaultValue, { shouldValidate: true } );

      setSelectedType( defaultValue );
    }
  }, [ defaultValue, fieldName, isDisabled, setValue, stepItem ] );

  if ( !fieldName && isFirstRender.current ) {
    logger.error( 'This field does not have a correct name: ', stepItem );
    isFirstRender.current = false;
    return <></>;
  }

  return (
    <Fragment>
      { fieldName && (
        <Fragment>
          { fieldLabel && (
            <Form.Label className="bf-main-label">
              { fieldLabel }
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> )
              }
            </Form.Label>
          ) }
          <Controller
            name={ fieldName }
            control={ control }
            rules={ { required: isCheckedByField( 'isRequired', stepItem! ) } }
            defaultValue={ getDefaultValue() }
            render={ ( props ) => (
              <FormGroup className={ `custom-btn-radio btn-col-2 mb-2 ${renderClassNameBox( stepItem ) }` }>
                { switcherList.map( ( o, idx ) => {
                  return (
                    <div className={ `box-radio ${ props.value === o.value ? 'bf-item-checked' : '' }` } key={ idx }>
                      <FormControl
                        { ...props }
                        className="form-check-input"
                        type="radio"
                        id={ o.value }
                        value={ o.value }
                        checked={ props.value === o.value }
                        onChange={ ( e ) => {
                          props.onChange( e );

                          setSelectedType( o.value as AddressType );
                        } }
                        onBlur={ props.onBlur }
                        disabled={ isDisabled }
                      />
                      <Form.Label
                        id={ `label-${ o.value }` }
                        className="btn btn-border-radio"
                        htmlFor={ o.value }
                      >
                        { t( `bookingFunnel.personalData.${o.value}` ) }
                      </Form.Label>
                    </div>
                  );
                } ) }
                <Form.Control
                  type="hidden"
                  isInvalid={ errors[props.name] !== undefined }
                />
                <Form.Control.Feedback type="invalid">
                  { t( 'base:forms.messages.fieldRequired', {
                    fieldLabel: t( 'bookingFunnel.personalData.addressTitle' ),
                  } ) }
                </Form.Control.Feedback>
              </FormGroup>
            ) }
          />
          { selectedType === AddressType.InsuredAddress && hiddenFields.map( ( elementName, idx ) => {
            return (
              <Fragment key={ idx }>
                <HiddenField
                  fieldName={ elementName }
                  fieldValue={ getHiddenValue( elementName ) }
                />
              </Fragment>
            );
          } ) }
        </Fragment>
      ) }
    </Fragment>
  );
};
