import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { blankLink } from 'config';
import { JsonValue } from '@cover42/protobuf-util';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem, FieldStringSettings } from 'Services/widgets/interfaces';
import { checkedUnderwriting, isRequiredField } from '../../../booking-funnel/booking-funnel-hooks';
import { AsteriskRequiredField } from '../../../AsteriskRequiredField';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';
import { isBoolean } from 'lodash';
import { formatChars, renderFieldLabel, renderPlaceholder } from '../../core-hooks';
import { InputType } from 'Services/widgets/enums';

export interface StringFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const StringField: React.FC<StringFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue } = useFormContext();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const { inputType, settings } = stepItem || {};
  const { mask, maskChar } = settings as FieldStringSettings || {};

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    if ( productFieldValue ) {
      return productFieldValue as string;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string || '';
    }

    return stringValue !== null ? stringValue as string : '';
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return `${classNameField} read-only`;
    }

    if ( stepItem?.isReadOnly ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  const isUseMaskInput = ( ): boolean => {
    if ( inputType === InputType.Mask && mask ) {
      return true;
    }

    return false;
  };

  const isValidateString = ( value: string ): string | boolean => {
    if ( !value ) {
      return true;
    }

    // TODO: Commenting on this code, will need to redo the validation for the mask
    // if ( isUseMaskInput() ) {
    //   const maskLength = mask.length;
    //   const currentValueLength = value.replace( maskChar, '' ).length;
    //   const errMessage = t( 'base:forms.messages.dateInvalid', {
    //     fieldLabel: variable.label,
    //     fieldFormat: mask,
    //   } );

    //   return currentValueLength < maskLength ? errMessage : true;
    // }

    return true;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            return isValidateString( value );
          },
        } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup className={ stepItem?.classNameBox! } controlId={ props.name }>
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            { isUseMaskInput() ? (
              <InputMask
                mask={ mask }
                maskChar={ maskChar || null }
                disabled={ isDisabled }
                name={ props.name }
                value={ props.value }
                readOnly={ isReadOnlyInput() }
                className={ renderClassName() }
                placeholder={ renderPlaceholder( variable.label, stepItem ) }
                isInvalid={ errors[props.name] !== undefined }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
                onBlur={ ( ) => {
                  const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                  actions.recalculationPremium( isCheckedUnderwriting );
                } }
                formatChars={ formatChars }
              >
                { ( inputProps ) =>
                  <Form.Control
                    { ...inputProps }
                    type="text"
                  />
                }
              </InputMask>
            ) : (
              <Form.Control
                { ...props }
                type={ inputType ? inputType as string : 'text' }
                className={ renderClassName() }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( variable.label, stepItem ) }
                isInvalid={ errors[props.name] !== undefined }
                disabled={ isDisabled }
                onBlur={ ( ) => {
                  const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                  actions.recalculationPremium( isCheckedUnderwriting );
                } }
              />
            ) }
            <FormControl.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </FormControl.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
