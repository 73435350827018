import React, { Fragment } from 'react';
import closeIcon from 'assets/bf-images/funk/close_green_icon.svg';
import wavingHandIcon from 'assets/bf-images/funk/waving_hand_icon.svg';
import { Modal } from 'react-bootstrap';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import { CoreResultStructure, ICustomerItem, IHTMLText, ResultStructure } from 'Services/widgets/interfaces';
import { replacePlaceholders } from '../../core-hooks';
import { LoginUserProps } from '../../core-booking-funnel/steps/personal-sub-steps/LoginUserCoreStep';

export interface AllBFDataLoginStep extends LoginUserProps {
  customerData: ICustomerItem;
}

export interface CorePopupProps {
  userName: string;
  onClose: () => void;
  successResult?: ResultStructure;
  allData?: AllBFDataLoginStep;
}

export const CoreWelcomePopup: React.FC<CorePopupProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { userName, onClose, successResult, allData } = props;
  const { htmlText, body, linkedItems } = successResult as unknown as CoreResultStructure || {};

  let titleText = t( 'bookingFunnel.funkReanovo.welcomeTitle', {
    userName: userName,
  } );

  let bodyText = t( 'bookingFunnel.funkReanovo.welcomeInfo' );

  if ( htmlText ) {
    const title: IHTMLText = {
      htmlText,
      linkedItems,
    };
    titleText = replacePlaceholders( title, userName, allData );
  }

  if ( body ) {
    const bodyData: IHTMLText = {
      htmlText: body,
      linkedItems,
    };
    bodyText = replacePlaceholders( bodyData, '', allData );
  }

  return (
    <Fragment>
      <div className="modal-open">
        <div className="fade modal-backdrop show"></div>
        <div className="fade dynamic-tooltip-modal modal show" style={ { display: 'block' } }>
          <Modal.Dialog centered>
            <Modal.Body>
              <a
                href={ blankLink }
                role='button'
                onClick={ ( e ) => {
                  e.preventDefault();
                  onClose();
                } }
              >
                <img src={ closeIcon } className="float-right" alt="Close Icon" />
              </a>
              <div
                className="my-4 mx-3 bf-default-color text-center"
              >
                <h1 className="f-26">
                  { titleText }
                </h1>
                <img src={ wavingHandIcon } alt="Waving Hand Icon" />
                <h2 className="f-26 mt-4">{ bodyText }</h2>
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      </div>
    </Fragment>
  );
};
