import React from 'react';
import dayjs from 'dayjs';
import { sleep } from 'App/utils';
import { IStorage } from 'App/components/utils/providers/AppStorageCtx';
import { ICFFactorType } from 'App/components/widgets/factor-service';
import {
  accountsStorageKey,
  IBFCustomStylesSettings,
  IBFInsuredObjectItem,
  ICreateLeadData,
  ICreateLeadDataWS,
  ICustomApplicationItemWS,
  ICustomApplicationWS,
  ICustomerItem,
  IInitiateAuth,
  IInitiateAuthResponse,
  IInitiateLeadResponse,
  IInitiatePaymentSetup,
  IInsuranceTypeItem,
  ILeadData,
  IPayloadForDocumentPdf,
  IPayloadInvoice,
  IPaymentMethod,
  IPaymentSystem,
  IPaymentSystemComplete,
  IPolicyObject,
  IPolicyObjects,
  IProductDataForInvoice,
  IProductDataForInvoiceWS,
  IProductInvoiceData,
  IProductInvoiceItemWS,
  IProductPremiumData,
  IPSPConfig,
  IRespondAuth,
  IRespondAuthResponse,
  IStaticDocumentItem,
  ITariffDataSend,
  ITariffDataStep,
  LeadDataResponse,
  LeadItem,
  MutationResponse,
  SendEmailPayload,
  SendEmailResponse,
  LeadDataItemResponse,
  AddressItem,
  ValidateAddress,
  WorkflowItem,
  PayloadRule,
  VariableItem,
  ProductItem,
  IProductDocumentItem,
  GetProductDocumentUrlResponse,
  EmailVerificationResponse,
  InitiateEmailVerification,
  CompleteEmailVerification,
  PreSignedPostRequest,
  PreSignedPost,
  FilterNamedRangeRequest,
  FilterNamedRangeResponse,
} from './widgets/interfaces';

import { IPaymentDataStep } from '../App/components/widgets/booking-funnel/steps/PaymentStep3';
import { IPersonalDataStep } from '../App/components/widgets/booking-funnel/steps/PersonalDataStep2';
import {
  CompleteEmailVerificationRequest,
  CompletePaymentDataRequest,
  CompletePaymentSetupRequest,
  CreateCustomApplicationRequest,
  CreateLeadRequest,
  DownloadPdfDocumentRequest,
  DownloadStaticPdfDocumentRequest,
  GetAddressCompletionsRequest,
  GetBFCustomStylesSettingsRequest,
  GetCustomerInfoRequest,
  GetFactorsRequest,
  GetInsuredObjectsRequest,
  GetLeadDataRequest,
  GetPSPConfig,
  GetProductByCodeRequest,
  GetProductDocumentUrlRequest,
  GetStaticDocumentsRequest,
  GetProductDocumentsRequest,
  GetUnderwritingRules,
  GetWorkflowBySlugRequest,
  InitiateAuthRequest,
  InitiateEmailVerificationRequest,
  InitiateLeadRequest,
  InitiatePaymentMethodRequest,
  InitiatePaymentSetupRequest,
  ListInsuredObjectTypeRequest,
  ProductInvoiceRequest,
  ProductInvoiceWSRequest,
  ProductPremiumRequest,
  RefreshTokenEISRequest,
  RespondAuthRequest,
  RespondBySmsCodeAuthRequest,
  SendEmailRequest,
  UpdateLeadRequest,
  ValidateAddressRequest,
  GetStaticDocumentsByProductCodeRequest,
  AuthRequest,
  UploadDocumentWithPreSignedUrlRequest,
  PresignedUrlRequest,
  DeleteUploadedDocument,
  FilterNamedRange,
  GetBookingFunnelSettingsRequest,
} from './widgets/requests';
import { IApiService } from 'Services/base';
import { IPaymentDataStepWS } from 'App/components/widgets/worksurance/steps/PaymentStep3';
import {
  IBaseAuth,
  IFullAuth,
  IToken,
  IUserInfo,
  parseTokenResponse,
  RefreshTokenRequest,
  TokenResponse,
} from './api/auth/base';
import { JsonValue } from '@cover42/protobuf-util';
import { IAppUserMeta } from 'App/types';

export interface IWidgetService {
  getProductFactors( productCode: string, allValues: boolean ): Promise<ICFFactorType[]>;
  getInsuredObjects( productCode: string ): Promise<IBFInsuredObjectItem[]>;
  getProductInvoice( productCode: string, data: IProductDataForInvoice ): Promise<IProductInvoiceData>;
  calculateTariff( productCode: string, data: IPolicyObject[] ): Promise<IProductPremiumData>;
  downloadPdfDocument( payload: IPayloadForDocumentPdf ): Promise<BlobPart>;
  downloadStaticPdfDocument( documentCode: string ): Promise<BlobPart>;
  getProductDocumentUrl( productCode: string, documentCode: string ): Promise<GetProductDocumentUrlResponse>;
  getStaticDocuments( ): Promise<IStaticDocumentItem[]>;
  getProductDocuments( productCode: string ): Promise<IProductDocumentItem[]>;
  createLead( leadData: ICreateLeadData, isClearLeadData?: boolean, brokerId?: string ): Promise<LeadDataResponse>;
  getLead( waitTime?: number ): Promise<ILeadData>;
  sendSubstep1Tariff( data: ITariffDataSend ): Promise<MutationResponse>;
  sendSubstep2Tariff( data: ITariffDataStep | null ): Promise<MutationResponse>;
  savePersonalData( data: IPersonalDataStep ): Promise<MutationResponse>;
  saveQuestionData( data: ITariffDataStep ): Promise<MutationResponse>;
  applyForPolicy(): Promise<MutationResponse>;
  getAllInsuredObjectTypes( productCode: string ): Promise<IInsuranceTypeItem[]>;
  getBFCustomStylesSettings( productCode: string ): Promise<IBFCustomStylesSettings>;
  initiateLead(): Promise<IInitiateLeadResponse>;
  initiatePaymentSetup( initiateData: IPaymentSystem ): Promise<IInitiatePaymentSetup>;
  completePaymentSetup( paymentSetup: IPaymentSystemComplete, formData: IPaymentDataStep ): Promise<IPaymentMethod>;
  savePaymentData( data: IPaymentDataStepWS ): Promise<MutationResponse>;
  getEstimatedInvoiceWS( productCode: string, data: IProductDataForInvoiceWS ): Promise<IProductInvoiceItemWS[]>;
  updateEstimatedInvoiceWS( productCode: string, data: IProductDataForInvoiceWS ): Promise<IProductInvoiceItemWS[]>;
  seveTariffDataWS( data: IProductInvoiceItemWS[] ): Promise<MutationResponse>;
  seveEstimatedInvoiceWS( data: IProductInvoiceItemWS ): Promise<MutationResponse>;
  createCustomApplication(
    productCode: string, applicationData: ICustomApplicationWS
  ): Promise<ICustomApplicationItemWS>;
  createLeadWS( leadData: ICreateLeadDataWS ): Promise<LeadDataResponse>;
  selectedTariff( data: ITariffDataStep ): Promise<MutationResponse>;
  sendChangeResult( data: ITariffDataStep ): Promise<MutationResponse>;
  saveSelectedClient( data: ITariffDataStep ): Promise<MutationResponse>;
  saveSummaryData( data: ITariffDataStep ): Promise<MutationResponse>;
  saveLoginData( value: boolean ): Promise<MutationResponse>;
  sendSubstep2Result( data: ITariffDataStep ): Promise<MutationResponse>;
  savedInfo( nameField: string, value: JsonValue | ITariffDataStep ): Promise<MutationResponse>;
  initiateAuthorization( loginRequest: IInitiateAuth ): Promise<IInitiateAuthResponse>;
  respondAuthorization( loginRequest: IRespondAuth ): Promise<IRespondAuthResponse>;
  authorizationBySmsCode( loginRequest: IRespondAuth ): Promise<IFullAuth>;
  authorization( loginRequest: IInitiateAuth ): Promise<IFullAuth>;
  createLeadFunk( leadData: ICreateLeadData ): Promise<LeadDataResponse>;
  updateLeadFunk( leadData: ICreateLeadData, brokerId?: string ): Promise<LeadDataResponse>;
  getCustomerInfo(): Promise<ICustomerItem>;
  getLeadData( leadCode: string ): Promise<MutationResponse>;
  ckeckLeadData( leadCode: string ): Promise<LeadItem | null>;
  sendEmail( payloadData: SendEmailPayload ): Promise<SendEmailResponse>;
  refreshToken( userName: string, tenantSlug: string ): Promise<IFullAuth>;
  getCustomerData(): Promise<ICustomerItem>;
  initiatePaymentMethod( code?: string ): Promise<IInitiatePaymentSetup>;
  completePaymentData( paymentData: IPaymentSystemComplete ): Promise<IPaymentMethod>;
  getPSPConfig( tenantSlug: string ): Promise<IPSPConfig>;
  getLeadByCode( leadCode: string ): Promise<MutationResponse>;
  getLeadByCodeCore( leadCode: string ): Promise<MutationResponse>;
  refreshTokenEIS( userName: string ): Promise<IFullAuth>;
  resetLead(): Promise<ILeadData>;
  getAddressCompletions( partialAddress: string, country?: string ): Promise<AddressItem[]>;
  validateAddress( addressItem: AddressItem ): Promise<ValidateAddress>;
  getWorkflow( workflowSlug: string ): Promise<WorkflowItem | null>;
  getUnderwritingResult( workflowCode: string, payloadData: PayloadRule ): Promise<VariableItem>;
  resetUnderwritingResult(): Promise<MutationResponse>;
  getProductByCode( productCode: string ): Promise<ProductItem | null>;
  getStaticDocumentsByProductCode( productCode: string ): Promise<IStaticDocumentItem[]>;
  initiateEmailVerification( payload: InitiateEmailVerification ): Promise<EmailVerificationResponse>;
  completeEmailVerification( payload: CompleteEmailVerification ): Promise<EmailVerificationResponse>;
  getPreSignedRequestUrl( data: PreSignedPostRequest ): Promise<PreSignedPost>;
  uploadDocumentUsingPreSignedUrl(
    { fields, url }: PreSignedPost,
    file: File,
    onUploadProgress?: ( progressEvent: any ) => void,
  ): Promise<Record<string, string>>;
  updateLead( leadData: ICreateLeadData, isClearLeadData?: boolean, brokerId?: string ): Promise<LeadDataResponse>;
  deleteUploadedDocumentByLeadCode( documentCode: string, leadCode: string ): Promise<void>;
  filterNamedRange( request: FilterNamedRangeRequest ): Promise<FilterNamedRangeResponse>;
  getBookingFunnel( code: string ): Promise<IBFCustomStylesSettings>;
}

const versionData = '1.5.9';
const customFieldsKey = 'customFields';

const initialLeadData: ILeadData = {
  subStep1TariffData: null,
  subStep2TariffData: null,
  subStep1DeviceItems: null,
  personalData: null,
  paymentData: null,
  versionData: versionData,
  isEditLead: false,
};

export class WidgetService implements IWidgetService {
  protected api: IApiService;
  protected storage: IStorage;
  protected currentUser: IUserInfo | null;
  protected userHolderDict: Record<string, IFullAuth>;
  protected keyStorage: string;
  protected workflowItem: WorkflowItem | null;
  protected underwritingResult: VariableItem | null;

  constructor( api: IApiService, storage: IStorage ) {
    this.api = api;
    this.storage = storage;
    this.currentUser = null;
    this.userHolderDict = {};
    this.keyStorage = `LEAD_DATA_${api.productCode}`;
    this.workflowItem = null;
    this.underwritingResult = null;
  }

  async getProductFactors( productCode: string, allValues: boolean ): Promise<ICFFactorType[]> {
    try {
      const response = await this.api.request( new GetFactorsRequest( productCode, allValues ) );

      return response;
    } catch( e ) {
      throw new Error( 'Product factors does not exist' );
    }
  }

  async getInsuredObjects( productCode: string ): Promise<IBFInsuredObjectItem[]> {
    try {
      const response = await this.api.request( new GetInsuredObjectsRequest( productCode ) );

      return response;
    } catch( e ) {
      throw new Error( 'Product fields does not exist' );
    }
  }

  async getProductInvoice( productCode: string, data: IProductDataForInvoice ): Promise<IProductInvoiceData> {
    try {
      const payload: IPayloadInvoice = {
        policyObjects: data.policyObjects,
      };
      const response = await this.api.request( new ProductInvoiceRequest( productCode, payload ) );
      const { invoice } = response;

      return invoice;
    } catch( e ) {
      throw new Error( 'There has been an error on product invoice' );
    }
  }

  async calculateTariff( productCode: string, data: IPolicyObject[] ): Promise<IProductPremiumData> {
    try {
      const policyObjects: IPolicyObjects = { 'policyObjects': data };
      const response = await this.api.request( new ProductPremiumRequest( productCode, policyObjects ) );
      const { premium } = response;

      return premium;
    } catch( e ) {
      throw new Error( 'There has been an error on premium calculation' );
    }
  }

  async downloadPdfDocument( payload: IPayloadForDocumentPdf ): Promise<BlobPart> {
    const response = await this.api.request( new DownloadPdfDocumentRequest( payload ) );

    return response;
  }

  async downloadStaticPdfDocument( documentCode: string ): Promise<BlobPart> {
    const response = await this.api.request( new DownloadStaticPdfDocumentRequest( documentCode ) );

    return response;
  }

  async getProductDocumentUrl( productCode: string, documentCode: string ): Promise<GetProductDocumentUrlResponse> {
    const response = await this.api.request( new GetProductDocumentUrlRequest( productCode, documentCode ) );

    return response;
  }

  async getStaticDocuments( ): Promise<IStaticDocumentItem[]> {
    const response = await this.api.request( new GetStaticDocumentsRequest( ) );

    const { items } = response;

    return items;
  }

  async getProductDocuments( productCode: string ): Promise<IProductDocumentItem[]> {
    const response = await this.api.request( new GetProductDocumentsRequest( productCode ) );

    const { items } = response;

    return items;
  }

  async createLead(
    leadData: ICreateLeadData, isClearLeadData: boolean = true, brokerId?: string,
  ): Promise<LeadDataResponse> {
    try {

      let bearerToken = '';

      if ( brokerId ) {
        bearerToken = this.generateBearerToFunk( brokerId );
      }

      const lead = await this.api.request( new CreateLeadRequest( leadData, bearerToken ) );

      if ( isClearLeadData ) {
        this.saveLead( initialLeadData );
      }

      return lead;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  async getLead( waitTime: number = 300 ): Promise<ILeadData> {
    await sleep( waitTime );
    const localLead = this.storage.get( this.keyStorage ) as ILeadData;
    if ( localLead !== null && localLead.versionData && localLead.versionData === versionData ) {
      return localLead;
    } else {
      this.storage.set( this.keyStorage, initialLeadData );
      return initialLeadData;
    }
  }

  async createCustomApplication(
    productCode: string, applicationData: ICustomApplicationWS,
  ): Promise<ICustomApplicationItemWS> {
    try {
      const response = await this.api.request( new CreateCustomApplicationRequest( productCode, applicationData ) );

      const { application } = response;

      return application;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  async createLeadWS( leadData: ICreateLeadDataWS ): Promise<LeadDataResponse> {
    try {
      const lead = await this.api.request( new CreateLeadRequest( leadData ) );

      this.saveLead( initialLeadData );

      return lead;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  sendSubstep1Tariff( data: ITariffDataSend ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      let updateLeadData: ILeadData = {
        ...leadData,
        subStep1TariffData: data.formData,
      };

      if ( data && data.deviceItems ) {
        updateLeadData.subStep1DeviceItems = data.deviceItems;
      }

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  sendSubstep2Tariff( data: ITariffDataStep | null ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        subStep2TariffData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  savePersonalData( data: IPersonalDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        personalData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  saveQuestionData( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        questionData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  sendSubstep2Result( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        subStep2ResultData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  selectedTariff( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        selectedTariff: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  sendChangeResult( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        tariffResultData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  saveSelectedClient( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        saveSelectedClient: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  saveSummaryData( data: ITariffDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        summaryData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  saveLoginData( value: boolean ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        isLogin: value,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  async savedInfo( nameField: string, value: JsonValue | ITariffDataStep ): Promise<MutationResponse> {
    const leadData: ILeadData = await this.getLead();

    const updateLeadData: ILeadData = {
      ...leadData,
      [nameField]: value,
    };

    this.saveLead( updateLeadData );

    return { errorCode: 0 };
  }

  applyForPolicy(): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      //TODO: Here we have to apply for policy and remove the localstorage data
      this.saveLead( initialLeadData );

      return { errorCode: 0 };
    } );
  }

  async getAllInsuredObjectTypes( productCode: string ): Promise<IInsuranceTypeItem[]> {
    const response = await this.api.request( new ListInsuredObjectTypeRequest( productCode ) );

    return response;
  }

  async deleteUploadedDocumentByLeadCode( documentCode: string, leadCode: string ): Promise<void> {
    return await this.api.request( new DeleteUploadedDocument( documentCode, leadCode ) );
  }

  async getBFCustomStylesSettings( productCode: string ): Promise<IBFCustomStylesSettings> {
    let settings: IBFCustomStylesSettings = {
      logo: '',
      styles: '',
    };

    try {
      const response = await this.api.request( new GetBFCustomStylesSettingsRequest( productCode ) );
      const { values } = response;

      if ( values && values[0] ) {
        const data = JSON.parse( values[0] );

        settings = {
          ...data,
        };


        if ( data && data.stepsConfig ) {
          const stepsConfig = data.stepsConfig as string;
          settings.stepsConfig = JSON.parse( stepsConfig );
        }
      }

      return settings;
    } catch( e ) {
      return settings;
    }
  }

  async initiateLead(): Promise<IInitiateLeadResponse> {
    try {
      const response = await this.api.request( new InitiateLeadRequest( ) );

      return response;
    } catch( e ) {
      throw new Error( 'There has been an error on initiated lead' );
    }
  }

  async initiatePaymentSetup( initiateData: IPaymentSystem ): Promise<IInitiatePaymentSetup> {
    try {
      const response = await this.api.request( new InitiatePaymentSetupRequest( initiateData ) );

      return response;
    } catch( e ) {
      throw new Error( 'There has been an error on initiated Payment Setup' );
    }
  }

  async completePaymentSetup(
    paymentSetup: IPaymentSystemComplete,
    formData: IPaymentDataStep,
  ): Promise<IPaymentMethod> {
    try {
      const response = await this.api.request( new CompletePaymentSetupRequest( paymentSetup ) );

      const { paymentMethod } = response;

      if ( paymentMethod ) {
        const leadData: ILeadData = await this.getLead();

        const updateLeadData: ILeadData = {
          ...leadData,
          paymentData: formData,
        };

        this.saveLead( updateLeadData );
      }

      return paymentMethod;
    } catch( e ) {
      throw new Error( 'There has been an error on completed Payment Setup' );
    }
  }

  async getEstimatedInvoiceWS( productCode: string, data: IProductDataForInvoiceWS ): Promise<IProductInvoiceItemWS[]> {
    try {
      let premium: IProductInvoiceItemWS[] = [];

      const response = await this.api.request( new ProductInvoiceWSRequest( productCode, data ) );
      const { custom } = response;

      if ( custom && custom.premium ) {
        premium = custom.premium.map( ( item ) => {
          const premiumItem: IProductInvoiceItemWS = {
            ...item,
          };

          if ( !item.monthly_gross_premium ) {
            premiumItem.monthly_gross_premium = item.yearly_gross_premium / 12;
          }

          return premiumItem;
        } );

        const leadData: ILeadData = await this.getLead();

        const updateLeadData: ILeadData = {
          ...leadData,
          estimatedInvoiceData: premium,
          subStep2TariffData: null,
          tariffData: undefined,
          selectedEstimatedInvoiceData: undefined,
        };

        this.saveLead( updateLeadData );
      }

      return premium;
    } catch( e ) {
      throw new Error( 'Invoice data does not exist' );
    }
  }

  async updateEstimatedInvoiceWS(
    productCode: string, data: IProductDataForInvoiceWS,
  ): Promise<IProductInvoiceItemWS[]> {
    try {
      let premium: IProductInvoiceItemWS[] = [];

      const response = await this.api.request( new ProductInvoiceWSRequest( productCode, data ) );
      const { custom } = response;

      if ( custom && custom.premium ) {
        premium = custom.premium.map( ( item ) => {
          const premiumItem: IProductInvoiceItemWS = {
            ...item,
          };

          if ( !item.monthly_gross_premium ) {
            premiumItem.monthly_gross_premium = item.yearly_gross_premium / 12;
          }

          return premiumItem;
        } );

        const leadData: ILeadData = await this.getLead();

        const estimatedInvoiceData = [ ...leadData.estimatedInvoiceData!, ...premium ];

        const updateLeadData: ILeadData = {
          ...leadData,
          estimatedInvoiceData,
        };

        this.saveLead( updateLeadData );
      }

      return premium;
    } catch( e ) {
      throw new Error( 'Invoice data does not exist' );
    }
  }

  savePaymentData( data: IPaymentDataStep ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        paymentData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  seveTariffDataWS( data: IProductInvoiceItemWS[] ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        tariffData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  seveEstimatedInvoiceWS( data: IProductInvoiceItemWS ): Promise<MutationResponse> {
    return sleep( 500 ).then( async (): Promise<MutationResponse> => {
      const leadData: ILeadData = await this.getLead();

      const updateLeadData: ILeadData = {
        ...leadData,
        selectedEstimatedInvoiceData: data,
      };

      this.saveLead( updateLeadData );

      return { errorCode: 0 };
    } );
  }

  async initiateAuthorization( loginRequest: IInitiateAuth ): Promise<IInitiateAuthResponse> {
    try {
      const response = await this.api.request( new InitiateAuthRequest( loginRequest ) );

      return response;
    } catch( e ) {
      throw new Error( 'There has been an error on completed initiate auth' );
    }
  }

  async respondAuthorization( loginRequest: IRespondAuth ): Promise<IRespondAuthResponse> {
    try {
      const response = await this.api.request( new RespondAuthRequest( loginRequest ) );

      return response;
    } catch( e ) {
      throw new Error( 'There has been an error on completed login' );
    }
  }

  async authorizationBySmsCode( loginRequest: IRespondAuth ): Promise<IFullAuth> {
    try {
      const response = await this.api.request( new RespondBySmsCodeAuthRequest( loginRequest ) );
      const { authenticationResult } = response;

      const tockenInfo: TokenResponse = {
        accessToken: authenticationResult.accessToken,
      };

      const parserToken = parseTokenResponse( tockenInfo );

      this.updateAccessToken( parserToken.token );

      const accountData = this.updateAccountStorage( parserToken );

      this.userHolderDict[parserToken.token.userName] = accountData;

      return accountData;
    } catch ( error ) {
      throw new Error( 'There has been an error on completed login' );
    }
  }

  async authorization( loginRequest: IInitiateAuth ): Promise<IFullAuth> {
    try {
      const response = await this.api.request( new AuthRequest( loginRequest ) );
      const { authenticationResult } = response;

      const tockenInfo: TokenResponse = {
        accessToken: authenticationResult.accessToken,
      };

      const parserToken = parseTokenResponse( tockenInfo );

      this.updateAccessToken( parserToken.token );

      const accountData = this.updateAccountStorage( parserToken );

      this.userHolderDict[parserToken.token.userName] = accountData;

      return accountData;
    } catch ( error ) {
      throw new Error( 'There has been an error on completed login' );
    }
  }

  async createLeadFunk( leadData: ICreateLeadData ): Promise<LeadDataResponse> {
    try {
      const lead = await this.api.request( new CreateLeadRequest( leadData ) );

      return lead;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  async updateLeadFunk( leadData: ICreateLeadData, brokerId?: string ): Promise<LeadDataResponse> {
    try {
      let bearerToken = '';

      if ( brokerId ) {
        bearerToken = this.generateBearerToFunk( brokerId );
      }

      const lead = await this.api.request( new UpdateLeadRequest( leadData, bearerToken ) );

      return lead;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  async getCustomerInfo( ): Promise<ICustomerItem> {
    try {
      const response = await this.api.request( new GetCustomerInfoRequest( ) );

      const { customer } = response;

      return customer;
    } catch( e ) {
      throw new Error( 'There has been an error on get account data' );
    }
  }

  async getLeadData( leadCode: string ): Promise<MutationResponse> {
    try {
      const response = await this.api.request<LeadDataItemResponse>( new GetLeadDataRequest( leadCode ) );

      const { lead } = response;

      if ( lead.customData && lead.customData.saveData ) {
        const leadData: ILeadData = await this.getLead();

        const today = dayjs();
        const savedAt = lead.customData.saveData.savedAt;
        const agoDays = today.diff( savedAt, 'days' );

        if ( agoDays < 14 ) {
          const updateLeadData: ILeadData = {
            ...leadData,
            ...lead.customData.saveData,
            isSavedData: true,
          };

          if ( lead.account ) {
            const accountData = lead.account;
            const customFields = accountData.hasOwnProperty( customFieldsKey ) ? accountData[customFieldsKey] : {};

            delete accountData[customFieldsKey];

            updateLeadData.personalData = {
              ...updateLeadData.personalData,
              ...accountData,
              ...customFields,
            };
          }

          this.saveLead( updateLeadData );

          return { errorCode: 0 };
        }

        return { errorCode: 1 };
      }

      return { errorCode: 400 };

    } catch( e ) {
      return {
        errorCode: 404,
        errorMessage: 'There has been an error on get lead data',
      };
    }
  }

  async ckeckLeadData( leadCode: string ): Promise<LeadItem | null> {
    try {
      const response = await this.api.request<LeadDataResponse>( new GetLeadDataRequest( leadCode ) );

      const { lead } = response;

      return lead;

    } catch( e ) {
      return null;
    }
  }

  async sendEmail( payloadData: SendEmailPayload ): Promise<SendEmailResponse> {
    try {
      const response = await this.api.request( new SendEmailRequest( payloadData ) );

      return response;
    } catch( err ) {
      const errorData = err as any;

      const errorRes = {
        ...errorData.error.data,
      };

      return errorRes;
    }
  }

  async refreshToken( userName: string, tenantSlug: string ): Promise<IFullAuth> {
    try {
      if ( this.userHolderDict[userName] !== undefined ) {
        const auth = this.userHolderDict[userName];
        if ( !this.accessTokenRefreshRequired( auth.token ) ) {
          // we can reuse token from dict without real refresh
          return Promise.resolve( auth );
        }
      }

      const response = await this.api.requestToken<TokenResponse>( new RefreshTokenRequest( userName, tenantSlug ) );
      const parserToken = parseTokenResponse( response );

      this.updateAccessToken( parserToken.token );

      const accountData = this.updateAccountStorage( parserToken );

      this.currentUser = accountData.userInfo;
      this.userHolderDict[parserToken.token.userName] = accountData;

      return accountData;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async getCustomerData( ): Promise<ICustomerItem> {
    try {
      const response = await this.api.request( new GetCustomerInfoRequest( ) );

      const { customer } = response;

      return customer;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async initiatePaymentMethod( code?: string ): Promise<IInitiatePaymentSetup> {
    try {
      const response = await this.api.request( new InitiatePaymentMethodRequest( code ) );

      return response;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async completePaymentData( paymentData: IPaymentSystemComplete ): Promise<IPaymentMethod> {
    try {
      const response = await this.api.request( new CompletePaymentDataRequest( paymentData ) );

      const { paymentMethod } = response;

      return paymentMethod;
    } catch( e ) {
      throw new Error( 'There has been an error on completed Payment Setup' );
    }
  }

  async getPSPConfig( tenantSlug: string ): Promise<IPSPConfig> {
    try {
      return this.api.request( new GetPSPConfig( ) );
    } catch( e ) {
      throw new Error( 'There has been an error on get psp config.' );
    }
  }

  private accessTokenRefreshRequired( token: IToken ): boolean {
    const now = dayjs().utc();
    const timeToInvalidateToken = token.exp.diff ( now ) - 10 * 1000;
    // we can reuse existing token only if currect token is valid at least for 10 seconds.
    // if less than 10 seconds we will need to refresh token before calling any api request.
    return timeToInvalidateToken < 0;
  }

  async getLeadByCode( leadCode: string ): Promise<MutationResponse> {
    try {
      const response = await this.api.request<LeadDataItemResponse>( new GetLeadDataRequest( leadCode ) );

      const { lead } = response;

      if ( !lead ) {
        return { errorCode: 400 };
      }

      if ( lead.customData && lead.customData.saveData ) {
        const leadData: ILeadData = await this.getLead();

        const today = dayjs();
        const savedAt = lead.customData.saveData.savedAt;
        const agoDays = today.diff( savedAt, 'days' );

        if ( agoDays < 14 ) {
          const updateLeadData: ILeadData = {
            ...leadData,
            ...lead.customData.saveData,
            isSavedData: true,
          };

          if ( lead.account ) {
            updateLeadData.personalData = {
              firstName: lead.account.firstName,
              lastName: lead.account.lastName,
              email: lead.account.email,
              gender: lead.account.gender,
              street: lead.account.street,
              houseNumber: lead.account.houseNumber,
              zipCode: lead.account.zipCode,
              city: lead.account.city,
              birthDate: lead.account.birthDate,
              phone: lead.account.phone,
            };
          }

          this.saveLead( updateLeadData );

          return { errorCode: 0, data: lead };
        }

        return { errorCode: 1 };
      } else {
        const leadData: ILeadData = await this.getLead();

        const updateLeadData: ILeadData = {
          ...leadData,
          isSavedData: true,
        };

        if ( lead.account ) {
          updateLeadData.personalData = {
            ...lead.account,
          };
        }

        this.saveLead( updateLeadData );

        return { errorCode: 0, data: lead };
      }
    } catch( e ) {
      return {
        errorCode: 404,
        errorMessage: 'There has been an error on get lead data',
      };
    }
  }

  async getLeadByCodeCore( leadCode: string ): Promise<MutationResponse> {
    try {
      const response = await this.api.request<LeadDataItemResponse>( new GetLeadDataRequest( leadCode ) );

      const { lead } = response;

      if ( !lead ) {
        return { errorCode: 400 };
      }

      this.resetLead();
      const keyPersonalData = 'personalData';

      let accountData = {};

      if ( lead.account ) {
        const customFields = lead.account.hasOwnProperty( customFieldsKey ) ? lead.account[customFieldsKey] : {};

        delete lead.account[customFieldsKey];

        accountData = {
          ...lead.account,
          ...customFields,
        };

        await this.savedInfo( keyPersonalData, accountData );

        if ( lead.accountCode ) {
          await this.savedInfo( 'accountCode', lead.accountCode );
        }
      }

      if ( lead.customData && lead.customData.saveData ) {
        const leadData: ILeadData = await this.getLead();

        const today = dayjs();
        const savedAt = lead.customData.saveData.savedAt;
        const agoDays = today.diff( savedAt, 'days' );

        if ( agoDays < 14 ) {
          const updateLeadData: ILeadData = {
            ...leadData,
            ...lead.customData.saveData,
            isSavedData: true,
          };

          const personalData = lead.customData.saveData.hasOwnProperty( keyPersonalData )
            ? lead.customData.saveData[keyPersonalData] : null;

          if ( personalData ) {
            updateLeadData[keyPersonalData] = accountData ? { ...accountData, ...personalData } : personalData;
          }

          this.saveLead( updateLeadData );

          return { errorCode: 0, data: lead };
        }

        return { errorCode: 1 };
      } else {
        await this.savedInfo( 'isSavedData', true );

        return { errorCode: 0, data: lead };
      }
    } catch( e ) {
      return {
        errorCode: 404,
        errorMessage: 'There has been an error on get lead data',
      };
    }
  }

  async refreshTokenEIS( userName: string ): Promise<IFullAuth> {
    try {
      if ( this.userHolderDict[userName] !== undefined ) {
        const auth = this.userHolderDict[userName];
        if ( !this.accessTokenRefreshRequired( auth.token ) ) {
          // we can reuse token from dict without real refresh
          return Promise.resolve( auth );
        }
      }

      const response = await this.api.requestToken<TokenResponse>( new RefreshTokenEISRequest( userName ) );
      const parserToken = parseTokenResponse( response );

      this.updateAccessToken( parserToken.token );

      const accountData = this.updateAccountStorage( parserToken );

      this.currentUser = accountData.userInfo;
      this.userHolderDict[parserToken.token.userName] = accountData;

      return accountData;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async resetLead(): Promise<ILeadData> {
    await sleep( 300 );
    this.storage.set( this.keyStorage, initialLeadData );
    return initialLeadData;
  }

  async getAddressCompletions( partialAddress: string, country?: string ): Promise<AddressItem[]> {
    try {
      const response = await this.api.request( new GetAddressCompletionsRequest( partialAddress, country ) );

      const { items } = response;
      let addressItems: AddressItem[] = [];

      if ( items ) {
        addressItems = items.map( ( item ) => {
          const newItem: AddressItem = {
            ...item.addressDetails,
          };

          return newItem;
        } );
      }

      return addressItems;
    } catch( error ) {
      return Promise.reject( error );
    }
  }

  async validateAddress( addressItem: AddressItem ): Promise<ValidateAddress> {
    try {
      const response = await this.api.request( new ValidateAddressRequest( addressItem ) );

      return response;
    } catch( error ) {
      return Promise.reject( error );
    }
  }

  async getWorkflow( workflowSlug: string ): Promise<WorkflowItem | null> {
    try {
      let workflowItem = this.workflowItem;
      if ( workflowItem === null ) {
        const response = await this.api.request( new GetWorkflowBySlugRequest( workflowSlug ) );
        const { items } = response;

        if ( items && items.length > 0 ) {
          workflowItem = items[0] as unknown as WorkflowItem;

          this.workflowItem = workflowItem;
        }

        return workflowItem;
      }

      return workflowItem;
    } catch( error ) {
      return Promise.reject( error );
    }
  }

  async getUnderwritingResult( workflowCode: string, payloadData: PayloadRule ): Promise<VariableItem> {
    try {
      let underwritingResult = this.underwritingResult;
      if ( underwritingResult === null ) {
        const response = await this.api.request( new GetUnderwritingRules( workflowCode, payloadData ) );

        const { variables } = response;

        this.underwritingResult = variables;

        return variables;
      }

      return underwritingResult;
    } catch( error ) {
      return Promise.reject( error );
    }
  }

  async resetUnderwritingResult(): Promise<MutationResponse> {
    this.underwritingResult = null;

    return { errorCode: 0 };
  }

  async getProductByCode( productCode: string ): Promise<ProductItem | null> {
    try {
      let productItem: ProductItem | null = null;
      const response = await this.api.request( new GetProductByCodeRequest( productCode ) );
      const { items } = response;

      if ( items && items.length > 0 ) {
        productItem = items[0];
      }

      return productItem;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async getStaticDocumentsByProductCode( productCode: string ): Promise<IStaticDocumentItem[]> {
    const response = await this.api.request( new GetStaticDocumentsByProductCodeRequest( productCode ) );

    const { items } = response;

    return items;
  }

  async initiateEmailVerification( payload: InitiateEmailVerification ): Promise<EmailVerificationResponse> {
    try {
      const response = await this.api.request( new InitiateEmailVerificationRequest( payload ) );

      return response;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async completeEmailVerification( payload: CompleteEmailVerification ): Promise<EmailVerificationResponse> {
    try {
      const response = await this.api.request( new CompleteEmailVerificationRequest( payload ) );

      return response;
    } catch ( error ) {
      return Promise.reject( error );
    }
  }

  async getPreSignedRequestUrl( data: PreSignedPostRequest ): Promise<PreSignedPost> {
    const response = await this.api.request( new PresignedUrlRequest( data ) );

    return response;
  }

  async uploadDocumentUsingPreSignedUrl(
    { fields, url }: PreSignedPost, file: File, onUploadProgress?: ( progressEvent: any ) => void,
  ): Promise<Record<string, string>> {
    const formData = new FormData();

    Object.entries( fields ).forEach( ( [ k, v ] ) => {
      formData.append( k, v.toString() );
    } );

    formData.append( 'file', file, file.name );

    const response = await this.api.requestS3(
      new UploadDocumentWithPreSignedUrlRequest( formData, url, fields.contentType, onUploadProgress ),
    );

    return response;
  }

  async updateLead(
    leadData: ICreateLeadData, isClearLeadData?: boolean, brokerId?: string,
  ): Promise<LeadDataResponse> {
    try {
      let bearerToken = '';

      if ( brokerId ) {
        bearerToken = this.generateBearerToFunk( brokerId );
      }

      const lead = await this.api.request( new UpdateLeadRequest( leadData, bearerToken ) );

      if ( isClearLeadData ) {
        this.saveLead( initialLeadData );
      }

      return lead;
    } catch( e ) {
      throw new Error( 'There has been an error on saved data' );
    }
  }

  async filterNamedRange( request: FilterNamedRangeRequest ): Promise<FilterNamedRangeResponse> {
    try {
      const response = await this.api.request( new FilterNamedRange( request ) );

      return response;
    } catch( error ) {
      return Promise.reject( error );
    }
  }

  async getBookingFunnel( code: string ): Promise<IBFCustomStylesSettings> {
    let settings: IBFCustomStylesSettings = {
      logo: '',
      styles: '',
    };

    try {
      const response = await this.api.request( new GetBookingFunnelSettingsRequest( code ) );
      const { bookingFunnel } = response;

      settings = {
        logo: bookingFunnel.logoPath,
        styles: bookingFunnel.css,
      };

      if ( bookingFunnel.stepsConfig ) {
        settings.stepsConfig = bookingFunnel.stepsConfig;
      }

      return settings;
    } catch( e ) {
      return settings;
    }
  }

  private updateAccessToken( token: IToken ): void {
    this.api.accessToken.current = token;
  }

  private updateAccountStorage( auth: IBaseAuth ): IFullAuth {
    let users = this.storage.get<IAppUserMeta[]>( accountsStorageKey );
    if ( users === null ) {
      users = [];
    }
    const userInfo: IUserInfo = auth.userInfo;
    const userMeta: IAppUserMeta = {
      userName: userInfo.email,
      userFullName: userInfo.given_name + ' ' + userInfo.family_name,
      tenantId: userInfo.tenant_id,
      tenantName: userInfo.tenant_name,
      tenantSlug: userInfo.tenant_slug,
      sub: userInfo.sub,
    };

    let accountIndex = users.findIndex( ( item ) => item.userName === auth.userInfo.email );
    if ( accountIndex === -1 ) {
      // no user in local storage. Create one and put it to the store
      users.push( userMeta );
      accountIndex = users.length - 1;
    } else {
      // replace at index / update eser metadata
      users[accountIndex] = userMeta;
    }

    this.storage.set( accountsStorageKey, users );

    return {
      ...auth,
      accountIndex: accountIndex,
      userMeta: userMeta,
    };
  }

  private saveLead( leadData: ILeadData ) {
    this.storage.set( this.keyStorage, leadData );
  }

  private generateBearerToFunk( brokerId: string ): string {
    if ( this.currentUser && this.currentUser.tenant_hierarchy && brokerId ) {
      return `${this.currentUser.tenant_hierarchy}:${brokerId}`;
    }

    return '';
  }
}

export const WidgetServiceContext: React.Context<IWidgetService> = React.createContext( undefined as any );

export const useWidgetService = (): IWidgetService => {
  return React.useContext( WidgetServiceContext );
};
