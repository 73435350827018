import dayjs from 'dayjs';
import { HashUrlParam } from 'Services/widgets/interfaces';
import * as qs from 'query-string';

export interface OptionDateItem {
  value: number;
  label: string;
}

export interface INavItem {
  numberStep: string;
  hashStep: string;
  nameStep: string;
  dataKey?: string;
}

export const FIELD_TYPE_STRING = 1;
export const FIELD_TYPE_BOOL = 2;
export const FIELD_TYPE_NUMBER = 3;
export const FIELD_TYPE_DATETIME = 12;
export const FIELD_TYPE_DURUNIT = 13;

export type CFDefaultValueType = string | number | boolean | any | undefined;

export const phoneValidationRegExp: RegExp = /^\+[0-9]{1,3}[0-9]{10}$/;
export const oneTimePayment = 'oneTimePayment';
export const TRUE_VALUE = 'true';

export const isObject = ( obj: any ): obj is Object => {
  return obj !== null && typeof obj === 'object';
};

export const monthList = (): OptionDateItem[] => {
  const options: OptionDateItem[] = [];

  for ( let i = 0; i <= 11; i += 1 ) {
    const monthName = dayjs().month( i ).format( 'MMMM' );
    const monthItem: OptionDateItem = {
      value: i+1,
      label: monthName,
    };

    options.push( monthItem );
  }

  return options;
};

export const parseHashUrl = ( hash: string ): HashUrlParam[] => {
  return hash.split( '&' ).filter( ( v ) => v !== '' ).map( ( kv ) => {
    let tmp = kv.split( '=' );
    let param: HashUrlParam = {
      name: tmp[0],
      value: tmp[1],
    };

    return param;
  } );
};

export const mobMenuList = (): INavItem[] => {
  const items: INavItem[] = [
    {
      numberStep: 'bookingFunnel.steps.step1',
      hashStep: 'tarif',
      nameStep: 'bookingFunnel.steps.tariff',
    },
    {
      numberStep: 'bookingFunnel.steps.step2',
      hashStep: 'personal-data',
      nameStep: 'bookingFunnel.steps.personalData',
      dataKey: 'personalData',
    },
    {
      numberStep: 'bookingFunnel.steps.step3',
      hashStep: 'payment',
      nameStep: 'bookingFunnel.steps.payment',
      dataKey: 'paymentData',
    },
    {
      numberStep: 'bookingFunnel.steps.step4',
      hashStep: 'summary',
      nameStep: 'bookingFunnel.steps.summary',
      dataKey: 'paymentData',
    },
  ];

  return items;
};

export const mobMenuListGM = (): INavItem[] => {
  const items: INavItem[] = [
    {
      numberStep: 'bookingFunnel.steps.step1',
      hashStep: 'tarif',
      nameStep: 'bookingFunnel.steps.tariff',
      dataKey: 'subStep1TariffData',
    },
    {
      numberStep: 'bookingFunnel.steps.step2',
      hashStep: 'personal-data',
      nameStep: 'bookingFunnel.steps.personalData',
      dataKey: 'personalData',
    },
    {
      numberStep: 'bookingFunnel.steps.step3',
      hashStep: 'payment',
      nameStep: 'bookingFunnel.steps.payment',
      dataKey: 'paymentData',
    },
    {
      numberStep: 'bookingFunnel.steps.step4',
      hashStep: 'summary',
      nameStep: 'bookingFunnel.steps.summary',
      dataKey: 'paymentData',
    },
  ];

  return items;
};

export const getParamUrlByName = ( paramName: string ): string | undefined => {
  const parsedUrl = qs.parse( window.location.href, { decode: false } );
  const res = parsedUrl[paramName];

  if ( res ) {
    return res;
  }

  const parsedSearch = qs.parse( window.location.search );

  return parsedSearch[paramName];
};
