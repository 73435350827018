import React, { Fragment } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ICFFactorType, ICFFactorTypeOption } from '../../../factor-service';
import { CFDefaultValueType } from '../../../bf-hooks';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';

import employmentIcon0 from 'assets/bf-images/core/employment_icon_0.png';
import employmentIcon1 from 'assets/bf-images/core/employment_icon_1.png';
import employmentIcon2 from 'assets/bf-images/core/employment_icon_2.png';
import { upperFirst } from 'lodash';

const employmentIcons: string[] = [ employmentIcon0, employmentIcon1, employmentIcon2 ];

export type FieldType = 'radio' | 'select';

export interface EmploymentStatusProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
}

export const EmploymentStatus: React.FC<EmploymentStatusProps> = ( {
  valField,
  variable,
  factor,
  showTooltip,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();

  const factorValues = React.useMemo<ICFFactorTypeOption[]>( () => {
    if ( factor && factor.values ) {
      return [ ...factor.values ].sort( ( a, b ) => {
        const aKey = parseInt( a.key as string );
        const bKey = parseInt( b.key as string );
        return aKey - bKey;
      } );
    }
    return [];

  }, [ factor ] );

  const getDefaultValue = React.useCallback(
    ( items: ICFFactorTypeOption[], typeField: FieldType, keyItem?: JsonValue ): ICFFactorTypeOption | string => {
      if ( valField ) {
        return valField;
      }

      if ( items.length && keyItem ) {
        const findValue = items.find( ( i ) => i.key === keyItem )!;
        if ( typeField === 'radio' ) {
          return findValue ? String( findValue.key ) : '';
        }
        return findValue ? findValue : '';
      }

      return '';

    }, [ valField ] );

  const getIcon = React.useCallback( ( keyItem: string | number ): string => {
    if ( !keyItem ) {
      return employmentIcon0;
    }

    if ( employmentIcons[keyItem] ) {
      return employmentIcons[keyItem];
    }

    return employmentIcon0;

  }, [] );

  return (
    <div className="bf-category">
      { factor && (
        <Fragment>
          <Form.Label id={ `${variable.name}-label` }
            className={ `main-label-dynamic ${variable.bfTooltip && 'tooltip-label'}` }
          >
            { variable.bfLabel ?
              <div dangerouslySetInnerHTML={ { __html: `${variable.bfLabel}` } }></div>
              : variable.label }
            { variable.bfTooltip && (
              <a
                id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                href={ blankLink }
                onClick={ ( e ) => {
                  e.preventDefault();
                  showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                } }
              >
                { t( 'bookingFunnel.tooltipHelp' ) }
              </a>
            ) }
          </Form.Label>
          { variable.bfDescription && (
            <span className="gm-info f-12 mt-1">
              { variable.bfDescription }
            </span>
          ) }
          <Controller
            name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
            control={ control }
            rules={ { required: variable.isRequired } }
            defaultValue={ getDefaultValue( factorValues, 'radio', variable.defaultValue ) }
            render={ ( props ) => (
              <Fragment>
                <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name}` +
              `${factorValues.length === 3 ? ' btn-col-3' : ' btn-col-2'}`
                }
                >
                  { factorValues.map( ( f, idx ) => {
                    return (
                      <Fragment key={ idx }>
                        <FormControl
                          { ...props }
                          className="form-check-input"
                          type="radio"
                          id={ `option-${ props.name + '-' + f.key}` }
                          value={ String( f.key ) }
                          checked={ props.value === String( f.key ) }
                          onBlur={ props.onBlur }
                        />
                        <Form.Label
                          className="btn btn-border-radio dynamic-label-status mb-4"
                          htmlFor={ `option-${ props.name + '-' + f.key}` }
                        >
                          <div className="dynamic-label-content">
                            <img src={ getIcon( f.key ) } alt={ f.name } />
                            <span>{ upperFirst( f.name ) }</span>
                          </div>
                        </Form.Label>
                      </Fragment>
                    );
                  } ) }
                </FormGroup>
                <Form.Control
                  id={ `error-messages-${props.name}` }
                  type="hidden"
                  isInvalid={ errors[props.name] !== undefined }
                />
                <FormControl.Feedback type="invalid">
                  { t( 'base:forms.messages.fieldRequired', {
                    fieldLabel: variable.label,
                  } ) }
                </FormControl.Feedback>
              </Fragment>
            ) }
          />
        </Fragment>
      ) }
    </div>
  );
};
