import { createBrowserHistory, History } from 'history';
import { BaseConfig } from 'App/widget/types';
import { publicApiUrl, publicUrl } from 'App/utils';

export const blankLink: string = '';
export const history: History = createBrowserHistory();
export const defaultFactoryConfig: Omit<BaseConfig, 'tenantCode'> = {
  language: 'de',
  appLocation: publicUrl,
  apiLocation: publicApiUrl,
};
