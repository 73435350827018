import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressSettings, ContainerFieldItem, ITariffDataStep } from 'Services/widgets/interfaces';
import { isCheckedByField, renderPlaceholder } from '../../core-hooks';
import { CoreAddressSelectElement } from './CoreAddressSelectElement';
import { AddressFields } from 'Services/widgets/enums';
import { HiddenField } from '../steps/personal-sub-steps/form-fields/HiddenField';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';

export interface CoreAddressSingleLineProps {
  fieldValue: string;
  fieldName: string;
  personalDataFields: ITariffDataStep;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

const hiddenFields: string[] = [
  AddressFields.City,
  AddressFields.Street,
  AddressFields.HouseNumber,
  AddressFields.ZipCode,
];

export const CoreAddressSingleLine: React.FC<CoreAddressSingleLineProps> = ( {
  fieldValue,
  fieldName,
  personalDataFields,
  stepItem,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const formContext = useFormContext();
  const { errors, control } = formContext;

  const settings = stepItem?.settings as AddressSettings;
  const restrictedCountries: string[] = settings.restrictedCountries || [];
  const defaultCountry = restrictedCountries[0];
  const isAddressSingleLine = settings && settings.isSingleLine;

  const getDefaultValue = React.useCallback( ( ): string => {
    if ( fieldValue ) {
      return fieldValue;
    }

    if ( isAddressSingleLine && personalDataFields !== null ) {
      const { street, houseNumber, zipCode, city } = personalDataFields as ITariffDataStep;

      const newAddress = ( street ? street : '' )
      + ( houseNumber ? ` ${houseNumber}, ` : '' )
      + ( zipCode ? `${zipCode} `: '' )
      + ( city ? city : '' );

      return newAddress;
    }

    return '';
  }, [ fieldValue, isAddressSingleLine, personalDataFields ] );

  const isValidateAddress = ( value: string ): string | boolean => {
    const errFields = errors ? Object.keys( errors ) : null;

    if ( !errFields ) {
      return true;
    }

    const filteredErrFields = errFields.filter( ( fieldKey ) => hiddenFields.includes( fieldKey ) );
    if ( filteredErrFields.length ) {
      const errMessage = t( 'base:forms.messages.addressInvalid' );

      return errMessage;
    }

    return true;
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( `bookingFunnel.personalData.${fieldName}` );
  }, [ fieldName, stepItem, t ] );

  if ( !isAddressSingleLine ) {
    return <></>;
  }

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }
            return isValidateAddress( value );
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => {
          return (
            <FormGroup
              className={ `address-field mb-4${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
              controlId={ props.name }
            >
              <Form.Label className="bf-main-label">
                { fieldLabel }
                {
                  isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                  ( <AsteriskRequiredField /> )
                }
              </Form.Label>
              <CoreAddressSelectElement
                nameField={ props.name }
                selectValue={ { label: props.value, countryName: defaultCountry } }
                placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
                isDisabled={ isDisabled }
              />
              <Form.Control
                { ...props }
                type="hidden"
                isInvalid={ errors[props.name] !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
                  <Fragment>
                    { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
                  </Fragment>
                ) }
              </Form.Control.Feedback>
            </FormGroup>
          );
        } }
      />
      { hiddenFields.map( ( field, idx ) => {
        return (
          <Fragment key={ idx }>
            <HiddenField
              isRequired={ true }
              fieldName={ field }
              fieldValue={ personalDataFields && personalDataFields[field] }
            />
          </Fragment>
        );
      } ) }
    </Fragment>
  );
};
