import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AddressSettings,
  ContainerFieldItem,
  IBFInsuredObjectItem,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { isCheckedByField, renderPlaceholder } from '../../../core-hooks';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import { HiddenField } from '../../steps/personal-sub-steps/form-fields/HiddenField';
import { CoreAddressSelect } from './CoreAddressSelect';

export interface CoreAddressAutoCompleteProps {
  formData: ITariffDataStep;
  insuredObjects: IBFInsuredObjectItem[];
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const htmlTagsRegex = /(<([^>]+)>)/gi;

export const defaultPlaceholder = 'street houseNumber, zipCode city';

export const CoreAddressAutoComplete: React.FC<CoreAddressAutoCompleteProps> = ( {
  formData,
  insuredObjects,
  stepItem,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const insuredObjectName = stepItem && stepItem.insuredObjectName ? stepItem.insuredObjectName : '';
  const foundInsuredObject = insuredObjects.find( ( object ) => object.name === insuredObjectName );
  const settings = stepItem?.settings as AddressSettings;
  const restrictedCountries: string[] = settings.restrictedCountries || [];
  const defaultCountry = restrictedCountries[0] || 'Germany';
  const { fieldName, fieldItems, label, description } = stepItem || {};
  const hiddenFields = fieldItems?.map( ( f ) => f.fieldName ) || [];

  const { control, errors } = useFormContext();

  const getDefaultValue = React.useCallback( ( ): string => {
    if ( formData && fieldName && formData[fieldName] ) {
      return formData[fieldName];
    }

    if ( hiddenFields ) {
      let res = '';
      hiddenFields.forEach( ( field ) => {

        if ( formData && formData[`${field}_${insuredObjectName}`] && defaultPlaceholder.includes( field ) ) {
          const fieldValue = formData[`${field}_${insuredObjectName}`];
          res = res ? res : defaultPlaceholder;
          res = res.replaceAll( field, fieldValue );
        }
      } );

      return res;
    }

    return '';
  }, [ fieldName, formData, hiddenFields, insuredObjectName ] );

  const isValidateAddress = ( value: string ): string | boolean => {
    const errFields = errors ? Object.keys( errors ) : null;

    if ( !errFields ) {
      return true;
    }

    const filteredErrFields = errFields.filter( ( fieldKey ) => hiddenFields.includes( fieldKey ) );
    if ( filteredErrFields.length ) {
      const errMessage = t( 'base:forms.messages.addressInvalid' );

      return errMessage;
    }

    return true;
  };

  if ( !foundInsuredObject ) {
    return <></>;
  }

  return (
    <Fragment>
      <Controller
        name={ fieldName! }
        control={ control }
        rules={ {
          required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }
            return isValidateAddress( value );
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => {
          return (
            <FormGroup
              className={ `address-field ${errors[props.name] !== undefined ? ' dynamic-input-error' : ''}` }
              controlId={ props.name }
            >
              { label && (
                <Form.Label id={ `${props.name}-label` }>
                  <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${label}` } }></div>
                  { isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> ) }
                </Form.Label>
              ) }
              <CoreAddressSelect
                fieldItems={ fieldItems! }
                insuredObjectName={ insuredObjectName }
                nameField={ props.name }
                selectValue={ { label: props.value, countryName: defaultCountry } }
                placeholder={ renderPlaceholder( label!.replace( htmlTagsRegex, '' ), stepItem ) }
                isDisabled={ isDisabled }
                stepItem={ stepItem! }
              />
              <Form.Control
                { ...props }
                type="hidden"
                isInvalid={ errors[props.name] !== undefined }
              />
              { description && (
                <div
                  className="d-inline-block w-100 gm-info f-12 mt-1"
                  dangerouslySetInnerHTML={ { __html: description } }
                >
                </div>
              ) }
              <Form.Control.Feedback type="invalid">
                { errors[props.name]?.message ? ( errors[props.name]?.message ) : (
                  <Fragment>
                    { t( 'base:forms.messages.fieldRequired',
                      { fieldLabel: label && label.replace( htmlTagsRegex, '' ) },
                    ) }
                  </Fragment>
                ) }
              </Form.Control.Feedback>
            </FormGroup>
          );
        } }
      />
      { hiddenFields.map( ( field, idx ) => {
        return (
          <Fragment key={ idx }>
            <HiddenField
              isRequired={ true }
              fieldName={ `${field}_${insuredObjectName}` }
              fieldValue={ formData && formData[`${field}_${insuredObjectName}`] }
            />
          </Fragment>
        );
      } ) }
    </Fragment>
  );
};
