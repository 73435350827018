import React, { Fragment } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useAppAlertService, AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { useCoreActions } from '../../../DynamicCore';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import {
  ILeadData,
  ITariffDataStep,
  IRespondAuth,
  IRespondAuthResponse,
} from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { useTimer } from 'react-timer-hook';
import { LoginMFACoreState } from './LoginMFACoreStep';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { personalDataCoreKey } from './RegistrationCoreStep';
import { StepInfoItem } from '../../../interfaces';
import { AddressType, COREBFStep } from '../../../enums';
import { CoreCirclesNavigationMobile } from '../../core-ui-components/CoreCirclesNavigationMobile';
import { CoreSavedDataPopup } from '../../core-ui-components/CoreSavedDataPopup';
import { keyAccountCode, keyCode, keyCustomFields } from '../../../core-hooks';
import { CoreWelcomePopup } from '../../core-ui-components/CoreWelcomePopup';

export interface LoginSMSCoreStep {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  loginState: LoginMFACoreState;
  stepData: StepInfoItem;
  isParentStep: boolean;
  policyStepNames: string[];
}

export const LoginSMSCoreStep: React.FC<LoginSMSCoreStep> = ( propsStep ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert, hideAlert } = useAppAlertService();
  const actions = useCoreActions();
  const service = useWidgetService();
  const AuthInputRef = React.useRef<AuthCodeRef>( null );
  const { productCode, lead, productData, loginState, stepData, isParentStep, policyStepNames } = propsStep;
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ isShowWelcome, setShowWelcome ] = React.useState<boolean>( false );
  const [ authState, setAuthState ] = React.useState<LoginMFACoreState>( loginState );
  const [ isStopTimer, setStopTimer ] = React.useState<boolean>( false );
  const [ userInfo, setUserInfo ] = React.useState<string>( '' );

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds( expiryTimestamp.getSeconds() + 180 ); // 180 seconds timer

  const {
    seconds,
    minutes,
    restart,
  } = useTimer( { expiryTimestamp, onExpire: () => setStopTimer( true ) } );

  const formContext = useForm( {
    mode: 'onChange',
  } );

  const { errors, setValue, setError, formState } = formContext;

  const isValidCode = ( codeVal: string ): boolean => {
    if ( codeVal.length < 6 ) {
      return false;
    }

    return true;
  };

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    hideAlert();

    const smsCode = formData.smsCode;

    if( !isValidCode( smsCode ) ) {
      const errMessage = t( 'base:forms.messages.fieldRequired', { fieldLabel: 'Sms code' } );

      setError(
        'smsCode',
        {
          shouldFocus: true,
          message: errMessage,
        },
      );

      return;
    }

    const { responseAuthData } = authState;
    try {
      const loginRequest: IRespondAuth = {
        challengeName: responseAuthData.challengeName,
        challengeResponses: {
          USERNAME: responseAuthData.challengeParameters.USER_ID_FOR_SRP,
          SMS_MFA_CODE: smsCode,
        },
        tenantSlug: responseAuthData.tenantSlug,
        session: responseAuthData.session,
      };

      const responseLogin = await service.authorizationBySmsCode( loginRequest );

      if( responseLogin ) {
        const responseCustomer = await service.getCustomerInfo();
        const account = responseCustomer.account;
        const customFields = account.hasOwnProperty( keyCustomFields ) ? account[keyCustomFields] : {};

        delete account[keyCustomFields];

        const updatedAccount = {
          ...account,
          ...customFields,
          addressType: AddressType.AccountAddress,
        };

        if ( account.hasOwnProperty( keyAccountCode ) ) {
          await service.savedInfo( keyAccountCode, account[keyAccountCode] as string );
        }

        if ( account.hasOwnProperty( keyCode ) ) {
          await service.savedInfo( keyAccountCode, account[keyCode] as string );
        }

        await service.savedInfo( personalDataCoreKey, updatedAccount );

        setUserInfo( `${updatedAccount.firstName} ${updatedAccount.lastName}` );
        setShowWelcome( true );
      }
    } catch ( error ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ authState, hideAlert, service, setError, showAlert, t ] );

  const onCloseSavedPopup = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const handleOnChange = React.useCallback ( ( code: string ): void => {
    setValue( 'smsCode', code, { shouldValidate: true } );
  }, [ setValue ] );

  const goToPreviousStep = React.useCallback ( ( ): void => {
    if( isParentStep ) {
      actions.goToPersonalStep( stepData.nameStep, null, true );
    } else {
      actions.goToStep( stepData.previousStep!, false );
    }
  }, [ actions, isParentStep, stepData.nameStep, stepData.previousStep ] );

  const onCloseWelcomePopup = React.useCallback ( async (): Promise<void> => {
    const result = await service.saveLoginData( true );
    if ( result.errorCode === 0 ) {
      actions.goToStep( stepData.nextStep!, true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, stepData.nextStep, t ] );

  const resendSmsCode = React.useCallback ( async ( ): Promise<void> => {
    hideAlert();

    try {
      const { responseAuthData, loginData } = loginState;
      const responseAuth = await service.respondAuthorization( loginData );

      if ( responseAuth ) {
        const authData: IRespondAuthResponse = {
          ...responseAuthData,
          session: responseAuth.session,
        };

        setAuthState( {
          responseAuthData: authData,
          loginData,
        } );

        const resetTimestamp = new Date();
        resetTimestamp.setSeconds( resetTimestamp.getSeconds() + 60 ); // 60 seconds timer

        restart( resetTimestamp );
        AuthInputRef.current?.clear();
        AuthInputRef.current?.focus();

        setStopTimer( false );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } catch ( error ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ hideAlert, loginState, restart, service, showAlert, t ] );

  return (
    <FormProvider { ...formContext }>
      <div id="login-sms-data-step">
        <Row className="mt-3 mx-0">
          <Col md={ 12 } className="head-tab mb-2 text-center px-0 mx-0">
            <h1 className="f-26">Bitte geben Sie den Code ein, den wir Ihnen zugeschickt haben</h1>
          </Col>
          <Col className="mt-4" md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row>
                <Col id="alert-tariff-page">
                  <AppAlertUI />
                </Col>
                <Col sm={ 12 }>
                  <Controller
                    name="smsCode"
                    rules={ {
                      required: true,
                    } }
                    defaultValue={ '' }
                    render={ ( props ) => (
                      <FormGroup controlId={ props.name }>
                        <div className="core-timer">
                          <span>{ minutes }</span>:<span>{ seconds }</span>
                        </div>
                        <AuthCode
                          containerClassName="core-sms-code"
                          ref={ AuthInputRef }
                          autoFocus={ true }
                          onChange={ handleOnChange }
                          disabled={ isStopTimer }
                        />
                        <Form.Control
                          { ...props }
                          type="hidden"
                          placeholder={ 'Sms code' }
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <Form.Control.Feedback type="invalid" className="text-center">
                          { errors[props.name]?.message ? (
                            errors[props.name]?.message
                          ) : (
                            <Fragment>
                              { t( 'base:forms.messages.fieldRequired',
                                { fieldLabel: 'Sms code' } ) }
                            </Fragment>
                          ) }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
                <Col sm={ 12 } className="mb-4 d-flex justify-content-center">
                  <Button
                    id={ `${COREBFStep.SmsStep}_send_again` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 btn-link-current"
                    onClick={ () => resendSmsCode() }
                  >
                    Erneut senden
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center mt-0 mx-0">
                <CoreCirclesNavigationMobile lead={ lead } />
              </Row>
              <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4">
                <Button
                  id={ `${COREBFStep.SmsStep}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  className="col-sm-12 mr-0"
                >
                  { t( 'bookingFunnel.funkMesse.login.buttonLogin' ) }
                </Button>
                <Button
                  id={ `${COREBFStep.SmsStep}_back` }
                  type="button"
                  variant="link"
                  className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                  onClick={ goToPreviousStep }
                >
                  { t( 'bookingFunnel.previousBtn' ) }
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
        { isShowSavedStep && (
          <CoreSavedDataPopup
            currentStep={ COREBFStep.SmsStep }
            productCode={ productCode }
            lead={ lead }
            productData={ productData! }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
        { isShowWelcome && (
          <CoreWelcomePopup
            userName={ userInfo }
            onClose={ () => onCloseWelcomePopup( ) }
          />
        ) }
      </div>
    </FormProvider>
  );
};
