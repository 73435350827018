import React, { ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ILeadData } from 'Services/widgets/interfaces';
import { useLocation } from 'react-router-dom';
import { parseHashUrl } from '../../../bf-hooks';
import { useCoreActions } from '../../DynamicCore';
import { CoreNavItem } from '../../ui-components/MobileNavCore';
import { prepareMobilNav } from '../../core-hooks';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';
import { Circle } from 'rc-progress';

export interface NavDataProps {
  defaultActiveKey: string;
  lead: ILeadData;
  isRejected: boolean;
  isValidForm: boolean;
  showAllSteps?: boolean;
}

export const CoreNavigationMobile: React.FC<NavDataProps> = ( {
  defaultActiveKey, lead, isRejected, isValidForm, showAllSteps,
} ) => {
  const location = useLocation();
  const urlHash: string = location.hash.substring( 1 );
  const hashParams = parseHashUrl( urlHash );
  const actions = useCoreActions();
  const stepsInfo = actions.getStepsInfo();

  let itemList: CoreNavItem[] = [];

  if ( stepsInfo ) {
    const navList = prepareMobilNav( stepsInfo );
    itemList = navList.filter( ( i ) => i.componentType !== JSONComponentType.System )
      .map( ( item, idx ) => {
        return {
          ...item,
          numberStep: idx+1,
        };
      } );
  }

  const getCurrentStep = React.useCallback( (): CoreNavItem => {
    let currentHash = defaultActiveKey;

    if ( hashParams && hashParams[0] ) {
      currentHash = hashParams[0].value;
    }

    const currentItem = itemList.find( ( c ) => c.hashStep === currentHash )!;
    return currentItem;
  }, [ defaultActiveKey, hashParams, itemList ] );

  const currentStep = getCurrentStep();

  const onClickItem = React.useCallback( ( stepName: string, disabledLink: boolean ) => {
    if ( disabledLink || isRejected || !isValidForm ) {
      return;
    }

    actions.goToStep( stepName, true );
  }, [ actions, isRejected, isValidForm ] );

  const isActiveItem = React.useCallback( ( nameHash: string ) => {
    const params = hashParams.filter( ( param ) => param.value === nameHash );

    if ( params.length > 0 ) {
      return true;
    }

    return false;
  }, [ hashParams ] );

  const dropdownItems = React.useMemo<ReactElement[] | null>( () => {
    if ( !itemList ) {
      return null;
    }

    return itemList.map( ( item, idx ): ReactElement => {
      let isDisabled: boolean = false;
      if ( lead && !lead[item.hashStep!] ) {
        isDisabled = true;
      }

      const index = idx+1;
      if ( currentStep ) {
        if ( currentStep.numberStep < index && !showAllSteps ) {
          isDisabled = true;
        }
      }

      const isActive: boolean = isActiveItem( item.hashStep );

      return (
        <Dropdown.Item
          id={ `bf-menu-item-${item.numberStep}` }
          key={ item.numberStep }
          onClick={ ( e ) => { onClickItem( item.hashStep, isDisabled ); } }
          className="d-flex justify-content-start align-items-center"
          disabled={ !isActive && isDisabled }
          active={ isActive }
        >
          <span className="bf-item-icon">{ index }</span>
          <span className="bf-item-name">{ item.navigationName }</span>
        </Dropdown.Item>
      );
    } );
  }, [ currentStep, isActiveItem, itemList, lead, onClickItem, showAllSteps ] );

  if ( !currentStep ) {
    return <></>;
  }

  return (
    <Dropdown className="drp-nav-selector">
      <Dropdown.Toggle variant={ 'link' } id="mobile-nav">
        <div className="d-flex justify-content-between align-items-center">
          <div className="bf-current-item text-left">
            <div className="bf-circle-progress">
              <Circle
                percent={ ( 100 / itemList.length ) * currentStep.numberStep }
                strokeWidth={ 10 }
                trailWidth={ 10 }
                strokeLinecap={ 'square' }
              />
              <div className="bf-circle-progress-value">{ currentStep.numberStep }/{ itemList.length }</div>
            </div>
            <span className="bf-current-name">{ currentStep.navigationName }</span>
          </div>
          <div>
            <span className="bf-icon-menu">
              <i className="bg-dropdown-indicator"></i>
            </span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="pb-3" id="bf-mobile-menu">
        { dropdownItems }
      </Dropdown.Menu>
    </Dropdown>
  );
};
