import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import successIconUrl from 'assets/bf-images/success.svg';
import successIconUrlDoc from 'assets/bf-images/success-doc.svg';
import HashTabs from 'App/components/utils/HashTabs';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tab, Button } from 'react-bootstrap';
import { TariffStep1 } from './steps/TariffStep1';
import { PersonalDataStep2 } from './steps/PersonalDataStep2';
import { PaymentStep3 } from './steps/PaymentStep3';
import { SummaryStep4 } from './steps/SummaryStep4';
import { useHistory } from 'react-router-dom';
import {
  ILeadData,
  IBFProductVariable,
  IBFInsuredObjectItem,
  IInitiateLeadResponse,
  IStaticDocumentItem,
  IDocumentDownload,
  StepsConfig,
  IProductDocumentItem,
} from 'Services/widgets/interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import { ICFFactorType } from '../factor-service';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { emilGroupLogo } from '../../../utils';
import { setCustomStyles } from 'App/ui-utils';
import { NavMobileSelector } from './ui/NavMobileSelector';
import { generateVariables } from './booking-funnel-hooks';
import { BFStep } from './enum';
import { PolicyStep1 } from './steps/PolicyStep1';
import { Configuration } from 'App/widget/types';

export type IActiveStepType = 'subStep1' | 'subStep2';
export interface IBookingFunnelActions {
  reload( updatedProduct?: IDataFactorsAndVariables ): void;
  goToStep( step: string, isReloadLead: boolean ): Promise<void>;
  goToSubStep( activeStep: IActiveStepType, isReloadLead: boolean ): Promise<void>;
  onSubmit(): void;
  goToTopPage(): void;
  reloadLead(): Promise<void>;
}

export const BookingFunnelActionsContext: React.Context<IBookingFunnelActions> =
React.createContext( undefined as any );

export const useBookingFunnelActions = (): IBookingFunnelActions => {
  return React.useContext( BookingFunnelActionsContext );
};

export interface IDataFactorsAndVariables {
  factors: ICFFactorType[];
  insuredObjects: IBFInsuredObjectItem[];
  variables: IBFProductVariable[];
  productName: string;
  productSlug?: string;
  isCustomLayout: boolean;
  leadData: IInitiateLeadResponse;
  staticDocuments: IStaticDocumentItem[];
  productDocuments: IProductDocumentItem[];
  isSetupPayment: boolean;
  documentSettings?: IDocumentDownload[];
  stepsConfig?: StepsConfig;
  configuration?: Configuration;
}

export interface IBookingFunnelStepProps {
  lead: ILeadData;
  isCustomLayout?: boolean;
  productData: IDataFactorsAndVariables;
}

export interface BookingFunnelProps {
  productCode: string;
  productName: string;
  configuration?: Configuration;
}

const BookingFunnel: React.FC<BookingFunnelProps> = ( { productCode, productName, configuration } ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const history = useHistory();
  const service = useWidgetService();
  const { hideAlert } = useAppAlertService();
  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );
  const [ lead, setLead ] = React.useState<ILeadData | null>( null );
  const [ bfLogo, setBFLogo ] = React.useState<string>( '' );
  const [ bfLayout, setBFLayout ] = React.useState<string>( '' );
  const [ productData, setProductData ] = React.useState<IDataFactorsAndVariables | null>( null );
  const [ reloadTrigger, setReloadTrigger ] = React.useState<number>( dayjs().valueOf() );
  const [ actionStep, setActionStep ] = React.useState<IActiveStepType>( 'subStep1' );
  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );

  const lng = i18n.language;
  if( lng === 'de' ) {
    dayjs.locale( 'de' );
  } else {
    dayjs.locale( 'en-gb' );
  }

  React.useEffect( () => {
    let isMounted = true;

    const loadData = async (): Promise<IDataFactorsAndVariables | null> => {
      let result: IDataFactorsAndVariables | null = null;

      try {
        const productInfo = await service.getProductByCode( productCode );

        const [
          initiateLead,
          customCssData,
          factorsData,
          insuredObjectsData,
          documents,
          productDocuments,
          storeLead,
        ] = await Promise.all( [
          service.initiateLead(),
          service.getBFCustomStylesSettings( productCode ),
          service.getProductFactors( productCode, false ),
          service.getInsuredObjects( productCode ),
          service.getStaticDocuments(),
          service.getProductDocuments( productCode ),
          service.getLead(),
        ] );

        if ( isMounted ) {
          if ( customCssData ) {
            if ( customCssData.logo ) {
              setBFLogo( customCssData.logo );
            } else {
              setBFLogo( emilGroupLogo );
            }

            if ( customCssData.styles ) {
              setBFLayout( 'custom-layout-1' );
              setCustomStyles( 'custom-css', customCssData.styles );
            }
          }

          if ( factorsData.length && insuredObjectsData.length && productInfo ) {
            const variableList = generateVariables( insuredObjectsData );

            result = {
              factors: factorsData,
              insuredObjects: insuredObjectsData,
              variables: variableList.sort( ( a, b ) => a.legacyBfOrder! - b.legacyBfOrder! ),
              productName,
              productSlug: productInfo.slug,
              isCustomLayout: customCssData.styles ? true : false,
              leadData: initiateLead,
              isSetupPayment: false,
              staticDocuments: documents ? documents : [],
              productDocuments: productDocuments ? productDocuments : [],
              documentSettings: customCssData.documentSettings,
              stepsConfig: customCssData.stepsConfig,
              configuration: configuration,
            };

            setProductData( result );
            setErrorMessage( null );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }

          if ( storeLead ) {
            setLead( storeLead );
          }
        }
      } catch( e ) {
        isMounted = false;
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setErrorMessage(
              `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${e.message}`,
            );
          }
        } else {
          throw e;
        }
      }

      return result;
    };

    const loadProductFactorsWithAllValues = async ( oldProductData: IDataFactorsAndVariables | null ) => {
      if ( !oldProductData ) {
        return;
      }

      try {
        const factorsData = await service.getProductFactors( productCode, true );

        if ( isMounted ) {
          if ( factorsData.length ) {
            setProductData( {
              ...oldProductData,
              factors: factorsData,
            } );
            setErrorMessage( null );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }
        }
      } catch( e ) {
        isMounted = false;
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setErrorMessage(
              `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${e.message}`,
            );
          }
        } else {
          throw e;
        }
      }
    };

    loadData()
      .then(
        ( data: IDataFactorsAndVariables | null ) => { loadProductFactorsWithAllValues( data ); },
      );

    return () => {
      isMounted = false;
    };
  }, [ reloadTrigger, service, productCode, t, productName, configuration ] );

  const bookingFunnelActions = React.useMemo<IBookingFunnelActions>( () => {
    return {
      reload: ( updatedProduct ): void => {
        if ( updatedProduct !== undefined ) {
          setProductData( updatedProduct );
        } else {
          setReloadTrigger( dayjs().valueOf() );
        }
      },
      goToStep: async ( stepName: string, isReloadLead: boolean ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( `#step=${stepName}` );

        if ( stepName === 'personal-data' && actionStep !== 'subStep1' ) {
          setActionStep( 'subStep1' );
        }
        scrollToTopPage();
      },
      goToSubStep: async ( activeStep: IActiveStepType, isReloadLead: boolean ): Promise<void> => {
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();

          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( '#step=tarif' );

        setActionStep( activeStep );
        scrollToTopPage();
      },
      onSubmit: (): void => {
        setIsSuccess( true );
        setActionStep( 'subStep1' );
      },
      goToTopPage: (): void => {
        scrollToTopPage();
      },
      reloadLead: async ( ): Promise<void> => {
        const leadData: ILeadData = await service.getLead();
        if ( leadData ) {
          setLead( leadData );
        }
      },
    };
  }, [ actionStep, hideAlert, history, service ] );

  const homePageHandler = (): void => {
    setLead( null );
    setIsSuccess( false );
    history.push( '/' );
    setReloadTrigger( dayjs().valueOf() );
  };

  const scrollToTopPage = (): void => {
    const bookingFunnelPage = document.getElementById( 'booking-funnel-page' )!;
    if ( bookingFunnelPage ) {
      bookingFunnelPage.scrollIntoView( { block: 'start' } );
    }
  };

  if ( errorMessage !== null ) {
    return (
      <div className="m-5 d-flex justify-content-center">
        <p>{ errorMessage }</p>
      </div> );
  }

  if ( lead === null || !bfLogo ) {
    return <div className="my-5"><LoadingSpinner /></div>;
  }

  return (
    <BookingFunnelActionsContext.Provider value={ bookingFunnelActions }>
      <Row id="booking-funnel-page" className={ `m-0 ${bfLayout}` } hidden={ isSuccess }>
        <Col>
          <Row>
            { bfLogo && (
              <Col sm={ 12 } className="bf-page-header">
                <div className="logo d-flex">
                  <img src={ bfLogo } alt="Logo" />
                </div>
              </Col>
            ) }
            <Col sm={ 12 } className="bf-page-content">
              <NavMobileSelector
                defaultActiveKey="tarif"
                lead={ lead }
                productData={ productData! }
              />
              <HashTabs
                className="booking-funnel-wizard d-flex justify-content-center"
                variant="tabs"
                defaultActiveKey="tarif"
                id="step"
                onSelect={ () => hideAlert() }
              >
                <Tab tabClassName={ actionStep === 'subStep1' ? 'sub-step-1' : '' } eventKey="tarif" title={
                  <div>
                    <h6>{ t( 'bookingFunnel.steps.step1' ) }</h6>
                    <p className="m-0">{ t( 'bookingFunnel.steps.tariff' ) }</p>
                  </div>
                }
                >
                  { productData !== null && !productData.stepsConfig && (
                    <TariffStep1
                      productCode={ productCode }
                      subStep={ actionStep }
                      lead={ lead }
                      productData={ productData! }
                    />
                  ) }
                  { productData !== null && productData.stepsConfig && (
                    <PolicyStep1
                      productCode={ productCode }
                      subStep={ actionStep }
                      lead={ lead }
                      productData={ productData! }
                    />
                  ) }
                </Tab>
                <Tab eventKey="personal-data" disabled={ lead.personalData === null } title={
                  <div>
                    <h6>{ t( 'bookingFunnel.steps.step2' ) }</h6>
                    <p className="m-0">{ t( 'bookingFunnel.steps.personalData' ) }</p>
                  </div>
                }
                >
                  { productData !== null && (
                    <PersonalDataStep2
                      lead={ lead }
                      productData={ productData }
                    />
                  ) }
                </Tab>
                <Tab eventKey="payment" disabled={ lead.paymentData === null } title={
                  <div>
                    <h6>{ t( 'bookingFunnel.steps.step3' ) }</h6>
                    <p className="m-0">{ t( 'bookingFunnel.steps.payment' ) }</p>
                  </div>
                }
                >
                  { productData !== null && (
                    <PaymentStep3
                      lead={ lead }
                      isCustomLayout={ productData.isCustomLayout }
                      productData={ productData }
                    />
                  ) }
                </Tab>
                <Tab eventKey="summary" disabled={ lead.paymentData === null } title={
                  <div>
                    <h6>{ t( 'bookingFunnel.steps.step4' ) }</h6>
                    <p className="m-0">{ t( 'bookingFunnel.steps.summary' ) }</p>
                  </div>
                }
                >
                  { productData !== null && (
                    <SummaryStep4
                      productCode={ productCode }
                      lead={ lead }
                      productData={ productData }
                    />
                  ) }
                </Tab>
              </HashTabs>
            </Col>
          </Row>
        </Col>
      </Row>
      { isSuccess && (
        <div className={ `success-page ${bfLayout}` }>
          <Row className="max-w-450 m-box-mob">
            { bfLogo && (
              <Col sm={ 12 } className="bf-page-header">
                <div className="logo d-flex">
                  <img src={ bfLogo } alt="Logo" />
                </div>
              </Col>
            ) }
            <Col md={ 12 } className="core-success-img text-center px-0">
              <img src={ productData?.isCustomLayout ? successIconUrlDoc : successIconUrl } alt="success" />
            </Col>
            <Col md={ 12 } className="mt-4 px-0 text-center">
              <h1 className="f-26">{ t( 'bookingFunnel.successMessage' ) }</h1>
            </Col>
            <Col md={ 12 } className="mt-5 px-0 text-center">
              <Button
                id={ `${BFStep.PaymentApprovementStep}_to_home_page` }
                variant="primary"
                className="col-sm-12 mr-0"
                onClick={ homePageHandler }
              >
                { t( 'bookingFunnel.toHomeBtn' ) }
              </Button>
            </Col>
          </Row>
        </div>
      ) }
    </BookingFunnelActionsContext.Provider>
  );
};

export default BookingFunnel;
