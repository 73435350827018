import { JsonObject, JsonValue } from '@cover42/protobuf-util';
import React, { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { IBFProductVariable } from 'Services/widgets/interfaces';
import { FIELD_TYPE_DATETIME, isObject } from '../../../bf-hooks';
import { FieldDefaultValue } from 'App/widget/types';

export interface HiddenFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  isShortName?: boolean;
  fieldDefaultValue?: FieldDefaultValue;
}

export const HiddenField: React.FC<HiddenFieldProps> = ( { valueField, variable, isShortName, fieldDefaultValue } ) => {
  const { control } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    if ( fieldDefaultValue ) {
      return productFieldValue ? productFieldValue as string : '';
    }

    if ( valueField ) {
      return valueField;
    }

    if ( ( variable.typeId === FIELD_TYPE_DATETIME && stringValue !== null ) || isObject( stringValue ) ) {
      const objectDate = stringValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return stringValue !== null ? stringValue as string : '';
  };

  return (
    <Fragment>
      <Controller
        name={ isShortName ? variable.name :
          `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
        control={ control }
        rules={ { required: false } }
        defaultValue={ getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup controlId={ props.name }>
            <Form.Control
              { ...props }
              type="hidden"
            />
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
