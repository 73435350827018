import React, { Fragment } from 'react';
import closeIcon from 'assets/bf-images/funk/close_green_icon.svg';
import emailIcon from 'assets/bf-images/funk/email_icon.svg';
import markEmailIcon from 'assets/bf-images/funk/mark_email_icon.svg';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import dayjs from 'dayjs';
import GenderOptions from '../../genderOptions';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEmailDataValidationSchemaFunk } from '../validation';
import { Trans, useTranslation } from 'react-i18next';
import { blankLink } from 'config';
import {
  ITariffDataStep,
  ILeadData,
  ICreateLeadData,
  IScreenType,
  IPolicyObject,
  IAccountLeadData,
} from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import {
  convertDateToApi,
  generateVariables,
  keyMultiInsuredObjects,
  personalDataKey,
  prepareDataForTariff,
  preparePersonaData,
  preparePolicyPayload,
} from '../core-hooks';
import { useWidgetService } from 'Services/widget';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { apiDateTimeFormat } from 'App/ui-utils';
import { useAppLogger } from 'Services/logger';
import { AccountType, LeadStatus } from '@cover42/ts-contracts';
import { Title } from 'Services/widgets/enums';
import { getParamUrlByName } from '../../bf-hooks';

export interface DataPopupProps {
  currentStep: string;
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  onClose: () => void;
  goNextStep: () => void;
  policyStepNames: string[];
}

export interface IEmailData {
  email: string;
  confirmEmail: string;
  confirmationEmailData: boolean | undefined;
}

const initialData: IEmailData = {
  email: '',
  confirmEmail: '',
  confirmationEmailData: false,
};

export const SavedDataPopup: React.FC<DataPopupProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const logger = useAppLogger();
  const service = useWidgetService();
  const currentUrl = window.location.href;
  const emailDataValidationSchema = useEmailDataValidationSchemaFunk();

  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const { currentStep, productCode, productData, screenType, onClose, policyStepNames } = dataProps;

  const formContext = useForm<IEmailData>( {
    mode: 'onChange',
    resolver: yupResolver( emailDataValidationSchema ),
  } );

  const { formState, control, errors } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    try {
      const leadStoreData: ILeadData = await service.getLead();
      const tariffData = preparePolicyPayload( leadStoreData, policyStepNames! );

      let tariffDataForMultiInsured: IPolicyObject[] = [];
      const multiInsuredObjects = leadStoreData[keyMultiInsuredObjects];

      if ( multiInsuredObjects ) {
        const variables = generateVariables( multiInsuredObjects );

        tariffDataForMultiInsured = prepareDataForTariff(
          tariffData,
          variables,
          multiInsuredObjects,
          false,
        );
      }

      const preparedPolicyData = prepareDataForTariff(
        tariffData,
        productData.variables,
        productData.insuredObjects,
        false,
      );

      let newAccountData: IAccountLeadData = {
        gender: GenderOptions[0].value,
        title: Title.none,
        firstName: '',
        lastName: '',
        zipCode: '',
        city: '',
        street: '',
        houseNumber: '',
        phone: '',
        email: formData['email'],
        type: AccountType.Person,
      };

      const savedPersonalData = leadStoreData.personalData as unknown as IAccountLeadData;
      const customData = productData.stepsConfig && productData.stepsConfig.customData
        ? productData.stepsConfig.customData : null;
      const personaData = preparePersonaData( tariffData, savedPersonalData || newAccountData, customData );

      if ( personaData !== null ) {
        newAccountData = {
          ...newAccountData,
          title: personaData.title || Title.none,
          firstName: personaData.firstName || '',
          lastName: personaData.lastName || '',
          zipCode: personaData.zipCode as string || '',
          city: personaData.city || '',
          street: personaData.street || '',
          houseNumber: personaData.houseNumber || '',
          phone: personaData.phone || '',
          customFields: {
            country: personaData.country || '',
          },
        };
      }

      let linkToBF = currentUrl;
      const leadCode = getParamUrlByName( 'leadCode' );

      if ( !leadCode ) {
        linkToBF = `${linkToBF}&leadCode=${productData.leadData.leadCode}`;
      }

      const payloadLead: ICreateLeadData = {
        code: productData.leadData.leadCode,
        productCode: productCode,
        policyObjects: [ ...preparedPolicyData, ...tariffDataForMultiInsured ],
        account: newAccountData,
        customData: {
          saveData: {
            ...leadStoreData,
            ...tariffData,
            savedAt: convertDateToApi( dayjs().format( apiDateTimeFormat ) ),
            isShowWelcome: true,
          },
          linkToBF,
        },
        status: LeadStatus.Created,
      };

      const leadData = await service.ckeckLeadData( productData.leadData.leadCode );

      if ( leadData ) {
        payloadLead.productVersionId = leadData.policy.productVersionId;
      }

      const result = leadData ?
        await service.updateLeadFunk( payloadLead ) : await service.createLeadFunk( payloadLead );

      if ( result ) {
        await service.savedInfo( personalDataKey, newAccountData );

        setIsSuccess( true );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } catch( e ) {
      if ( e instanceof Error ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
        logger.error( e );
      } else {
        throw e;
      }
    }
  }, [ currentUrl, logger, policyStepNames, productCode, productData.insuredObjects, productData.leadData.leadCode,
    productData.stepsConfig, productData.variables, service, showAlert, t ] );

  return (
    <Fragment>
      <div className={ `modal-open dynamic-save-data ${ screenType.isMobile ? 'mobile-mode' : ''} ` }>
        <div className="fade modal-backdrop show"></div>
        <div className="fade gm-tooltip-modal modal show" style={ { display: 'block' } }>
          <Modal.Dialog centered>
            <Modal.Header>
              <a
                id={ `${currentStep}_close_modal` }
                className="dynamic-close-btn"
                href={ blankLink }
                onClick={ ( e ) => {
                  e.preventDefault();
                  onClose();
                } }
              >
                <img src={ closeIcon } alt="Close icon" />
              </a>
              <div className="dynamic-header-box">
                <img src={ emailIcon } alt="Email icon" />
                <h2>{ t( 'bookingFunnel.funkReanovo.emailPopup.title' ) }</h2>
              </div>
            </Modal.Header>
            <Modal.Body>
              { isSuccess && (
                <div className='max-w-744'>
                  <p className="text-center">{ t( 'bookingFunnel.funkReanovo.emailPopup.successInfoText1' ) }</p>
                  <p className="text-center">{ t( 'bookingFunnel.funkReanovo.emailPopup.successInfoText2' ) }</p>
                  <Row className="dynamic-btn-panel mt-4 mx-0 d-flex justify-content-center">
                    <Button
                      id={ `${currentStep}_send_data_confirm` }
                      type="button"
                      variant="primary"
                      className="mr-0 mb-0 bg-btn-primary"
                      onClick={ () => onClose() }
                    >
                      { t( 'bookingFunnel.funkReanovo.nextBtnEmail' ) }
                      <img
                        src={ rightArrowIconUrl }
                        className="ml-2"
                        alt={ t( 'bookingFunnel.funkReanovo.nextBtnEmail' ) }
                      />
                    </Button>
                  </Row>
                </div>
              ) }
              { !isSuccess && (
                <Fragment>
                  <div id="alert-save-page" className="max-w-744">
                    <AppAlertUI />
                  </div>
                  <div className='max-w-744'>
                    <p className="text-justify">{ t( 'bookingFunnel.funkReanovo.emailPopup.discription' ) }</p>
                    <p className="text-justify">
                      <Trans
                        i18nKey="bookingFunnel.funkReanovo.emailPopup.textInfo"
                        ns="widgets"
                        components={ {
                          linkToSite:
                          <a rel="noopener noreferrer"
                            className="font-weight-bold"
                            target="_blank"
                            href="https://www.funk-experts.de/datenschutz"
                          >
                            Link
                          </a>,
                        } }
                      />
                    </p>
                    <Form className="mt-3" noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
                      <Row>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="email"
                            control={ control }
                            defaultValue={ initialData.email }
                            render={ ( props ) => (
                              <FormGroup controlId={ props.name } className="dynamic-email-box">
                                <Form.Label>{ t( 'bookingFunnel.funkReanovo.emailPopup.emailField' ) }</Form.Label>
                                <Form.Control
                                  { ...props }
                                  type="email"
                                  className="bf-custom-input"
                                  placeholder={ t( 'bookingFunnel.funkReanovo.emailPopup.emailPlaceholder' ) }
                                  isInvalid={ errors[props.name] !== undefined }
                                />
                                <Form.Control.Feedback type="invalid">
                                  { errors[props.name]?.message }
                                </Form.Control.Feedback>
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="confirmEmail"
                            control={ control }
                            defaultValue={ initialData.confirmEmail }
                            render={ ( props ) => (
                              <FormGroup controlId={ props.name } className="dynamic-email-box">
                                <Form.Label>
                                  { t( 'bookingFunnel.funkReanovo.emailPopup.confirmEmailField' ) }
                                </Form.Label>
                                <Form.Control
                                  { ...props }
                                  type="email"
                                  className="bf-custom-input"
                                  placeholder={ t( 'bookingFunnel.funkReanovo.emailPopup.emailPlaceholder' ) }
                                  isInvalid={ errors[props.name] !== undefined }
                                />
                                <Form.Control.Feedback type="invalid">
                                  { errors[props.name]?.message }
                                </Form.Control.Feedback>
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="confirmationEmailData"
                            control={ control }
                            defaultValue={ initialData.confirmationEmailData }
                            render={ ( props ) => (
                              <FormGroup as={ Row } controlId={ props.name }>
                                <Col className="col-auto d-flex">
                                  <Form.Control
                                    { ...props }
                                    className="form-check-input m-0 wd-24"
                                    type="checkbox"
                                    isInvalid={ errors[props.name] !== undefined }
                                    onChange={
                                      ( e: React.ChangeEvent<HTMLInputElement> ) => props.onChange( e.target.checked )
                                    }
                                    checked={ props.value }
                                    onBlur={ props.onBlur }
                                  />
                                </Col>
                                <Form.Label className="checkbox-label">
                                  { t( 'bookingFunnel.funkReanovo.emailPopup.confirmationEmailData' ) }
                                </Form.Label>
                                { errors[props.name] !== undefined && (
                                  <div className="col-sm-12 mt-3 d-inline-flex">
                                    <span className="form-check-input-error">
                                      { t( 'bookingFunnel.summary.checkBoxErr' ) }
                                    </span>
                                  </div>
                                ) }
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <p>{ t( 'bookingFunnel.funkReanovo.required' ) }</p>
                        </Col>
                      </Row>
                      <Row className="mt-4 mx-0 d-flex justify-content-center">
                        <Button
                          id={ `${currentStep}_send_data` }
                          type="submit"
                          variant="primary"
                          disabled={ formState.isSubmitting }
                          className="mr-0 mb-0 bg-btn-primary"
                        >
                          { t( 'bookingFunnel.funkReanovo.emailPopup.btnSend' ) }
                          <img src={ markEmailIcon } className="ml-4"
                            alt={ t( 'bookingFunnel.funkReanovo.emailPopup.btnSend' ) }
                          />
                        </Button>
                      </Row>
                    </Form>
                  </div>
                </Fragment>
              ) }
            </Modal.Body>
          </Modal.Dialog>
        </div>
      </div>
    </Fragment>
  );
};
