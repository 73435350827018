import React from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import { GroupType, FormatOptionLabelMeta, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDefaultStyles } from 'Services/stylings';
import { ICFFactorTypeOption } from '../../../../factor-service';
import { SelectValuesOptionComponent } from 'App/components/widgets/base-ui-elements/select-values/options';
import { useFormContext } from 'react-hook-form';
import { DropdownIndicatorTagForBg, NullIndicator } from 'App/components/utils/react-select/indicators';
import { useCoreActions } from '../../../DynamicCore';
import { checkedUnderwriting } from '../../../../booking-funnel/booking-funnel-hooks';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { renderPlaceholder } from '../../../core-hooks';

const bgColorSelected = '#ECF5FA';

export const defaultCountItems: number = 20;

export interface ValuesProps {
  id?: string;
  nameField: string;
  selectValue: ICFFactorTypeOption;
  listValues: ICFFactorTypeOption[];
  isDisabled?: boolean;
  stepItem: ContainerFieldItem;
}

export const CoreSelectElement: React.FC<ValuesProps> = ( {
  id = 'select-values',
  nameField,
  selectValue,
  listValues,
  isDisabled,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const defaulStyles = useAppDefaultStyles();
  const { setValue } = useFormContext();
  const [ selectState, setSelectState ] = React.useState<ICFFactorTypeOption | string >( selectValue );

  const getItemsToFactor = React.useCallback( async ( inputValue ): Promise<ICFFactorTypeOption[]> => {
    let items: ICFFactorTypeOption[] = listValues;

    if ( inputValue.length > 0 ) {
      const search = inputValue.toLocaleLowerCase();

      items = items.filter( ( o ) => {
        return o.name.toLowerCase().includes ( search );
      } );
    }

    return Promise.resolve( items );
  }, [ listValues ] );

  const loadOptions = React.useCallback( async ( _search: string, loadedOptions: unknown[] ) => {
    const start = loadedOptions.length;
    const end = start + defaultCountItems;
    const options = await getItemsToFactor( _search );
    const slicedOptions = options.slice( start, end );

    return {
      options: slicedOptions,
      hasMore: slicedOptions.length > 0 && end <= listValues.length ? true : false,
    };
  }, [ getItemsToFactor, listValues.length ] );

  const getOptionValue = React.useCallback( ( option: ICFFactorTypeOption ) => {
    return option.name;
  }, [ ] );

  const getOptionLabel: (
    option: ICFFactorTypeOption,
    labelMeta: FormatOptionLabelMeta<ICFFactorTypeOption>
  ) => React.ReactNode = React.useCallback( ( option ) => {
    return <SelectValuesOptionComponent values={ option as ICFFactorTypeOption } />;
  }, [] );

  const getGroupLabel = React.useCallback( ( group: GroupType<ICFFactorTypeOption> ) => {
    return null;
  }, [ ] );

  const handleSelect = React.useCallback( async ( selectedValue: ValueType<ICFFactorTypeOption> ) => {
    const item: ICFFactorTypeOption = selectedValue as ICFFactorTypeOption;

    setSelectState( item );
    setValue( nameField, item, { shouldValidate: true } );

    const isCheckedUnderwriting = checkedUnderwriting( stepItem );
    actions.recalculationPremium( isCheckedUnderwriting );
  }, [ actions, nameField, setValue, stepItem ] );

  React.useEffect( () => {
    if ( selectValue && typeof selectValue === 'string' && listValues.length ) {
      const items: ICFFactorTypeOption[] = listValues;
      const foundValue = items.find( ( i ) => i.key === selectValue || i.name === selectValue )!;

      if ( foundValue ) {
        setSelectState( '' );
        handleSelect( foundValue );
      }
    }
  }, [ handleSelect, listValues, nameField, selectValue, setValue ] );

  const customStyles = React.useMemo( () => {
    return {
      option: ( styles, { isFocused, isSelected } ) => {
        return {
          ...styles,
          backgroundColor: isFocused | isSelected ? bgColorSelected : null,
          color: defaulStyles.textColor,
        };
      },
    };
  }, [ defaulStyles.textColor ] );

  const hasNoOptions = !listValues?.length;
  const placeholder = !hasNoOptions ? t( 'selectValues.selectValue' ) : t( 'selectValues.loading' );

  return (
    <AsyncPaginate
      debounceTimeout={ 0 }
      name={ nameField }
      id={ `${id}-search-input` }
      className={ `select-values-list ${ stepItem.className ? stepItem.className : '' }` }
      classNamePrefix="select-item"
      placeholder={ renderPlaceholder( placeholder, stepItem ) }
      defaultValue={ selectState }
      value={ selectState }
      components={ {
        DropdownIndicator: DropdownIndicatorTagForBg,
        IndicatorSeparator: NullIndicator,
      } }
      cacheOptions
      loadOptions={ loadOptions }
      blurInputOnSelect={ true }
      isMulti={ false }
      isClearable={ false }
      isSearchable={ true }
      escapeClearsValue
      menuPlacement="bottom"
      getOptionValue={ getOptionValue }
      formatOptionLabel={ getOptionLabel }
      formatGroupLabel={ getGroupLabel }
      onChange={ handleSelect }
      noOptionsMessage={ () => t( 'base:noOptions' ) }
      loadingMessage={ () => t( 'bookingFunnel.sLoadingRecords' ) }
      styles={ customStyles }
      maxMenuHeight={ 200 }
      isDisabled={ isDisabled || hasNoOptions }
    />
  );
};
