import React, { Fragment } from 'react';
import closeIcon from 'assets/bf-images/funk/close_green_icon.svg';
import { omit } from 'lodash';
import { Modal, Overlay, Tooltip } from 'react-bootstrap';
import { blankLink } from 'config';
import { ILeadData } from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { TooltipView } from 'Services/widgets/enums';

export interface TooltipCoreProps {
  tooltipInfo: string;
  onClose: () => void;
  targetLink?: React.ReactInstance;
}

const keyTooltipView = 'tooltipView';

export const TooltipCore: React.FC<TooltipCoreProps> = ( { tooltipInfo, onClose, targetLink } ) => {
  const service = useWidgetService();
  const showTooltip: boolean = true;
  const refOverlay = React.useRef( null );
  const [ tooltipView, setTooltipView ] = React.useState<TooltipView | undefined>( undefined );

  React.useEffect( () => {
    let isMounted = true;

    const checkTooltipView = async () => {
      if ( isMounted ) {
        const leadData: ILeadData = await service.getLead( 0 );

        if ( leadData && leadData[keyTooltipView] ) {
          setTooltipView( leadData[keyTooltipView] as TooltipView );
        }
      }
    };

    checkTooltipView();

    return () => {
      isMounted = false;
    };
  }, [ service ] );

  if ( !tooltipView ) {
    return ( <></> );
  }

  return (
    <Fragment>
      { tooltipView === TooltipView.Overlay && (
        <div ref={ refOverlay }>
          <Overlay
            container={ refOverlay as unknown as React.ReactInstance }
            rootClose={ true }
            rootCloseEvent="click"
            target={ targetLink }
            show={ showTooltip }
            placement="auto"
            transition={ true }
            onHide={ onClose }
          >
            { ( props ) => (
              <Tooltip id="tooltip-view" { ...omit( props, [ 'show' ] ) }>
                <div
                  className="bf-default-color"
                  dangerouslySetInnerHTML={ { __html: `${tooltipInfo}` } }
                >
                </div>
              </Tooltip>
            ) }
          </Overlay>
        </div>
      ) }
      { tooltipView === TooltipView.Popup && (
        <div className="modal-open">
          <div className="fade modal-backdrop show"></div>
          <div className="fade dynamic-tooltip-modal modal show" style={ { display: 'block' } }>
            <Modal.Dialog size="lg" centered>
              <Modal.Body>
                <a
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    onClose();
                  } }
                >
                  <img src={ closeIcon } className="float-right" alt="Tooltip Help" />
                </a>
                <div
                  className="my-4 mx-3 bf-default-color"
                  dangerouslySetInnerHTML={ { __html: `${tooltipInfo}` } }
                >
                </div>
              </Modal.Body>
            </Modal.Dialog>
          </div>
        </div>
      ) }
    </Fragment>
  );
};
