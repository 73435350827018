import React, { Fragment } from 'react';
import arrowUpIconUrl from 'assets/bf-images/funk/arrow_up.svg';
import arrowDnIconUrl from 'assets/bf-images/funk/arrow_dn.svg';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, IBFInsuredObjectItem, ITariffDataStep } from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { renderFullAddress } from '../../core-hooks';

interface AddressProps {
  stepItem: ContainerFieldItem;
  tariffData: ITariffDataStep;
  multiInsuredObjects: IBFInsuredObjectItem[];
}

export const AddressList: React.FC<AddressProps> = ( { stepItem, tariffData, multiInsuredObjects } ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const [ isShowAllList, setShowAllLis ] = React.useState<boolean>( false );
  const configObject = stepItem && stepItem.config ? stepItem.config : '';

  const onShowList = React.useCallback ( ( isShow: boolean ): void => {
    setShowAllLis( isShow );
  }, [] );

  const firstObject = multiInsuredObjects[0];
  const allObjects = multiInsuredObjects.filter( ( obj ) => obj.name !== firstObject.name );

  const getValueField = React.useCallback( ( nameGroup: string ) => {
    const fullAdress = renderFullAddress( tariffData, nameGroup );

    return !isEmpty( fullAdress ) ? fullAdress : '-';

  }, [ tariffData ] );

  return (
    <Fragment>
      <div className="details-section d-flex justify-content-space-between">
        <p className="bf-selected-val">{ configObject['addressLabel'] }</p>
        <p>{ getValueField( firstObject.name ) }</p>
      </div>
      { isShowAllList && allObjects && allObjects.map( ( item, idx ) => (
        <div key={ idx } className="details-section d-flex justify-content-end">
          <p className="bf-selected-val">{ getValueField( item.name ) }</p>
        </div>
      ),
      ) }
      { allObjects.length >= 1 && (
        <div className="details-section d-flex justify-content-center">
          <a
            href={ blankLink }
            onClick={ ( e ) => {
              e.preventDefault();
              onShowList( !isShowAllList );
            } }
          >
            { isShowAllList ? (
              <Fragment>
                { t( 'bookingFunnel.funkReanovo.btnLess' ) }
                <img className="pl-2" src={ arrowUpIconUrl } alt="Icon Up" />
              </Fragment>
            ) : (
              <Fragment>
                { `${ t( 'bookingFunnel.funkReanovo.btnMore' )} +${ allObjects.length }` }
                <img className="pl-2" src={ arrowDnIconUrl } alt="Icon Down" />
              </Fragment>
            ) }
          </a>
        </div>
      ) }
    </Fragment>
  );
};
