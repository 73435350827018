import React, { Fragment } from 'react';
import { JsonValue } from '@cover42/protobuf-util';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PremiumFormulaUnits } from 'Services/widgets/enums';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { blankLink } from 'config';
import { checkedUnderwriting, isRequiredField } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { useCoreActions } from '../../../DynamicCore';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { renderFieldLabel } from '../../../core-hooks';

export interface DateUnitFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  isShortName?: boolean;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export interface OptionTimeItem {
  value: PremiumFormulaUnits;
  label: string;
}

export const DateUnitField: React.FC<DateUnitFieldProps> = ( {
  valueField, variable, isShortName, showTooltip, stepItem, isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue } = useFormContext();
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const timeOptions = React.useMemo<OptionTimeItem[]>( () => {
    const options = [
      {
        value: PremiumFormulaUnits.Month,
        label: t( `bookingFunnel.gmTimeOptions.${PremiumFormulaUnits.Month}` ),
      },
      {
        value: PremiumFormulaUnits.Year,
        label: t( `bookingFunnel.gmTimeOptions.${PremiumFormulaUnits.Year}` ),
      },
    ];

    return options;
  }, [ t ] );

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    return stringValue !== null ? stringValue as string : '';
  };

  const isUITypeRadio = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.Radio;

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      { isUITypeRadio ? (
        <Fragment>
          <Form.Label id={ `${variable.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
            <div
              className="d-inline-block"
              dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
            />
            { variable.bfTooltip && (
              <a
                id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                href={ blankLink }
                role='button'
                onClick={ ( e ) => {
                  e.preventDefault();
                  showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                } }
              >
                { t( 'bookingFunnel.tooltipHelp' ) }
              </a>
            ) }
          </Form.Label>
          { variable.bfDescription && (
            <span className="gm-info f-12 mt-1">
              { variable.bfDescription }
            </span>
          ) }
          <Controller
            name={ fieldName }
            control={ control }
            rules={ { required: isRequiredField( variable, stepItem ) } }
            defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
            render={ ( props ) => (
              <FormGroup>
                <div className={ `list-button custom-btn-radio mb-2 rdb-${props.name}` }>
                  { timeOptions.map( ( f, idx ) => {
                    return (
                      <Fragment key={ idx }>
                        <div className="box-radio">
                          <FormControl
                            { ...props }
                            className="form-check-input"
                            type="radio"
                            id={ `option_${ props.name + '_' + idx}` }
                            value={ String( f.value ) }
                            checked={ props.value === String( f.value ) }
                            onChange={ ( e ) => {
                              props.onChange( e );
                              const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                              actions.recalculationPremium( isCheckedUnderwriting );
                            } }
                            onBlur={ props.onBlur }
                          />
                          <Form.Label
                            className="btn btn-border-radio font-weight-500"
                            htmlFor={ `option_${ props.name + '_' + idx}` }
                          >
                            <span className="text-left">{ f.label }</span>
                          </Form.Label>
                        </div>
                      </Fragment>
                    );
                  } ) }
                </div>
                <Form.Control
                  id={ `error-messages-${props.name}` }
                  type="hidden"
                  isInvalid={ errors[props.name] !== undefined }
                />
                <FormControl.Feedback type="invalid">
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
                </FormControl.Feedback>
              </FormGroup>
            ) }
          />
        </Fragment>
      ) : (
        <Controller
          name={ isShortName ? variable.name :
            `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
          control={ control }
          rules={ { required: isRequiredField( variable, stepItem ) } }
          defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
          render={ ( props ) => (
            <FormGroup controlId={ props.name } className="gm-select-box">
              <Form.Label id={ `${props.name}-label` }>
                <div
                  className="d-inline-block"
                  dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                />
                { variable.bfTooltip && (
                  <a
                    id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                    href={ blankLink }
                    role='button'
                    onClick={ ( e ) => {
                      e.preventDefault();
                      showTooltip( true, variable.bfTooltip! );
                    } }
                  >
                    { t( 'bookingFunnel.tooltipHelp' ) }
                  </a>
                ) }
              </Form.Label>
              { variable.bfDescription && (
                <span className="gm-info f-12 mt-1">
                  { variable.bfDescription }
                </span>
              ) }
              <Form.Control
                { ...props }
                as="select"
                className="form-select"
                placeholder={ variable.label }
                onChange={ ( e: React.ChangeEvent<HTMLSelectElement> ) => {
                  props.onChange( e );
                  const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                  actions.recalculationPremium( isCheckedUnderwriting );
                } }
                isInvalid={ errors[props.name] !== undefined }
              >
                { timeOptions.map( ( el ) =>
                  <option
                    key={ el.value }
                    value={ el.value }
                  >
                    { el.label }
                  </option> ) }
              </Form.Control>
              <FormControl.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
              </FormControl.Feedback>
            </FormGroup>
          ) }
        />
      ) }
    </Fragment>
  );
};
