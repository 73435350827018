import React, { Fragment } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ICFFactorType, ICFFactorTypeOption } from '../../factor-service';
import { CFDefaultValueType, isObject } from '../../bf-hooks';
import { useTranslation } from 'react-i18next';
import { SelectElement } from './fields/SelectElement';
import { IBFProductVariable } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { JSONItemUIType } from '../../booking-funnel/enum';
import { checkedUnderwriting, isRequiredField } from '../../booking-funnel/booking-funnel-hooks';
import { MultiSelectElement } from './fields/MultiSelectElement';
import { useCoreActions } from '../DynamicCore';
import { isEmpty } from 'lodash';
import { renderFieldLabel } from '../core-hooks';

export type FieldType = 'radio' | 'select';

export interface FactorElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  showTooltip: ( isShow: boolean, tooltip: string ) => void;
  stepItem?: ContainerFieldItem;
}

export const FactorElement: React.FC<FactorElementProps> = ( {
  valField,
  variable,
  factor,
  showTooltip,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control } = useFormContext();

  const isUITypeRadio = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.Radio;
  const isUITypeMultiSelect = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.MultiSelect;
  const uiTypeNoExists = stepItem && !stepItem.uiType;
  const uiTypeNoExistsOrIsRadio = !stepItem || uiTypeNoExists || isUITypeRadio;

  const factorValues = React.useMemo<ICFFactorTypeOption[]>( () => {
    if ( factor && factor.values ) {
      return [ ...factor.values ].sort( ( a, b ) => {
        const aKey = parseInt( a.key as string );
        const bKey = parseInt( b.key as string );
        return isUITypeRadio ? bKey - aKey : aKey - bKey;
      } );
    }
    return [];

  }, [ factor, isUITypeRadio ] );

  const getDefaultValue = React.useCallback(
    ( items: ICFFactorTypeOption[], typeField: FieldType, keyItem?: JsonValue ):
    ICFFactorTypeOption[] | ICFFactorTypeOption | string => {
      if ( valField ) {
        if ( items.length && typeof valField === 'string' && isUITypeMultiSelect ) {
          const values = valField ? JSON.parse( valField ) : [];
          const factorList = items.filter( ( f ) => values.includes( String( f.key ) ) );

          return factorList ? factorList : '';
        }

        if ( isObject( valField ) && items.length && valField.hasOwnProperty( 'key' ) ) {
          return valField.key && typeField === 'radio' ? valField.key : valField;
        }

        if ( typeof valField === 'string' && items.length && typeField === 'select' ) {
          const findValue = items.find( ( i ) => i.key === valField )!;
          return findValue ? findValue : '';
        }

        return valField;
      }

      if ( items.length && keyItem ) {
        const findValue = items.find( ( i ) => i.key === keyItem )!;
        if ( typeField === 'radio' ) {
          return findValue ? String( findValue.key ) : '';
        }

        return findValue ? findValue : '';
      }

      return '';

    }, [ isUITypeMultiSelect, valField ] );

  const getMultiDefaultValue = React.useCallback( ( multiValue: ICFFactorTypeOption[] | string ): string => {
    if ( multiValue && typeof multiValue === 'string' ) {
      return multiValue;
    }

    if ( multiValue && multiValue.length ) {
      const values: string[] = [];
      const valueList = multiValue as ICFFactorTypeOption[];

      valueList.forEach( ( item ) => {
        values.push( item.key as string );
      } );

      return values.length ? JSON.stringify( values ) : '';
    }

    return '';

  }, [] );

  const onRecalculation = React.useCallback( ( ): void => {
    if ( isEmpty( errors ) ) {
      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting );
    }
  }, [ actions, errors, stepItem ] );

  return (
    <Fragment>
      { factor && (
        <Fragment>
          { uiTypeNoExistsOrIsRadio && factorValues.length > 0 && factorValues.length <= 6 ?
            <Fragment>
              { factorValues.length <= 2 ? (
                <Fragment>
                  <Form.Label id={ `${variable.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                    <div
                      className="d-inline-block"
                      dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                    />
                    { variable.bfTooltip && (
                      <a
                        id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                        href={ blankLink }
                        role='button'
                        onClick={ ( e ) => {
                          e.preventDefault();
                          showTooltip( true, variable.bfTooltip! );
                        } }
                      >
                        { t( 'bookingFunnel.tooltipHelp' ) }
                      </a>
                    ) }
                  </Form.Label>
                  { variable.bfDescription && (
                    <span className="gm-info f-12 mt-1">
                      { variable.bfDescription }
                    </span>
                  ) }
                  <Controller
                    name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                    control={ control }
                    rules={ { required: isRequiredField( variable, stepItem ) } }
                    defaultValue={ getDefaultValue( factorValues, 'radio', variable.defaultValue ) }
                    render={ ( props ) => (
                      <FormGroup>
                        <div className={ `custom-btn-radio mb-2 rdb-${props.name}` }>
                          { factorValues.map( ( f, idx ) => {
                            return (
                              <Fragment key={ idx }>
                                <div className="box-radio">
                                  <Form.Label
                                    className="btn btn-border-radio font-weight-500"
                                    htmlFor={ `option_${ props.name + '_' + f.key}` }
                                  >
                                    { f.name }
                                  </Form.Label>
                                  <FormControl
                                    { ...props }
                                    className="form-check-input"
                                    type="radio"
                                    id={ `option_${ props.name + '_' + f.key}` }
                                    value={ String( f.key ) }
                                    checked={ props.value === String( f.key ) }
                                    onChange={ ( e ) => {
                                      props.onChange( e );

                                      onRecalculation();
                                    } }
                                    onBlur={ props.onBlur }
                                  />
                                </div>
                              </Fragment>
                            );
                          } ) }
                        </div>
                        <Form.Control
                          id={ `error-messages-${props.name}` }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <FormControl.Feedback type="invalid">
                          { t( 'base:forms.messages.fieldRequired', {
                            fieldLabel: variable.label,
                          } ) }
                        </FormControl.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Form.Label id={ `${variable.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                    <div
                      className="d-inline-block"
                      dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                    />
                    { variable.bfTooltip && (
                      <a
                        id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                        href={ blankLink }
                        role='button'
                        onClick={ ( e ) => {
                          e.preventDefault();
                          showTooltip( true, variable.bfTooltip! );
                        } }
                      >
                        { t( 'bookingFunnel.tooltipHelp' ) }
                      </a>
                    ) }
                  </Form.Label>
                  { variable.bfDescription && (
                    <span className="gm-info f-12 mt-1">
                      { variable.bfDescription }
                    </span>
                  ) }
                  <Controller
                    name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                    control={ control }
                    rules={ { required: isRequiredField( variable, stepItem ) } }
                    defaultValue={ getDefaultValue( factorValues, 'radio', variable.defaultValue ) }
                    render={ ( props ) => (
                      <FormGroup>
                        <div className={ `list-button custom-btn-radio mb-2 rdb-${props.name}` }>
                          { factorValues.map( ( f, idx ) => {
                            return (
                              <Fragment key={ idx }>
                                <div className="box-radio">
                                  <FormControl
                                    { ...props }
                                    className="form-check-input"
                                    type="radio"
                                    id={ `option_${ props.name + '_' + f.key}` }
                                    value={ String( f.key ) }
                                    checked={ props.value === String( f.key ) }
                                    onChange={ ( e ) => {
                                      props.onChange( e );

                                      onRecalculation();
                                    } }
                                    onBlur={ props.onBlur }
                                  />
                                  <Form.Label
                                    className="btn btn-border-radio font-weight-500"
                                    htmlFor={ `option_${ props.name + '_' + f.key}` }
                                  >
                                    <span className="text-left">{ f.name }</span>
                                  </Form.Label>
                                </div>
                              </Fragment>
                            );
                          } ) }
                        </div>
                        <Form.Control
                          id={ `error-messages-${props.name}` }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <FormControl.Feedback type="invalid">
                          { t( 'base:forms.messages.fieldRequired', {
                            fieldLabel: variable.label,
                          } ) }
                        </FormControl.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Fragment>
              ) }
            </Fragment>
            : (
              <Controller
                name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                control={ control }
                rules={ { required: isRequiredField( variable, stepItem ) } }
                defaultValue={ getDefaultValue( factorValues, 'select', variable.defaultValue ) }
                render={ ( props ) => (
                  <FormGroup controlId={ props.name }
                    className={ `${ stepItem?.classNameBox } ${errors[props.name] !== undefined
                      ? ' dynamic-input-error' : ''}` }
                  >
                    <label id={ `${props.name}-label` }
                      className={ `form-label${variable.bfTooltip ? ' tooltip-label' : ''}` }
                    >
                      <div
                        className="d-inline-block"
                        dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                      />
                      { variable.bfTooltip && (
                        <a
                          id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                          href={ blankLink }
                          role='button'
                          onClick={ ( e ) => {
                            e.preventDefault();
                            showTooltip( true, variable.bfTooltip! );
                          } }
                        >
                          { t( 'bookingFunnel.tooltipHelp' ) }
                        </a>
                      ) }
                    </label>
                    { variable.bfDescription && (
                      <span className="gm-info f-12 mt-1">
                        { variable.bfDescription }
                      </span>
                    ) }
                    { isUITypeMultiSelect ? (
                      <Fragment>
                        <MultiSelectElement
                          nameField={ props.name }
                          selectValue={ props.value }
                          listValues={ factorValues }
                          stepItem={ stepItem! }
                        />
                        <Form.Control
                          { ...props }
                          value={ getMultiDefaultValue( props.value ) }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <SelectElement
                          nameField={ props.name }
                          selectValue={ props.value }
                          listValues={ factorValues }
                          stepItem={ stepItem! }
                        />
                        <Form.Control
                          { ...props }
                          value={ props.value ? props.value.key : '' }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                      </Fragment>
                    ) }
                    <FormControl.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: variable.label,
                      } ) }
                    </FormControl.Feedback>
                  </FormGroup>
                ) }
              />
            )
          }
        </Fragment>
      ) }
    </Fragment>
  );
};
