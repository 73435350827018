import jsonpath from 'jsonpath';
import dayjs, { Dayjs } from 'dayjs';
import { CriteriaItem } from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { isObject } from '../bf-hooks';
import { JsonObject, JsonValue } from '@cover42/protobuf-util';
import { formatDatePolicy } from '../dynamic-core/core-hooks';

export enum CriteriaType {
  Const = 'const',
  Var = 'var',
  Variable = 'variable',
  DateType = 'date',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Function = 'function',
  Factor = 'factor',
}

export enum BooleanType {
  True = 'true',
  False = 'false',
}

type EmilFunction = ( objData: any, ...args: any[] ) => boolean | number | JsonObject;

const isValueDate = ( value: string ): boolean => {
  const regexDate = new RegExp( '[0-9]{4}[-][0-9]{2}[-][0-9]{2}' );

  if ( value && typeof value === 'string' && regexDate.test( value ) ) {
    const resVal = Date.parse( value );

    return !isNaN( resVal );
  }

  return false;
};

const convertValueToDayjs = ( dateVal: string ): Dayjs => {
  const formatDate = dayjs( dateVal ).format( formatDatePolicy );

  return dayjs( formatDate );
};

const convertValueToNumber = ( value: JsonValue ): number => {
  if ( value && typeof value === 'string' ) {
    const resVal = value.split( '.' ).join( '' ).replace( /,/g, '.' );

    return Number( resVal );
  }

  if ( value && typeof value === 'number' ) {
    return value;
  }

  return 0;
};

const getFieldValue = ( objData: any, arg: CriteriaItem, isConvertValue?: boolean ): JsonValue => {
  let fieldValue = arg.value!;

  if ( [ CriteriaType.Var, CriteriaType.Variable ].includes( arg.type ) ) {
    const key = arg.value!;

    fieldValue = jsonpath.query( objData, `$.${key}` )[0];
  }

  if ( arg.type === CriteriaType.Function ) {
    fieldValue = evalFunction( objData, arg );
  }

  if ( isObject( fieldValue ) && fieldValue.hasOwnProperty( 'key' ) ) {
    fieldValue = fieldValue['key'];
  }

  if ( isConvertValue ) {
    fieldValue = convertValueToNumber( fieldValue );
  }

  return fieldValue;
};

const AND = ( objData: any, ...args: boolean[] ): boolean | number | JsonObject => {
  if ( !args || args.length === 0 ) {
    return false;
  }

  let res: boolean | number | JsonObject = true;
  let v: boolean | number | JsonObject = true;

  for ( let i = 0; i < args.length; i++ ) {
    let element = args[i] as unknown as CriteriaItem;

    if ( [ CriteriaType.Const, CriteriaType.Boolean ].includes( element.type ) ) {
      v = element.value as boolean;
    } else if ( element.type === CriteriaType.Function ) {
      v = evalFunction( objData, element );
    }

    res = res && v;
  }

  return res;
};

const OR = ( objData: any, ...args: boolean[] ): boolean | number | JsonObject => {
  if ( !args || args.length === 0 ) {
    return false;
  }

  let res: boolean | number | JsonObject = true;
  let v: boolean | number | JsonObject = true;

  for ( let i = 0; i < args.length; i++ ) {
    let element = args[i] as unknown as CriteriaItem;

    if ( [ CriteriaType.Const, CriteriaType.Boolean ].includes( element.type ) ) {
      v = element.value as boolean;
    } else if ( element.type === CriteriaType.Function ) {
      v = evalFunction( objData, element );
    }

    if ( v ) {
      return v;
    }

    res = res && v;
  }

  return res;
};

const EQUALS = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {
    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return fieldDate1.isSame( fieldDate2 );
  }

  const isNumber = arg2.type === CriteriaType.Number;
  const isTypeBoolean = arg2.type === CriteriaType.Boolean;
  let expectedValue = getFieldValue( obj, arg1, isNumber );

  if ( isTypeBoolean && [ BooleanType.True, BooleanType.False ].includes( expectedValue as unknown as BooleanType )
    && typeof expectedValue === 'string' ) {
    expectedValue = expectedValue === BooleanType.True;
  }

  const currentValue = getFieldValue( obj, arg2 );

  if ( typeof expectedValue !== 'boolean' && !expectedValue && expectedValue !== 0
  && typeof currentValue !== 'boolean' && !currentValue && currentValue !== 0 ) {
    return false;
  }

  return expectedValue === currentValue;
};
const NOT_EQUALS = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {
    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return !fieldDate1.isSame( fieldDate2 );
  }

  const isNumber = arg2.type === CriteriaType.Number;
  const isTypeBoolean = arg2.type === CriteriaType.Boolean;
  let expectedValue = getFieldValue( obj, arg1, isNumber );

  if ( isTypeBoolean && [ BooleanType.True, BooleanType.False ].includes( expectedValue as unknown as BooleanType )
    && typeof expectedValue === 'string' ) {
    expectedValue = expectedValue === BooleanType.True;
  }

  const currentValue = getFieldValue( obj, arg2 );

  if ( ( typeof expectedValue !== 'boolean' && !expectedValue && expectedValue !== 0 )
  || ( typeof currentValue !== 'boolean' && !currentValue && expectedValue !== 0 ) ) {
    return false;
  }

  return expectedValue !== currentValue;
};

const IN = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length === 0 ) {
    return false;
  }

  const obj = objData;
  const key = ( args[0] as CriteriaItem ).value!;
  const [ , ...inValues ] = args;
  const expectedValues = inValues.map( ( val: CriteriaItem ) => ( val.value ) );

  let currentValue = jsonpath.query( obj, `$.${key}` )[0];

  if ( isObject( currentValue ) && currentValue.hasOwnProperty( 'key' ) ) {
    currentValue = currentValue['key'];
  }

  return expectedValues.indexOf( currentValue ) !== -1;
};

const EMPTY = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg = args[0] as CriteriaItem;

  const currentValue = getFieldValue( obj, arg );

  return isEmpty( currentValue );
};

const SUM = ( objData: any, ...args: any[] ): number => {
  if ( !args || args.length < 2 ) {
    return 0;
  }

  const obj = objData;
  let sumValues = 0;
  const countArgs = args.length - 1;

  for ( let i = 0; i <= countArgs; i += 1 ) {
    const arg = args[i] as CriteriaItem;
    const fieldValue = getFieldValue( obj, arg, true ) as number;

    sumValues = sumValues + fieldValue;
  }

  return sumValues;
};

const LESS = ( objData: any, ...args: any[] ): boolean => {
  if ( !args ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {
    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return fieldDate1.isBefore( fieldDate2 );
  }

  const fieldValue1 = getFieldValue( obj, arg1, true ) as number;
  const fieldValue2 = getFieldValue( obj, arg2, true ) as number;

  return fieldValue1 < fieldValue2;
};

const LESS_OR_EQUALS = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {

    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return fieldDate1.isBefore( fieldDate2 ) || fieldDate1.isSame( fieldDate2 );
  }

  const fieldValue1 = getFieldValue( obj, arg1, true ) as number;
  const fieldValue2 = getFieldValue( obj, arg2, true ) as number;

  return fieldValue1 <= fieldValue2;
};

const GREATER = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {
    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return fieldDate1.isAfter( fieldDate2 );
  }

  const fieldValue1 = getFieldValue( obj, arg1, true ) as number;
  const fieldValue2 = getFieldValue( obj, arg2, true ) as number;

  return fieldValue1 > fieldValue2;
};

const GREATER_OR_EQUALS = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 2 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1 );
  const arg2Value = getFieldValue( obj, arg2 );
  const isValuesDate = isValueDate( arg1Value as string ) || isValueDate( arg2Value as string );
  const isDateType = arg1.type === CriteriaType.DateType || arg2.type === CriteriaType.DateType;

  if ( isDateType || isValuesDate ) {
    const fieldDate1 = convertValueToDayjs( arg1Value as string );
    const fieldDate2 = convertValueToDayjs( arg2Value as string );

    return fieldDate1.isAfter( fieldDate2 ) || fieldDate1.isSame( fieldDate2 );
  }

  const fieldValue1 = getFieldValue( obj, arg1, true ) as number;
  const fieldValue2 = getFieldValue( obj, arg2, true ) as number;

  return fieldValue1 >= fieldValue2;
};

const MATCH_FIELD_VALUE = ( objData: any, ...args: any[] ): boolean => {
  if ( !args || args.length < 3 ) {
    return false;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const field = args[1] as CriteriaItem;
  const arg3 = args[2] as CriteriaItem;

  const countObject = getFieldValue( obj, arg1, true ) as number;

  for ( let i = 1; i <= countObject; i += 1 ) {
    const fullPathToField = `${field.value}${i}`;
    const newArg: CriteriaItem = {
      ...field,
      value: fullPathToField,
    };

    const expectedValue = getFieldValue( obj, newArg );
    const currentValue = getFieldValue( obj, arg3 );

    if ( expectedValue === currentValue ) {
      return true;
    }
  }

  return false;
};

const AGE = ( objData: any, ...args: any[] ): number => {
  if ( !args || args.length < 1 ) {
    return 0;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;

  const fieldValue1 = getFieldValue( obj, arg1, false );

  if ( fieldValue1 ) {
    const currentDateField = dayjs( fieldValue1 as Date );
    const today = dayjs();
    const differenceInYears = today.diff( currentDateField, 'year', true );

    return differenceInYears;
  }

  return 0;
};

const COUNT_MULTI_OBJECTS = ( objData: any, ...args: any[] ): number => {
  if ( !args || args.length < 2 ) {
    return 0;
  }

  let countObj = 0;
  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  if ( [ CriteriaType.Var, CriteriaType.Variable ].includes( arg1.type ) ) {
    const currentValue = getFieldValue( obj, arg2 );
    const key = arg1.value!;
    const splitPath = key.split( '.' );

    if ( splitPath && splitPath.length > 1 ) {
      const stepName = splitPath[0];
      const fieldName = splitPath[1];

      const storeData = jsonpath.query( objData, `$.${stepName}` )[0];
      const allKeys = storeData ? Object.keys( storeData ).filter( ( f ) => f.startsWith( fieldName ) ) : undefined;

      if ( !allKeys ) {
        return 0;
      }

      allKeys.forEach( ( item ) => {
        let itemValue = storeData[item];

        if ( isObject( itemValue ) && itemValue.hasOwnProperty( 'key' ) ) {
          itemValue = itemValue['key'];
        }

        if ( itemValue === currentValue ) {
          countObj = countObj + 1;
        }
      } );
    }
  }

  return countObj;
};

const SUM_OF_PERCENT = ( objData: any, ...args: any[] ): number => {
  if ( !args || args.length < 2 ) {
    return 0;
  }

  const obj = objData;
  const arg1 = args[0] as CriteriaItem;
  const arg2 = args[1] as CriteriaItem;

  const arg1Value = getFieldValue( obj, arg1, true ) as number;
  const arg2Value = getFieldValue( obj, arg2, true ) as number;

  return ( arg1Value * arg2Value ) / 100;
};

const emilFunctions: Map<string, EmilFunction> = new Map( [
  [ 'AND', AND ],
  [ 'OR', OR ],
  [ 'EQUALS', EQUALS ],
  [ 'NOT_EQUALS', NOT_EQUALS ],
  [ 'IN', IN ],
  [ 'EMPTY', EMPTY ],
  [ 'SUM', SUM ],
  [ 'LESS', LESS ],
  [ 'LESS_OR_EQUALS', LESS_OR_EQUALS ],
  [ 'GREATER', GREATER ],
  [ 'GREATER_OR_EQUALS', GREATER_OR_EQUALS ],
  [ 'MATCH_FIELD_VALUE', MATCH_FIELD_VALUE ],
  [ 'AGE', AGE ],
  [ 'COUNT_MULTI_OBJECTS', COUNT_MULTI_OBJECTS ],
  [ 'SUM_OF_PERCENT', SUM_OF_PERCENT ],
] );

export const evalFunction = ( objData: any, criteria?: CriteriaItem ): boolean | number | JsonObject => {
  if ( !criteria ) {
    return true;
  }

  let func = emilFunctions.get( criteria.name! );

  if ( func ) {
    const result = func( objData, ...criteria.args );
    if ( typeof result === 'boolean' ) {

      if ( result && criteria.ruleConfig! ) {
        return criteria.ruleConfig;
      }

      return result as boolean;
    }

    if ( typeof result === 'number' ) {
      return result as number;
    }
  }

  return false;
};
