import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiDateTimeFormat } from 'App/ui-utils';
import { formatDatePolicy, isCheckedByField } from '../../../../core-hooks';
import { DatePickerComponent } from '../../../../ui-elements/fields/DatePickerComponent';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';

export interface CoreBirthDateFieldProps {
  nameField: string;
  labelField: string;
  valueField: string;
  endDate: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const CoreBirthDateField: React.FC<CoreBirthDateFieldProps> = ( {
  nameField,
  labelField,
  valueField,
  endDate,
  stepItem,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const dayPickerRef = React.useRef<HTMLDivElement>( null );
  const popperRef = React.useRef<HTMLDivElement>( null );
  const fromDate = dayjs().subtract( 120, 'years' ).format();
  const [ isOpenDatePicker, setOpenDatePicker ] = React.useState<boolean>( false );

  const { errors, control, setValue } = useFormContext();

  const fullDate = valueField && valueField !== 'null' ? dayjs( valueField, formatDatePolicy ) : null;

  const [ dayDate, setDayDate ] = React.useState<string>( fullDate ? fullDate.format( 'DD' ) : '' );
  const [ monthDate, setMonthDate ] = React.useState<string>( fullDate ? fullDate.format( 'MM' ) : '' );
  const [ yearDate, setYearDate ] = React.useState<string>( fullDate ? fullDate.format( 'YYYY' ) : '' );
  const [ selected, setSelected ] = React.useState<Date>(
    fullDate ? new Date( fullDate.format( formatDatePolicy ) ) : new Date( endDate ),
  );

  const closePopper = React.useCallback ( ( ): void => {
    setOpenDatePicker( false );
    dayPickerRef?.current?.focus();
  }, [] );

  const openDatePicker = React.useCallback ( ( ): void => {
    if ( isDisabled ) {
      return;
    }

    setOpenDatePicker( !isOpenDatePicker );
  }, [ isDisabled, isOpenDatePicker ] );

  const handleChangeBirthDate = React.useCallback ( ( date ): void =>{
    const valueDate: dayjs.Dayjs = dayjs( date );

    setDayDate( valueDate.format( 'DD' ) );
    setMonthDate( valueDate.format( 'MM' ) );
    setYearDate( valueDate.format( 'YYYY' ) );

    const savedDate = valueDate.format( apiDateTimeFormat );

    setValue( nameField, savedDate, { shouldValidate: true } );
    setSelected( new Date( savedDate ) );

    openDatePicker();
  }, [ nameField, openDatePicker, setValue ] );

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return labelField;
  }, [ labelField, stepItem ] );


  const defaultDateValue = React.useCallback( () => {
    if ( valueField ) {
      return valueField;
    }

    if ( !valueField && isDisabled ) {
      return endDate!;
    }

    return '';
  }, [ endDate, isDisabled, valueField ] );

  return (
    <Fragment>
      <Controller
        name={ nameField }
        control={ control }
        rules={ { required: isCheckedByField( 'isRequired', stepItem! ) } }
        defaultValue={ defaultDateValue }
        render={ ( props ) => (
          <FormGroup className={
            `${stepItem?.classNameBox || '' } dynamic-birthdate-box date-picker-field${errors[props.name]
              ? ' dynamic-date-error' : ''}` }
          controlId={ props.name }
          >
            <Form.Label className="bf-main-label">
              <div className="d-inline-block" dangerouslySetInnerHTML={ { __html: `${fieldLabel}` } }></div>
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                  ( <AsteriskRequiredField /> )
              }
            </Form.Label>
            <Row
              className={
                `date-container select-container${!props.value ? ' form-select-empty' : '' }`
              }
            >
              <div
                className={ `col-md-12 input-group dynamic-input-group${ isDisabled ? ' box-disabled' : ''}` }
                ref={ popperRef }
                onClick={ openDatePicker }
              >
                <div className="dynamic-icon-select">
                  <span className="input-group-text"><i className="feather icon-search" /></span>
                </div>
                <Form.Control
                  name="day"
                  min="1"
                  max="31"
                  className="dynamic-day"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'TT' }
                  value={ dayDate }
                />
                <Form.Control
                  name="month"
                  min="1"
                  max="12"
                  className="dynamic-month"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'MM' }
                  value={ monthDate }
                />
                <Form.Control
                  name="year"
                  className="dynamic-year"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'jjjj' }
                  value={ yearDate }
                />
              </div>
              { isOpenDatePicker && (
                <DatePickerComponent
                  popperRef={ popperRef }
                  dayPickerRef={ dayPickerRef }
                  currentValue={ selected }
                  isOpenDatePicker={ isOpenDatePicker }
                  fromDate={ fromDate }
                  toDate={ endDate }
                  closePopper={ closePopper }
                  onChangeDate={ handleChangeBirthDate }
                />
              ) }
            </Row>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <span className="dynamic-birthdate-info mt-2">
              { t( 'bookingFunnel.personalDataFunk.birthDateInfo' ) }
            </span>
            <Form.Control.Feedback type="invalid" className="dynamic-error-message">
              <Fragment>
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: labelField } ) }
              </Fragment>
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
