import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import arrowUpIconUrl from 'assets/bf-images/core/arrow_up.svg';
import arrowDnIconUrl from 'assets/bf-images/core/arrow_dn.svg';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  IBFInsuredObjectItem,
  IBFProductVariable,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DATETIME, isObject } from 'App/components/widgets/bf-hooks';
import { defaultDateFormat } from 'App/ui-utils';
import { ICFFactorType } from 'App/components/widgets/factor-service';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';

interface ObjectProps {
  stepItem: ContainerFieldItem;
  factors: ICFFactorType[];
  tariffData: ITariffDataStep;
  multiInsuredObjects: IBFInsuredObjectItem[];
  insuredObjectName: string;
  nameStep: string;
}

export const CoreObjectList: React.FC<ObjectProps> = (
  { stepItem, factors, tariffData, multiInsuredObjects, insuredObjectName, nameStep },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const [ isShowAllList, setShowAllLis ] = React.useState<boolean>( false );

  const onShowList = React.useCallback ( ( isShow: boolean ): void => {
    setShowAllLis( isShow );
  }, [] );

  const savedMultiInsuredObjects = multiInsuredObjects?.filter(
    ( o ) => o.name.startsWith( `${insuredObjectName}_${nameStep}` )
  );
  const firstObject = savedMultiInsuredObjects[0];
  const allObjects = savedMultiInsuredObjects.filter( ( obj ) => obj.name !== firstObject.name );

  const getValueFactorField = React.useCallback( ( variable: IBFProductVariable ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, tariffData ] );

  const getValueBaseField = React.useCallback( ( variable: IBFProductVariable ) => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return dayjs( selectedVal ).format( defaultDateFormat );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';
    return !isEmpty( resVal ) ? resVal : '-';
  }, [ t, tariffData ] );

  return (
    <Fragment>
      { firstObject && (
        <Fragment>
          { firstObject['label'] && (
            <span className="mt-3 d-inline-block f-16 text-current-color">
              { `${firstObject['label']} (1)` }
            </span>
          ) }
          { stepItem && stepItem.items && stepItem.items.map( ( field, fieldIdx ) => {
            const foundProductField = firstObject.productFields?.find(
              ( f ) => `${f.name}_${f.groupName}` === `${field.fieldName}_${f.groupName}` && !f.isHidden,
            );

            if ( !foundProductField ) {
              return <Fragment key={ fieldIdx }></Fragment>;
            }

            return (
              <Fragment key={ fieldIdx }>
                <div className="details-section mx-4">
                  <p className="bf-selected-val f-14">{ foundProductField.label }</p>
                  { foundProductField.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                    <p>{ getValueBaseField( foundProductField ) }</p>
                  ) }
                  { foundProductField.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                    <p>{ getValueFactorField( foundProductField ) }</p>
                  ) }
                </div>
              </Fragment>
            );
          } ) }
        </Fragment>
      ) }
      { isShowAllList && allObjects && allObjects.map( ( item, idx ) => (
        <Fragment key={ idx }>
          { item['label'] && (
            <span className="mt-3 d-inline-block f-16 text-current-color">
              { `${item['label']} (${idx + 2})` }
            </span>
          ) }
          { stepItem && stepItem.items && stepItem.items.map( ( field, fieldIdx ) => {
            const foundProductField = item.productFields?.find(
              ( f ) => `${f.name}_${f.groupName}` === `${field.fieldName}_${f.groupName}` && !f.isHidden,
            );

            if ( !foundProductField ) {
              return <Fragment key={ fieldIdx }></Fragment>;
            }

            return (
              <Fragment key={ fieldIdx }>
                <div className="details-section mx-4">
                  <p className="bf-selected-val f-14">{ foundProductField.label }</p>
                  { foundProductField.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                    <p>{ getValueBaseField( foundProductField ) }</p>
                  ) }
                  { foundProductField.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                    <p>{ getValueFactorField( foundProductField ) }</p>
                  ) }
                </div>
              </Fragment>
            );
          } ) }
        </Fragment>
      ),
      ) }
      { allObjects.length >= 1 && (
        <div className="details-section d-flex justify-content-center">
          <a
            href={ blankLink }
            onClick={ ( e ) => {
              e.preventDefault();
              onShowList( !isShowAllList );
            } }
          >
            { isShowAllList ? (
              <Fragment>
                { t( 'bookingFunnel.funkReanovo.btnLess' ) }
                <img className="pl-2" src={ arrowUpIconUrl } alt="Icon Up" />
              </Fragment>
            ) : (
              <Fragment>
                { `${ t( 'bookingFunnel.funkReanovo.btnMore' )} +${ allObjects.length }` }
                <img className="pl-2" src={ arrowDnIconUrl } alt="Icon Down" />
              </Fragment>
            ) }
          </a>
        </div>
      ) }
    </Fragment>
  );
};
