export enum PaymentType {
  Stripe = 'stripe',
  Braintree = 'braintree'
}

export enum PaymentMethodName {
  Sepa = 'sepa',
  Card = 'card',
  Invoice = 'invoice'
}

export enum DeviceTypes {
  Device = 'device',
  MobileDevice = 'mobileDevice'
}

export enum DeviceGroups {
  DeviceType = 'DeviceType',
  DeviceTypeMobile = 'DeviceTypeMobile',
  DeviceTypeOther = 'DeviceTypeOther'
}

export enum FieldDeviceTypes {
  DeviceType = 'deviceType',
  MobileDeviceType = 'mobileDeviceType'
}

export enum ActionDeviceTypes {
  Yes = 'yes',
  No = 'no'
}

export enum PremiumFormulaUnits {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  HalfYear = 'halfyear',
  Quarter = 'quarter',
  Hour = 'hour',
  Minute = 'minute',
}

export enum FactorPremiumFormulaUnits {
  Monthly = '5_monthly',
  Quarterly = '6_quarterly',
  HalfYearly = '7_half_yearly',
  Yearly = '8_yearly',
}

export enum DateTypes {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

export enum FamilyTypes {
  Family1 = 'addOnFamilyOne',
  Family2 = 'addOnFamilyTwo',
  Family3 = 'addOnFamilyThree'
}

export enum PdfDocumentType {
  Sepa = 'sepa',
  Policy = 'policy',
  Invoice = 'invoice',
  Terms = 'terms',
  Static = 'static',
  IPID = 'ipid',
  Mediation = 'mediation',
  Protocol = 'protocol',
  Rules = 'rules',
  WithdrawalRules = 'withdrawal-rules',
  Link = 'link',
  Docx = 'docx',
}

export enum TariffFieldsWS {
  BirthDate = 'birthDate',
  Weight = 'weight',
  Height = 'height',
  JobStatus = 'jobStatus',
  JobActivities = 'jobActivities',
  HasRiskyOccupation = 'hasRiskyOccupation',
  JobClass = 'jobClass',
}

export enum TariffStarterFieldsWS {
  PolicyStartDate = 'policyStartDate',
  Tariff = 'tariff',
  Coverage = 'coverage',
  PremiumDynamic = 'premiumDynamic',
  RetrainingComponent = 'retrainingComponent',
}

export enum TariffPerformerFieldsWS {
  PolicyStartDate = 'policyStartDate',
  Tariff = 'tariff',
  EndAge = 'endAge',
  Coverage = 'coverage',
  Salary = 'salary',
  PremiumDynamic = 'premiumDynamic',
  RetrainingComponent = 'retrainingComponent',
  JobStatus = 'jobStatus',
}

export enum TariffJobStatusWS {
  Schuler = '0',
  Student = '1',
  Auszubildender = '2',
  Abgeschlossene = '3',
  Akademiker = '4',
  Berufsausbildung = '5',
}

export enum TariffNamesWS {
  Starter = 'starter',
  Performer = 'performer',
}

export enum QuestionFieldsWS {
  ConsumedDrugs = 'question_consumedDrugs',
  DoesDangerousSports = 'question_doesDangerousSports',
  PlansDangerousTrip = 'question_plansDangerousTrip',
  PsedPrescriptionMedication = 'question_usedPrescriptionMedication',
  HasDisability = 'question_hasDisability',
  WasOnSickLeave = 'question_wasOnSickLeave',
  WasHospitalized = 'question_wasHospitalized',
  HasPsychicIllness = 'question_hasPsychicIllness',
  HasFullVision = 'question_hasFullVision',
  HasChronicIllness = 'question_hasChronicIllness',
}

export enum TariffFieldsGMK {
  Manufacturer = 'kitchenManufacturer',
  KitchenModel = 'kitchenModel',
  TotalGrossPrice = 'kitchenTotalGrossPrice',
  PurchaseDate = 'kitchenPurchaseDate',
  BuildDate = 'buildDate',
  InvoiceNumber = 'invoiceNumber',
}

export enum TariffFieldsFunk {
  EmploymentStatus = 'employmentStatus',
  Season = 'season',
  Year = 'year',
  Completed = 'isCompleted',
  PolicyStartDate = 'policyStartDate',
  PreviousClaims = 'previousClaims',
  PreviousClaimsDescription = 'previousClaimsDescription',
  PreviousInsurerExists = 'previousInsurerExists',
  PreviousInsurer = 'previousInsurer',
  ContractIDPreviousInsurance = 'contractIDPreviousInsurance',
  ContractEndPreviousInsurance = 'contractEndPreviousInsurance'
}

// Auth enums
export enum AuthFlowParameters {
  USERNAME = 'USERNAME',
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum ChallengeNames {
  USER_PASSWORD_AUTH = 'USER_PASSWORD_AUTH',
  VERIFY_USERNAME = 'VERIFY_USERNAME',
  PHONE_CODE = 'PHONE_CODE',
  EMAIL_CODE = 'EMAIL_CODE',
  ADMIN_USER_PASSWORD_AUTH = 'ADMIN_USER_PASSWORD_AUTH',
  SIGNUP = 'SIGNUP',
  SMS_MFA = 'SMS_MFA',
}

export enum InsuredObjectTypes {
  All = 'all',
  Car = 'car',
  TPL = 'tpl',
  Custom = 'custom',
  Motorbike = 'motorbike',
  Moped = 'moped',
  Bike = 'bike',
  Accident = 'accident',
  Travel = 'travel',
  Device = 'device',
  HomeContents = 'homeContents',
  LegalProtection = 'legalProtection',
  Health = 'health',
  Default = 'default',
}

export enum BasicProductFields {
  Number = 'number',
  Boolean = 'boolean',
  String = 'string',
  Date = 'date',
}

export enum CustomProductFields {
  DateTime = 'dateTime',
  Email = 'email',
  LicensePlateRegion = 'licensePlateRegion',
  LicensePlateLetters = 'licensePlateLetters',
  LicensePlateNumbers = 'licensePlateNumbers',
  VehicleType = 'vehicleType',
  VehicleUsage = 'vehicleUsage',
  LicensePlateType = 'licensePlateType',
  SfClass = 'sfClass',
  DurationUnit = 'durationUnit',
  RenewalFrequency = 'renewalFrequency'
}

export enum ProductFieldTypeSystem {
  Basic = 'basic',
  Custom = 'custom'
}

export enum ProductFieldTypeEntity {
  SystemProductFieldType = 'system_product_field_type',
  ProductFactorType = 'product_factor_type'
}

export enum SystemProductFieldReadOnly {
  PolicyDurationUnit = 'policyDurationUnit',
  PolicyDurationValue = 'policyDurationValue',
  BillingFrequencyUnit = 'billingFrequencyUnit',
  BillingFrequencyValue = 'billingFrequencyValue',
  PolicyRenewalFrequency = 'policyRenewalFrequency',
  RecurringBillingDay = 'recurringBillingDay',
  RecurringPaymentDay = 'recurringPaymentDay',
  BillingMethod = 'billingMethod',
  IsRecurringBilling = 'isRecurringBilling',
  BrokerID = 'brokerID'
}

export enum ProductFactorValuesEnum {
  None = 'none',
  Short = 'short',
  All = 'all',
}

export enum Title {
  none = '',
  doctor = 'Dr.',
  medicineDoctor = 'Dr. med.',
  professor = 'Prof.',
}

export enum InsuredObjectsFunkMesse {
  Exhibition = 'exhibition',
  Liability = 'liability',
  Accident = 'accident',
  Default = 'default',
}

export enum TariffFieldsFunkMesse {
  PolicyStartDate = 'policyStartDate',
  PolicyEndDate = 'policyEndDate',
  Coverage = 'coverage',
  Origin = 'origin',
  Transportation = 'transportation',
  Place = 'place',
  EventName = 'eventName',
  Add = 'add',
  DescriptionEventIns = 'descriptionEventIns',
  DescriptionPlIns = 'descriptionPlIns',
  PrivateLiability = 'privateLiability',
  NumberOfInsuredPeople = 'numberOfInsuredPeople',
  PremiumPerPerson = 'premiumPerPerson',
  DescriptionAccidentIns = 'descriptionAccidentIns',
  PersonalSum = 'personalSum',
  InsuredSums = 'insuredSums'
}

export enum Separators {
  Slash = '/',
  Dash = '-',
  Dot = '.',
  Underscore = '_',
  Comma = ',',
  Space = ' ',
  Colon = ':',
}

export enum BaseFields {
  PolicyStartDate = 'policyStartDate',
  PolicyEndDate = 'policyEndDate',
  BirthDateDe = 'geburtsdatum',
  BirthDateEn = 'birthDate'
}

export enum AddressFields {
  Country = 'country',
  City = 'city',
  Street = 'street',
  HouseNumber = 'houseNumber',
  Zip = 'zip',
  IsAddressValid = 'isAddressValid',
  ZipCode = 'zipCode',
}

export enum UnderwritingResult {
  Failed = 'failed',
  Passed = 'passed',
  Warning = 'warning',
}

export enum TypeBF {
  CustomBF = 'customBF'
}

export enum LinkType {
  StaticPdf = 'staticPdf',
  LinkToSite = 'linkToSite',
}

export enum AccountDataType {
  Gender = 'gender',
  String = 'string',
  Email = 'email',
  Phone = 'phone',
  Date = 'date',
  Address = 'address',
  TitleType = 'title',
  AccountType = 'accountType',
  Number = 'number'
}

export enum FieldType {
  Policy = 'policy',
  Account = 'account',
  Label = 'label',
  PremiumPreview = 'premiumPreview',
  PaymentSettings = 'paymentSettings',
  Consent = 'consent',
  DownloadLink = 'downloadLink',
  SummaryPreview = 'summaryPreview',
  DownloadInfo = 'downloadInfo',
  EmailConfirmation = 'emailConfirmation',
  SubmitButton = 'submitButton',
  DownloadInfoExtended = 'downloadInfoExtended',
  FileUpload = 'fileUpload',
  StateVariable = 'stateVariable',
  AddressSwitcher = 'addressSwitcher',
  MultiInsuredObject = 'multiInsuredObject',
  AddressAutoComplete = 'addressAutoComplete',
  List = 'list',
  AccountSelect = 'accountSelect',
  EmilFunctionLookup = 'emilFunctionLookup',
}

export enum FormType {
  Login = 'login',
  Registration = 'registration',
}

export enum PaymentProvider {
  Stripe = 'Stripe',
  Emil = 'EMIL'
}

export enum NavigatorType {
  Circles = 'circles',
  Dropdown = 'dropdown'
}

export enum InputType {
  Time = 'time',
  Mask = 'mask',
  PhoneInput = 'phoneInput'
}

export enum PositionLabel {
  Top = 'top',
  Bottom = 'bottom'
}

export enum LayoutLevel {
  SubStep = 'subStep',
  Container = 'container',
  Item = 'item',
  TitelContainer = 'titleContainer',
}

export enum AccountFields {
  Gender = 'gender',
  AccountTitle = 'title',
  FirstName= 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  Email = 'email',
  Type = 'type',
  BirthDate = 'birthDate',
  Country = 'country',
  City = 'city',
  Street = 'street',
  HouseNumber = 'houseNumber',
  Zip = 'zip',
  IsAddressValid = 'isAddressValid',
  ZipCode = 'zipCode',
  AccountType = 'accountType',
}

export enum TooltipView {
  Popup = 'popup',
  Overlay = 'overlay'
}

