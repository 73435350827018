import React from 'react';
import { blankLink } from 'config';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { AsteriskRequiredField } from '../../../AsteriskRequiredField';
import { checkedUnderwriting, isRequiredField } from '../../../booking-funnel/booking-funnel-hooks';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';
import { TRUE_VALUE } from 'App/components/widgets/bf-hooks';
import { renderFieldLabel } from '../../core-hooks';

export interface BooleanFieldProps {
  valueField: boolean | undefined;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const BooleanField: React.FC<BooleanFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const defaultValueSC = stepItem && stepItem.defaultValue ? String( stepItem.defaultValue ) : '';

  const getDefaultValue = ( boolValue: JsonValue ): boolean => {
    if ( productFieldValue ) {
      return productFieldValue as boolean;
    }

    if ( defaultValueSC ) {
      return defaultValueSC.toLowerCase() === TRUE_VALUE;
    }

    return boolValue !== null ? boolValue as boolean : false;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled && defaultValueSC ) {
      setValue( fieldName, defaultValueSC.toLowerCase() === TRUE_VALUE, { shouldValidate: true } );
    }
  }, [ defaultValueSC, fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Controller
      name={ fieldName }
      control={ control }
      rules={ {
        required: isRequiredField( variable, stepItem ),
        validate: ( value ) => {
          if ( value === null ) {
            return false;
          }
          return true;
        },
      } }
      defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
      render={ ( props ) => (
        <FormGroup>
          <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
            <div
              className="d-inline-block"
              dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
            />
            { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
            { variable.bfTooltip && (
              <a
                id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                href={ blankLink }
                onClick={ ( e ) => {
                  e.preventDefault();
                  showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                } }
              >
                { t( 'bookingFunnel.tooltipHelp' ) }
              </a>
            ) }
          </Form.Label>
          { variable.bfDescription && (
            <span className="gm-info f-12 mt-1">
              { variable.bfDescription }
            </span>
          ) }
          <div className="switch switch-primary">
            <Form.Control
              { ...props }
              id={ props.name }
              className="cr"
              type="checkbox"
              onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => {
                props.onChange( e.target.checked );
                const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                actions.recalculationPremium( isCheckedUnderwriting );
              } }
              checked={ props.value as boolean }
              disabled={ isDisabled }
            />
            <Form.Label className="cr" htmlFor={ props.name }></Form.Label>
          </div>
          <Form.Control
            isInvalid={ errors[props.name] !== undefined }
            type="hidden"
          />
          <FormControl.Feedback type="invalid">
            { t( 'base:forms.messages.fieldRequired', {
              fieldLabel: variable.label,
            } ) }
          </FormControl.Feedback>
        </FormGroup>
      ) }
    />
  );
};
