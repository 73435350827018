import React from 'react';
import { upperFirst, words } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropdown, Button } from 'react-bootstrap';
import { LeadStatus } from '@cover42/ts-contracts';
import { useFormContext } from 'react-hook-form';

export interface CoreLeadNavProps {
  leadStatuses?: string[];
  currentLeadStatus: string;
  isSubmitting: boolean;
  onSaveLeadSubmit: ( status: string ) => Promise<void>;
}

const formatLeadStatus = ( name: string ) => {
  const joinedStatus = words( name ).join( ' ' );
  return upperFirst( joinedStatus );
};

export const CoreLeadNav: React.FC<CoreLeadNavProps> = (
  { leadStatuses, currentLeadStatus, isSubmitting, onSaveLeadSubmit } ) => {
  const { t } = useTranslation( [ 'base' ] );
  const { trigger } = useFormContext();

  const statusList = leadStatuses && Object.values( leadStatuses )
    .sort( ( a, b ) => a.localeCompare( b ) );

  const onSaveApplication = React.useCallback( ( status: string ): void => {
    trigger();
    setTimeout( () => {
      onSaveLeadSubmit( status );
    }, 500 );
  }, [ onSaveLeadSubmit, trigger ] );

  const formatStatus = React.useCallback( ( name: string ) => {
    switch( name ) {
      case LeadStatus.Created:
      case LeadStatus.Declined:
      case LeadStatus.Approved:
        return t( 'saveAsLabel', { statusName: t( `leadStatuses.${name}` ) } );
      default:
        return t( 'saveAsLabel', { statusName: formatLeadStatus( name ) } );
    }
  }, [ t ] );

  return (
    <>
      { statusList && statusList.length > 0 ? (
        <Dropdown drop="down">
          <Dropdown.Toggle disabled={ isSubmitting } id="bf-status-lead" className="ml-3 mr-0" variant="primary">
            { t( 'saveLead' ) }
          </Dropdown.Toggle>
          { statusList && statusList.length > 0 && (
            <>
              <Dropdown.Menu className="bf-edit-dropdown-menu">
                { statusList.map( ( statusName, idx ) => (
                  <Dropdown.Item
                    key={ idx }
                    onClick={ () => onSaveApplication( statusName ) }
                  >
                    { formatStatus( statusName ) }
                  </Dropdown.Item>
                ) ) }
              </Dropdown.Menu>
            </>
          ) }
        </Dropdown>
      ) : (
        <Button
          disabled={ isSubmitting }
          className="ml-3 mr-0"
          variant="primary"
          onClick={ () => onSaveApplication( currentLeadStatus ) }
        >
          { t( 'saveLead' ) }
        </Button>
      ) }
    </>
  );
};
