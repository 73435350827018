import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import arrowUpIconUrl from 'assets/bf-images/funk/arrow_up.svg';
import arrowDnIconUrl from 'assets/bf-images/funk/arrow_dn.svg';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  IBFInsuredObjectItem,
  IBFProductVariable,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DATETIME, isObject } from 'App/components/widgets/bf-hooks';
import { defaultDateFormat } from 'App/ui-utils';
import { ICFFactorType } from 'App/components/widgets/factor-service';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';

interface ObjectProps {
  stepItem: ContainerFieldItem;
  factors: ICFFactorType[];
  tariffData: ITariffDataStep;
  multiInsuredObjects: IBFInsuredObjectItem[];
}

export const ObjectList: React.FC<ObjectProps> = ( { stepItem, factors, tariffData, multiInsuredObjects } ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const [ isShowAllList, setShowAllLis ] = React.useState<boolean>( false );
  const configObject = stepItem && stepItem.config ? stepItem.config : '';

  const onShowList = React.useCallback ( ( isShow: boolean ): void => {
    setShowAllLis( isShow );
  }, [] );

  const firstObject = multiInsuredObjects[0];
  const allObjects = multiInsuredObjects.filter( ( obj ) => obj.name !== firstObject.name );

  const getValueFactorField = React.useCallback( ( variable: IBFProductVariable ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, tariffData ] );

  const getValueBaseField = React.useCallback( ( variable: IBFProductVariable ) => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return dayjs( selectedVal ).format( defaultDateFormat );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';
    return !isEmpty( resVal ) ? resVal : '-';
  }, [ t, tariffData ] );

  return (
    <Fragment>
      <div className="details-section d-flex justify-content-space-between mt-3 mx-4">
        <p>{ configObject['objectLabel'] }</p>
      </div>
      { firstObject && (
        <Fragment>
          <span className="mx-4 mt-3 d-inline-block f-16 text-current-color">
            { `${configObject['groupTitle']} (1)` }
          </span>
          { firstObject.productFields && firstObject.productFields.filter( ( f ) => !f.isHidden )
            .map( ( item, cIdx ) => (
              <Fragment key={ cIdx }>
                <div className="details-section d-flex justify-content-space-between mx-4">
                  <p>{ item.label }</p>
                  { item.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                    <p className="bf-selected-val">{ getValueBaseField( item ) }</p>
                  ) }
                  { item.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                    <p className="bf-selected-val">{ getValueFactorField( item ) }</p>
                  ) }
                </div>
              </Fragment>
            ) ) }
        </Fragment>
      ) }
      { isShowAllList && allObjects && allObjects.map( ( item, idx ) => (
        <Fragment key={ idx }>
          <span className="mx-4 mt-3 d-inline-block f-16 text-current-color">
            { `${configObject['groupTitle']} (${idx + 2})` }
          </span>
          { item.productFields && item.productFields.filter( ( v ) => !v.isHidden )
            .map( ( variable, cIdx ) => (
              <Fragment key={ cIdx }>
                <div className="details-section d-flex justify-content-space-between mx-4">
                  <p>{ variable.label }</p>
                  { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                    <p className="bf-selected-val">{ getValueBaseField( variable ) }</p>
                  ) }
                  { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                    <p className="bf-selected-val">{ getValueFactorField( variable ) }</p>
                  ) }
                </div>
              </Fragment>
            ) ) }
        </Fragment>
      ),
      ) }
      { allObjects.length >= 1 && (
        <div className="details-section d-flex justify-content-center">
          <a
            href={ blankLink }
            onClick={ ( e ) => {
              e.preventDefault();
              onShowList( !isShowAllList );
            } }
          >
            { isShowAllList ? (
              <Fragment>
                { t( 'bookingFunnel.funkReanovo.btnLess' ) }
                <img className="pl-2" src={ arrowUpIconUrl } alt="Icon Up" />
              </Fragment>
            ) : (
              <Fragment>
                { `${ t( 'bookingFunnel.funkReanovo.btnMore' )} +${ allObjects.length }` }
                <img className="pl-2" src={ arrowDnIconUrl } alt="Icon Down" />
              </Fragment>
            ) }
          </a>
        </div>
      ) }
    </Fragment>
  );
};
