import { IPersonalDataStep } from '../../App/components/widgets/booking-funnel/steps/PersonalDataStep2';
import { IPaymentDataStep } from '../../App/components/widgets/booking-funnel/steps/PaymentStep3';
import { CFDefaultValueType } from 'App/components/widgets/bf-hooks';
import { JsonObject, Struct, JsonValue } from '@cover42/protobuf-util';
import dayjs from 'dayjs';
import {
  ActionDeviceTypes,
  AuthFlowParameters,
  ChallengeNames,
  DeviceGroups,
  DeviceTypes,
  LinkType,
  PdfDocumentType,
  ProductFieldTypeEntity,
  Title,
  UnderwritingResult,
  TypeBF,
  FieldType,
  AccountDataType,
  FormType,
  PaymentProvider,
  PaymentMethodName,
  NavigatorType,
  InputType,
  PositionLabel,
  TooltipView,
} from './enums';
import { ICFFactorTypeOption } from 'App/components/widgets/factor-service';
import { IPaymentDataStepWS } from 'App/components/widgets/worksurance/steps/PaymentStep3';
import { IPersonalDataStepWS } from 'App/components/widgets/worksurance/steps/personal-sub-steps/PersonalSubStep';
import { IPersonalDataStepFunk } from 'App/components/widgets/funk/steps/personal-sub-steps/RegistrationStep';
import { PaymentMethod } from '@stripe/stripe-js/types/api/payment-methods';
import { PersonalDataStepFunkMesse } from 'App/components/widgets/funk-messe/steps/personal-sub-steps/RegistrationStep';
import { PayloadSendEmail } from '../../App/widget/types';
import { AccountType, LeadStatus, PremiumFormulaTypes, PremiumFormulaUnits } from '@cover42/ts-contracts';
import { JSONComponentType, JSONItemUIType, JSONPremiumShowsType } from 'App/components/widgets/booking-funnel/enum';
import { IInvoiceResult } from 'App/components/widgets/funk-reanovo/interfaces';
import { TLayout } from './types';
import { CriteriaType } from 'App/components/widgets/booking-funnel/functions';

export const accountsStorageKey: string = 'APP_ACCOUNTS';
export type PaymentSystemType = 'stripe' | 'braintree';
export interface ITariffDataStep {
  [key: string]: CFDefaultValueType;
}

export interface ILeadData {
  subStep1TariffData: ITariffDataStep | null;
  subStep2TariffData: ITariffDataStep | null;
  subStep1DeviceItems: IDeviceItem[] | null;
  personalData: IPersonalDataStep | IPersonalDataStepWS | IPersonalDataStepFunk | PersonalDataStepFunkMesse | null;
  paymentData: IPaymentDataStep | IPaymentDataStepWS | null;
  estimatedInvoiceData?: IProductInvoiceItemWS[];
  tariffData?: IProductInvoiceItemWS[];
  selectedEstimatedInvoiceData?: IProductInvoiceItemWS;
  questionData?: ITariffDataStep;
  versionData: string;
  tariffResultData?: ITariffDataStep | null;
  tariffChangeData?: ITariffDataStep | null;
  subStep2ResultData?: ITariffDataStep | null;
  isShowSavedInfo?: boolean;
  saveSelectedClient?: ITariffDataStep | null;
  isLogin?: boolean;
  isSavedData?: boolean;
  selectedTariff?: ITariffDataStep | null;
  summaryData?: ITariffDataStep | null;
  savedAt?: string;
  isShowWelcome?: boolean;
  isShowClickInfo?: boolean;
  invoiceResult?: IInvoiceResult | null;
  productInvoice?: IProductInvoice[] | null;
  multiInsuredObjects?: IBFInsuredObjectItem[];
  isEditLead: boolean;
  quote?: IProductInvoiceData;
  [key: string]: CFDefaultValueType;
}

export interface LeadItemData {
  [key: string]: JsonValue;
}

export interface IAccountLeadData {
  firstName: string;
  lastName: string;
  title?: Title;
  email: string;
  gender: number | string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  birthDate?: string;
  phone: string;
  type?: AccountType;
  customFields?: JsonObject;
  companyName?: string;
}

export interface IAccountLeadDataFunk {
  firstName: string;
  lastName: string;
  title: Title;
  email: string;
  gender: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  birthDate: string;
  phone: string;
  customFields?: JsonObject;
}

export interface AccountLeadDataFunkMesse {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phone: string;
  companyName: string;
  type: AccountType;
  customFields?: JsonObject;
}

export interface ICustomData {
  saveData: ILeadData;
  linkToBF: string;
}

export interface BankAccountItem {
  accountCode?: string;
  iban: string;
}

export interface UploadedDocument {
  codes: string[];
}

export interface ICreateLeadData {
  code: string;
  productCode: string;
  policyObjects: IPolicyObject[];
  account: IAccountLeadData | IAccountLeadDataFunk | AccountLeadDataFunkMesse | JsonObject;
  customData?: ICustomData;
  status?: LeadStatus | string;
  productVersionId?: number;
  bankAccount?: BankAccountItem;
  paymentMethod?: PaymentMethodRequest;
  uploadedDocument?: UploadedDocument;
  accountCode?: string;
  validate?: boolean;
}

export interface PaymentMethodRequest {
  type: PaymentMethodName;
  sepa?: SepaDebit;
}

export interface SepaDebit {
  iban: string;
  holderName?: string;
}

export interface ILeadPolicyItem {
  productVersionId: number;
  accountCode: string;
  policyObjects: IPolicyObject[];
}

export interface LeadItem {
  id: number;
  account: IAccountLeadData | IAccountLeadDataFunk | AccountLeadDataFunkMesse;
  code: string;
  policy: ILeadPolicyItem;
  customData: ICustomData;
  productSlug: string;
  status: LeadStatus;
  leadNumber: string;
}

export interface LeadDataResponse {
  lead: LeadItem;
}

export interface IProductPremiumFormula {
  id: number;
  productVersionId: number;
  group: string;
  name: string;
  expression: string;
  type: string;
  unit: string;
  visibility: string;
  itemType: string;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
}

export interface IProductPremium {
  premiumFormulaId: number;
  total: number;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  premiumFormula: IProductPremiumFormula;
}

export interface IPremiumCalculationState {
  premiumData: IProductPremiumData | null;
  error: string | null;
}

export interface IPremiumCalculationStateGM {
  premiumData: IProductInvoiceData | null;
  error: string | null;
}

export interface IProductPremiumData {
  grandTotal: number;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  premiumItems: IProductPremium[];
}

export interface ProductPremiumResponse {
  premium: IProductPremiumData;
}

export interface IProductInvoice {
  premiumFormulaId: number;
  billId: number;
  name: string;
  group: string;
  quantity: number;
  pricePerUnit: number;
  unit: string;
  netAmount: number;
  taxAmount: number;
  grossAmount: number;
  taxCode: string;
  taxRate: number;
  billingIntervalFrom: dayjs.Dayjs;
  billingIntervalTo: dayjs.Dayjs;
  visibility: string;
  itemType: string;
}

export interface CurrencyItem {
  id: number;
  code: string;
  name: string;
  nativeName: string;
  symbol: string;
}

export interface IProductInvoiceData {
  accountNumber: string;
  policyCode: string;
  type: string;
  invoiceNumber: string;
  netAmount: number;
  taxAmount: number;
  grossAmount: number;
  creditAmount: number;
  currency: string;
  billingIntervalFrom: dayjs.Dayjs;
  billingIntervalTo: dayjs.Dayjs;
  invoiceItems: IProductInvoice[];
}

export interface GetProductDocumentUrlResponse {
  url: string;
}

export interface ProductInvoiceResponse {
  invoice: IProductInvoiceData;
}

export interface IDataStruct {
  [key: string]: Struct;
}

export interface IProductDataForInvoice {
  policyObjects: IPolicyObject[];
  billingIntervalFrom?: string;
  billingIntervalTo?: string;
}

export interface IPayloadInvoice {
  policyObjects: IPolicyObject[];
  billingIntervalFrom?: string;
  billingIntervalTo?: string;
}

export interface IPayloadForDocumentPdf {
  templateSlug: string;
  entityType: string;
  description: string;
  requester: string;
  payload: JsonObject | null;
  productSlug?: string;
  shouldSkipDefaultMargins?: boolean;
  engine?: string;
}

export interface MutationResponse {
  errorCode: number; // 0 means no error
  errorMessage?: string;
  data?: any;
}

export interface IPolicyObject {
  insuredObjectId: number;
  insuredObjectName?: string;
  data: Struct | JsonObject | null;
}

export interface IPolicyObjects {
  [key: string]: IPolicyObject[];
}

export interface IPolicyVariable {
  label: string;
  value: string;
}

export interface IPolicyData {
  id: number;
  groupName: string;
  variables: IPolicyVariable[];
};

export interface IPolicyObjectPayload {
  timesliceId: number;
  insuredObject: IBFInsuredObjectItem;
  insuredObjectName: string;
  data: JsonObject;
};

export interface HashUrlParam {
  name: string;
  value: string;
};

export interface IBFCustomStylesSettings {
  logo: string;
  styles: string;
  documentSettings?: IDocumentDownload[];
  stepsConfig?: StepsConfig;
}

export interface BFCustomStylesSettingsRequest {
  key: string;
  value: IBFCustomStylesSettings;
}

export interface BFCustomStylesSettingsResponse extends BFCustomStylesSettingsRequest {
  type: string;
}

export interface IBFCustomStylesSettingsResponse {
  values: IBFCustomStylesSettings;
}

export interface IBFProductVariable {
  id: number;
  name: string;
  label: string;
  group: string;
  groupName?: string;
  typeEntity: ProductFieldTypeEntity;
  typeId: number;
  insuredObjectId: number;
  isRequired: boolean;
  isUnique: boolean;
  isHidden: boolean;
  defaultValue: JsonValue;
  maxValue: JsonValue;
  minValue: JsonValue;
  createdAt: string;
  updatedAt: string;
  isSystem?: boolean;
  bfLabel?: string;
  bfTooltip?: string;
  bfDescription?: string;
  legacyBfOrder?: number;
  order?: number;
}

export interface IBFInsuredObjectItem {
  id: number;
  name: string;
  label: string;
  isMultiInsuredObject: boolean;
  minInsuredObjectsCount: number | null;
  maxInsuredObjectsCount: number | null;
  insuredObjectTypeId: number;
  productVersionId: number;
  productFields?: IBFProductVariable[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IBFInsuredObjectDevice extends IBFInsuredObjectItem {
  deviceType?: DeviceTypes;
}

export interface ILeadCode {
  leadCode: string;
  accountCode?: string | undefined;
}

export interface IPaymentSystem {
  stripe?: ILeadCode;
  braintree?: ILeadCode;
}

export interface IInitiateLeadResponse {
  leadCode: string;
  psp: string;
  data: Record<string, string>;
}

export interface IInitiateStripePaymentSetup {
  pspSecretToken: string;
  pspCustomerId: string;
}

export interface IInitiateBraintreePaymentSetup {
  pspSecretToken: string;
}

export interface IInitiatePaymentSetup {
  stripe?: IInitiateStripePaymentSetup;
  braintree?: IInitiateBraintreePaymentSetup;
}

export interface IInitiatePaymentSetupResponse {
  paymentSystem: IInitiatePaymentSetup;
}

export interface IPaymentSystemStripe {
  email: string;
  firstName: string;
  lastName: string;
  leadCode: string;
  pspCustomerId: string;
  pspPaymentMethodId: string | null | PaymentMethod;
}

export interface IPaymentSystemBraintree {
  email: string;
  firstName: string;
  lastName: string;
  leadCode: string;
  nonce: string;
}

export interface IPaymentSystemComplete {
  stripe?: IPaymentSystemStripe;
  braintree?: IPaymentSystemBraintree;
}

export interface IPaymentMethod {
  id: number;
  code: string;
  providerToken: string;
  pspCustomerId: string;
  psp: string;
  type: string;
  createdAt: string;
}

export interface ICompletePaymentSetupResponse {
  paymentMethod: IPaymentMethod;
}

export interface IDeviceItem {
  insuredId: number | null;
  groupName?: DeviceGroups;
  typeDevice?: DeviceTypes;
  value?: ICFFactorTypeOption;
  addedNewDevice: ActionDeviceTypes;
  addOnFamily?: boolean;
  showFields: boolean;
}

export interface ITariffDataSend {
  formData: ITariffDataStep;
  deviceItems?: IDeviceItem[];
}

export interface IStaticDocumentItem {
  id: number;
  code: string;
  templateSlug: string;
  entityType: string;
  description: string;
  requester: string;
  createdAt: string;
  s3Key: string;
  contentType: string;
  productSlug: string;
}

export interface IProductDocumentItem {
  id: number;
  code: string;
  productVersionId: number;
  type: string;
  description: string;
  s3Key: string;
  createdAt: string;
  contentType: string;
  productSlug: string;
  entityType: string;
}

export interface UnderwritingItem {
  enabled: boolean;
}

export interface PaymentSettings {
  hasSEPA: boolean;
  hasCreditCard: boolean;
  hasInvoice: boolean;
}

export interface PremiumSettings {
  showZeroAmount: boolean;
  showItems: boolean;
  premiumShowsType?: JSONPremiumShowsType;
  isCustomDesign?: boolean;
  saveForLater?: boolean;
  defaultErrorMessage?: string;
  showYearlyCalculation?: boolean;
  showNegativeAmount: boolean;
  ignoreRequiredFields: boolean;
}

export interface SummarySettings {
  autoExpand: boolean;
  isShowStepEditButton?: boolean;
}

export interface DocumentsSettings {
  documents: IDocumentDownload[];
  shouldSkipDefaultMargins?: boolean;
}

export interface AddressSettings {
  isSingleLine: boolean;
  isValidated: boolean;
  restrictedCountries: string[];
  pathToAddressObject?: string;
};

export interface FileUploadSettings {
  isMulti: boolean;
  maxFiles: number;
  minSize: number;
  maxSize: number;
  errorMessage?: string;
  hintMessage?: string;
}

export interface SummaryLabel {
  stepName: string;
  textHTML: string;
  position: PositionLabel;
}

export interface AddressFieldItem {
  fieldName: string;
  fieldNameResponse: string;
}

export interface FieldStringSettings {
  mask: string;
  maskChar: string;
}

export interface PhoneInputSettings {
  defaultCountry: string;
  countries: string;
  international?: boolean;
}

export interface ContainerFieldItem {
  id: string;
  insuredObjectName: string;
  fieldName: string;
  uiType?: JSONItemUIType;
  isRequired?: boolean;
  ignoreOnRecalculation?: boolean;
  premiumName?: string;
  classContainerItem?: string;
  classNameBox?: string;
  className?: string;
  isReadOnly?: boolean;
  suffix?: string;
  isMulti?: boolean;
  config?: JsonObject;
  ruleCriteria?: CriteriaItem;
  underwritingCriteria?: UnderwritingItem;
  paymentType?: string;
  infoTextPayment?: string[];
  filterOptions?: string[];
  dataType?: AccountDataType;
  type?: FieldType;
  textHTML?: string;
  settings?: PaymentSettings | PremiumSettings | SummarySettings |
  DocumentsSettings | AddressSettings | FileUploadSettings | FieldStringSettings | PhoneInputSettings;
  downloadLinks?: DownloadLinkItem[];
  provider?: PaymentProvider;
  name?: string;
  emailField?: EmailField;
  buttonText?: string;
  errorText?: string;
  successText?: string;
  phoneFormatText?: string;
  phoneFormatRegex?: string;
  label?: string;
  tooltip?: string;
  defaultValue?: string | number | boolean;
  inputType?: InputType;
  disableCriteria?: CriteriaItem;
  fieldValue?: JsonValue;
  placeholder?: string;
  labels?: SummaryLabel[];
  visibleCriteria?: CriteriaItem;
  sortByLabel?: boolean;
  sortedKeys?: string;
  groupName?: string;
  items?: JsonObject[];
  metadata?: JsonObject;
  isIntegers?: boolean;
  increment?: number | string;
  fieldItems: AddressFieldItem[];
  description: string;
  factorName?: string;
  input?: string;
  row?: string;
  filter?: string;
  pageSize?: number;
  mapping?: string;
  optionLabel?: string;
  emilFunctionSlug?: string;
  isGoNextStepAfterSelected?: boolean;
}

export interface CriteriaItem {
  name?: string;
  type: CriteriaType;
  value?: any;
  ruleConfig?: JsonObject;
  args: CriteriaItem[];
}

export interface ContainerItem {
  id: string;
  name: string;
  groupName?: string;
  title?: string;
  subTitle?: string;
  navigationTitle?: string;
  items: ContainerFieldItem[];
  visibleCriteria: CriteriaItem;
}

export interface DownloadLinkItem {
  name: string;
  textInfo: string;
  link: string;
}

export interface LinkItem {
  name: string;
  linkType: LinkType;
  linkKey: string;
  linkValue: string;
  entityType?: string;
  link: string;
}

export interface ContainerItemConsent {
  name: string;
  title: string;
  isRequired?: boolean;
  linkItems?: LinkItem[];
}

export interface EmailField {
  name: string;
  label: string;
}

export interface VisibleEmailSettings {
  emailName: string;
  codeName: string;
}

export interface SaveLeadOnComplete {
  enabled: boolean;
  beginStatus: string;
  endStatus: string;
  defaultAccountEmail: string;
}

export interface IHTMLText {
  htmlText: string;
  linkedItems?: LinkItem[];
}

export interface ResultStructure {
  title: IHTMLText;
  body: IHTMLText;
}

export interface CoreResultStructure {
  htmlText: string;
  linkedItems?: LinkItem[];
  body: string;
}

export interface LoginSettings {
  disableMFA: boolean;
  successResult: ResultStructure;
  errorResult: ResultStructure;
}

export interface SubStepItem {
  id: string;
  name: string;
  enabledPremiumCalculation?: boolean;
  premiumShowsType?: JSONPremiumShowsType;
  title?: string;
  subTitle?: string;
  stepTitle?: string;
  stepTitleToSummary?: string;
  formType?: FormType;
  containers: ContainerItem[];
  visibleCriteria?: CriteriaItem;
  subStepName?: string;
  requiredText?: string;
  downloadLinks?: DownloadLinkItem[];
  settings?: LoginSettings;
}

export interface StepItem {
  id: string;
  name: string;
  title?: string;
  subTitle?: string;
  navigationTitle?: string;
  componentType?: JSONComponentType;
  subSteps: SubStepItem[];
  visibleIfEmailValidated?: boolean;
  visibleEmailSettings?: VisibleEmailSettings;
  saveLeadOnComplete?: SaveLeadOnComplete;
}

export interface NavigatorItem {
  type: NavigatorType;
}

export interface SaveForLaterSettings {
  leadStatus: string;
  successInfoText1: string;
  successInfoText2: string;
  confirmationEmailLabel: string;
}

export interface StepsConfig {
  steps: StepItem[];
  customData?: JsonValue;
  workflowSlug?: string;
  typeBF?: TypeBF;
  successPageContent?: SuccessPageContent;
  navigator?: NavigatorItem;
  saveForLaterSettings?: SaveForLaterSettings;
  layout?: TLayout;
  initialLeadStatus?: string;
  tooltipView?: TooltipView;
}

export interface SuccessPageContent {
  successMessage: string;
  toHomePageBtnText: string;
  showApplicationNumber: boolean;
  toHomePageUrl: string;
}

export interface IDocumentDownload {
  documentDescriptionKey?: string;
  documentNameKey: string;
  documentType: PdfDocumentType;
  entityType?: string;
  templateSlug?: string;
  linkToFile?: string;
  isDisabled?: boolean;
}

export interface IScreenType {
  [key: string]: boolean;
}

export interface IPolicyObjectWS {
  birthdate: number;
  coverages: number[];
  bmi: number;
  end_ages?: number[];
  smoker?: boolean;
  job_class: number;
}

export interface IProductDataForInvoiceWS {
  data: IPolicyObjectWS;
  provider: string;
}

export interface IProductInvoiceItemWS {
  tariff: string;
  coverage: number;
  end_age: number;
  monthly_gross_premium: number;
  yearly_gross_premium: number;
  yearly_fix_costs: number;
  yearly_alpha: number;
  yearly_beta: number;
  yearly_gamma: number;
  yearly_tax: number;
}

export interface IInvoiceItemResponseWS {
  currency: string;
  origin: number;
  premium: IProductInvoiceItemWS[];
  status: string;
  timestamp: string;
}

export interface ProductInvoiceWSResponse {
  custom: IInvoiceItemResponseWS;
}

export interface ProductInvoiceFields {
  starter: IPolicyObjectWS;
  performer: IPolicyObjectWS;
}

export interface IParamsWS {
  tariffNames: string[];
  coverage: number;
  endAge: number[];
}

export interface IHolderWS {
  firstname: string;
  lastname: string;
  nationality: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  email: string;
  birthdate?: number;
  profession: string;
  phone: string;
}

export interface IPaymentWS {
  iban: string;
  mandate_signature_date: number;
}

export interface IPolicyInformationWS {
  origin: number;
  tariff: string;
  bmi: number;
  coverage: number;
  end_age: number;
  job_class: number;
  duration: string;
  policy_start_date: string;
  policy_end_date: string;
  age_client: string;
  monthly_net_premium: number;
  monthly_fixed_cost: number;
  monthly_sales_cost: number;
  gross_sales_cost: number;
  monthly_premium: number;
  yearly_net_premium: number;
  yearly_fixed_cost: number;
  yearly_sales_cost: number;
  yearly_premium: number;
  dynamic: number;
}

export interface ICustomApplicationObjectWS {
  holder: IHolderWS;
  payment: IPaymentWS;
  policy_information: IPolicyInformationWS;
}

export interface ICustomApplicationWS {
  data: ICustomApplicationObjectWS;
  provider: string;
}

export interface ICustomApplicationItemWS {
  timestamp: string;
  policy_number: string;
  monthly_benefit: number;
  duration: number;
  end: number;
  tariff_name: string;
  monthly_premium: number;
  general_condition_version: number;
  status: string;
}

export interface CustomApplicationResponse {
  application: ICustomApplicationItemWS;
}

export interface ICustomDataWS extends ICustomApplicationWS {
  productCode: string;
}

export interface ICreateLeadDataWS {
  productCode: string;
  policyObjects: IPolicyObject[];
  account: IAccountLeadData;
  customData: ICustomDataWS;
  premiumOverride?: PremiumOverride;
}

export interface PremiumOverride {
  premiumOverrides: PremiumOverrideItem[];
}

export interface PremiumOverrideItem {
  name: string;
  type: PremiumFormulaTypes;
  unit: PremiumFormulaUnits;
  netPremium: number;
}

export interface IAuthParameter {
  [key: string]: string;
}

export interface IInitiateAuth {
  authFlow: ChallengeNames;
  authParameters: IAuthParameter;
  tenantSlug: string;
}

export interface IChallengeParameter {
  REQUIRED_PARAMS: AuthFlowParameters[];
}

export interface IInitiateAuthResponse {
  challengeName: ChallengeNames;
  challengeParameters: IChallengeParameter;
}

export interface IChallengeResponse {
  USERNAME: string;
  PASSWORD?: string;
  SMS_MFA_CODE?: string;
}

export interface IRespondAuth {
  challengeName: ChallengeNames;
  challengeResponses?: IChallengeResponse;
  challengeParameters?: IAuthParameter;
  tenantSlug: string;
  session?: string;
}

export interface IRespondAuthResponse {
  session?: string;
  challengeName: ChallengeNames;
  challengeParameters: IAuthParameter;
  tenantSlug: string;
}

export interface IAuthenticationItem {
  accessToken: string;
  refreshToken: string;
}

export interface ISmsCodeAuthResponse {
  authenticationResult: IAuthenticationItem;
}

export interface IAccountItem {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  birthDate: string;
  phone: string;
}

export interface ICustomerItem {
  id: number;
  code: string;
  sub: string;
  status: string;
  tenantSlug: string;
  account: IAccountItem;
  createdAt: string;
  updatedAt: string;
}

export interface ICustomerResponse {
  customer: ICustomerItem;
}

export interface IInsuranceTypeItem {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
}

export interface IApiListResponse<ItemsType> {
  items: ItemsType[];
  nextPageToken: string;
}

export interface SendEmailPayload {
  templateSlug: string;
  emailSubject: string;
  payload: PayloadSendEmail;
}

export interface SendEmailErrorResponse {
  message: string;
  statusCode: string;
}

export interface SendEmailResponse extends SendEmailErrorResponse {
  code: string;
}

export interface ErrorDataItem {
  message: string;
  statusCode: number;
}

export interface ErrorItem {
  data: ErrorDataItem;
  error: boolean;
}

export interface ApiErrorResponse {
  error: ErrorItem;
}

export interface IPSPConfig {
  psp: PaymentSystemType;
  publicKey: string;
}

export interface FormValues {
  [key: string]: string | number | boolean | null | any | undefined;
}

export interface BankAccount {
  accountCode: string;
  iban: string;
}

export interface PolicyItem {
  accountCode: string;
  policyObjects: IPolicyObject[];
  productVersionId: number;
}

export interface LeadDataItem {
  id: number;
  code: string;
  policy: PolicyItem;
  account: IAccountLeadDataFunk;
  accountCode?: string;
  customData: ICustomData;
  bankAccount: BankAccount;
  productSlug: string;
  premium: Record<string, any>;
  status: LeadStatus;
  psp: string;
  data: Record<string, string>;
  createdAt: string;
  updatedAt: string;
}

export interface LeadDataItemResponse {
  lead: LeadDataItem;
}

export interface AddressItem {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  countyCode: string;
  county: string;
  city: string;
  district: string;
  street: string;
  postalCode: string;
  houseNumber: string;
}

export interface AddressCompletionItem {
  id: string;
  title: string;
  language: string;
  resultType: string;
  addressDetails: AddressItem;
}

export interface AddressCompletionResponse {
  items: AddressCompletionItem[];
}

export interface AddressCompletionItem {
  id: string;
  title: string;
  language: string;
  resultType: string;
  addressDetails: AddressItem;
}
export interface ValidateAddress {
  errors: Record<string, number | string>;
  isAddressValid: boolean;
  rawAddress: string;
  resultType: string;
  addressDetails: AddressItem;
}

export interface WorkflowItem {
  id: number;
  code: string;
  name: string;
  productSlug: string;
  slug: String;
}

export interface WorkflowResponse {
  items: WorkflowItem[];
}

export interface FormattedObjectsRule {
  formattedObjects: JsonValue;
}

export interface PayloadRule {
  policy: FormattedObjectsRule;
  allAddressesAreValid: boolean;
}

export interface UnderwritingItem {
  result: UnderwritingResult;
  statusMessage: string;
  errorMessage: string;
}
export interface VariableItem {
  tenantSlug: string;
  underwritingResult: UnderwritingItem;
}

export interface VariableResponse {
  variables: VariableItem;
}

export interface ProductItem {
  id: number;
  code: string;
  tenantId: number;
  name: string;
  slug: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  contractDurationDays: number;
}

export interface ProductResponse {
  items: ProductItem[];
}

export interface InitiateEmailVerification {
  email: string;
}

export interface CompleteEmailVerification {
  email: string;
  token: string;
}

export interface EmailVerificationResponse {
  result: boolean;
}

export interface AuthResponse {
  authenticationResult: IAuthenticationItem;
}

export interface FileItem {
  code: string;
  description: string;
  createdAt: string;
  contentType: string;
  filename: string;
}

export interface PreSignedPostRequest {
  templateSlug: string;
  entityType: string;
  description: string;
  requester: string;
  contentType: string;
  isoContentType: string;
  filename: string;
  leadCode?: string;
}

interface PreSignedPostFields extends Record<string, string> {
  templateSlug: string;
  entityId: string;
  entityType: string;
  contentType: string;
  filename: string;
  code: string;
  bucket: string;
  key: string;
}

export interface PreSignedPost {
  fields: PreSignedPostFields;
  url: string;
}

export interface FilterNamedRangeRequest {
  productSlug: string;
  name: string;
  filters?: string;
  search?: string;
  order?: string;
  pageSize?: number;
  pageToken?: string;
}

export interface FilterNamedRangeResponse {
  items: JsonObject[];
  nextPageToken: string;
  itemsPerPage: number;
  totalItems: number;
}

export interface BookingFunnelSettings {
  logoPath: string;
  css: string;
  stepsConfig?: StepsConfig;
}

export interface BookingFunnelSettingsResponse {
  bookingFunnel: BookingFunnelSettings;
}
