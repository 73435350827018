import React, { Fragment } from 'react';
import closeIcon from 'assets/bf-images/funk/close_green_icon.svg';
import emailIcon from 'assets/bf-images/funk/email_icon.svg';
import markEmailIcon from 'assets/bf-images/funk/mark_email_icon.svg';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import dayjs from 'dayjs';
import GenderOptions from '../../../genderOptions';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEmailDataValidationSchemaFunk } from '../../validation';
import { Trans, useTranslation } from 'react-i18next';
import { blankLink } from 'config';
import {
  ITariffDataStep,
  ILeadData,
  ICreateLeadData,
  IPolicyObject,
  IAccountLeadData,
} from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import {
  convertDateToApi,
  generateVariables,
  keyMultiInsuredObjects,
  personalDataKey,
  prepareAccountData,
  prepareDataForTariff,
  preparePolicyPayload,
} from '../../core-hooks';
import { useWidgetService } from 'Services/widget';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { apiDateTimeFormat } from 'App/ui-utils';
import { useAppLogger } from 'Services/logger';
import { AccountType, LeadStatus } from '@cover42/ts-contracts';
import { PersonalDataStepCore } from '../../steps/personal-sub-steps/RegistrationStep';
import { personalDataCoreKey } from '../steps/personal-sub-steps/RegistrationCoreStep';
import { CFDefaultValueType, getParamUrlByName } from 'App/components/widgets/bf-hooks';

export interface CoreDataPopupProps {
  currentStep: string;
  productCode: string;
  productData: IDataFactorsAndVariables;
  onClose: () => void;
  goNextStep: () => void;
  policyStepNames: string[];
}

export interface CoreEmailData {
  email: string;
  confirmEmail: string;
  confirmationEmailData: boolean | undefined;
}

const initialData: CoreEmailData = {
  email: '',
  confirmEmail: '',
  confirmationEmailData: false,
};

export const CoreSavedDataPopup: React.FC<CoreDataPopupProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const logger = useAppLogger();
  const service = useWidgetService();
  const currentUrl = window.location.href;
  const emailDataValidationSchema = useEmailDataValidationSchemaFunk();

  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const { currentStep, productCode, productData, onClose, policyStepNames } = dataProps;
  const saveForLaterSettings = productData.stepsConfig?.saveForLaterSettings;

  const formContext = useForm<CoreEmailData>( {
    mode: 'onChange',
    resolver: yupResolver( emailDataValidationSchema ),
  } );

  const { formState, control, errors } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    try {
      const leadStoreData: ILeadData = await service.getLead();

      const tariffData = preparePolicyPayload( leadStoreData, policyStepNames!, productData.stepsConfig );

      let tariffDataForMultiInsured: IPolicyObject[] = [];
      const multiInsuredObjects = leadStoreData[keyMultiInsuredObjects];

      if ( multiInsuredObjects ) {
        const variables = generateVariables( multiInsuredObjects );

        tariffDataForMultiInsured = prepareDataForTariff(
          tariffData,
          variables,
          multiInsuredObjects,
          false,
        );
      }

      const preparedPolicyData = prepareDataForTariff(
        tariffData,
        productData.variables,
        productData.insuredObjects,
        false,
      );

      let newAccountData: IAccountLeadData = {
        gender: GenderOptions[0].value,
        firstName: '',
        lastName: '',
        zipCode: '',
        city: '',
        street: '',
        houseNumber: '',
        phone: '',
        email: formData['email'],
        type: AccountType.Person,
      };

      const savedPersonalData = leadStoreData[personalDataCoreKey] as unknown as IAccountLeadData;

      if ( savedPersonalData !== null ) {
        newAccountData = prepareAccountData(
          savedPersonalData as unknown as PersonalDataStepCore, productData.stepsConfig,
        );
      } else {
        leadStoreData[personalDataCoreKey] = newAccountData as unknown as CFDefaultValueType;
      }

      let linkToBF = currentUrl;
      const leadCode = getParamUrlByName( 'leadCode' );

      if ( !leadCode ) {
        linkToBF = `${linkToBF}&leadCode=${productData.leadData.leadCode}`;
      }

      const bookingFunnelCode = getParamUrlByName( 'bookingFunnelCode' );

      if ( !bookingFunnelCode && productData.bookingFunnelCode ) {
        linkToBF = `${linkToBF}&bookingFunnelCode=${productData.bookingFunnelCode}`;
      }

      const payloadLead: ICreateLeadData = {
        code: productData.leadData.leadCode,
        productCode: productCode,
        policyObjects: [ ...preparedPolicyData, ...tariffDataForMultiInsured ],
        account: newAccountData,
        customData: {
          saveData: {
            ...leadStoreData,
            savedAt: convertDateToApi( dayjs().format( apiDateTimeFormat ) ),
            isShowWelcome: true,
          },
          linkToBF,
        },
        validate: false,
        status: saveForLaterSettings && saveForLaterSettings.leadStatus
          ? saveForLaterSettings.leadStatus : LeadStatus.Created,
      };

      const leadData = await service.ckeckLeadData( productData.leadData.leadCode );

      if ( leadData ) {
        payloadLead.productVersionId = leadData.policy.productVersionId;
      }

      const result = leadData ?
        await service.updateLead( payloadLead ) : await service.createLead( payloadLead, false );

      if ( result ) {
        await service.savedInfo( personalDataKey, newAccountData );

        setIsSuccess( true );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } catch( e ) {
      if ( e instanceof Error ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
        logger.error( e );
      } else {
        throw e;
      }
    }
  }, [ currentUrl, logger, policyStepNames, productCode, productData.bookingFunnelCode, productData.insuredObjects,
    productData.leadData.leadCode, productData.stepsConfig, productData.variables,
    saveForLaterSettings, service, showAlert, t ] );

  return (
    <Fragment>
      <div className="modal-open dynamic-save-data">
        <div className="fade modal-backdrop show"></div>
        <div className="fade gm-tooltip-modal modal show" style={ { display: 'block' } }>
          <Modal.Dialog centered>
            <Modal.Header>
              <a
                id={ `${currentStep}_close_modal` }
                className="dynamic-close-btn"
                href={ blankLink }
                onClick={ ( e ) => {
                  e.preventDefault();
                  onClose();
                } }
              >
                <img src={ closeIcon } alt="Close icon" />
              </a>
              <div className="dynamic-header-box">
                <img src={ emailIcon } alt="Email icon" />
                <h2>{ t( 'bookingFunnel.funkReanovo.emailPopup.title' ) }</h2>
              </div>
            </Modal.Header>
            <Modal.Body>
              { isSuccess && (
                <div className='max-w-744'>
                  { saveForLaterSettings && saveForLaterSettings.successInfoText2 ? (
                    <div
                      className="d-inline-block"
                      dangerouslySetInnerHTML={ { __html: `${saveForLaterSettings.successInfoText2}` } }
                    >
                    </div>
                  ) : (
                    <Fragment>
                      <p className="text-center">{ t( 'bookingFunnel.funkReanovo.emailPopup.successInfoText1' ) }</p>
                      <p className="text-center">{ t( 'bookingFunnel.funkReanovo.emailPopup.successInfoText2' ) }</p>
                    </Fragment>
                  ) }

                  <Row className="dynamic-btn-panel mt-4 mx-0 d-flex justify-content-center">
                    <Button
                      id={ `${currentStep}_send_data_confirm` }
                      type="button"
                      variant="primary"
                      className="mr-0 mb-0 bg-btn-primary"
                      onClick={ () => onClose() }
                    >
                      { t( 'bookingFunnel.funkReanovo.nextBtnEmail' ) }
                      <img
                        src={ rightArrowIconUrl }
                        className="ml-2"
                        alt={ t( 'bookingFunnel.funkReanovo.nextBtnEmail' ) }
                      />
                    </Button>
                  </Row>
                </div>
              ) }
              { !isSuccess && (
                <Fragment>
                  <div id="alert-save-page" className="max-w-744">
                    <AppAlertUI />
                  </div>
                  <div className='max-w-744'>
                    { saveForLaterSettings && saveForLaterSettings.successInfoText1 ? (
                      <div
                        className="d-inline-block"
                        dangerouslySetInnerHTML={ { __html: `${saveForLaterSettings.successInfoText1}` } }
                      >
                      </div>
                    ) : (
                      <Fragment>
                        <p className="text-justify">{ t( 'bookingFunnel.funkReanovo.emailPopup.discription' ) }</p>
                        <p className="text-justify">
                          <Trans
                            i18nKey="bookingFunnel.funkReanovo.emailPopup.textInfo"
                            ns="widgets"
                            components={ {
                              linkToSite:
                              <a rel="noopener noreferrer"
                                className="font-weight-bold"
                                target="_blank"
                                href="https://www.funk-experts.de/datenschutz"
                              >
                                Link
                              </a>,
                            } }
                          />
                        </p>
                      </Fragment>
                    ) }
                    <Form className="mt-3" noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
                      <Row>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="email"
                            control={ control }
                            defaultValue={ initialData.email }
                            render={ ( props ) => (
                              <FormGroup controlId={ props.name } className="dynamic-email-box">
                                <Form.Label>{ t( 'bookingFunnel.funkReanovo.emailPopup.emailField' ) }</Form.Label>
                                <Form.Control
                                  { ...props }
                                  type="email"
                                  className="bf-custom-input"
                                  placeholder={ t( 'bookingFunnel.funkReanovo.emailPopup.emailPlaceholder' ) }
                                  isInvalid={ errors[props.name] !== undefined }
                                />
                                <Form.Control.Feedback type="invalid">
                                  { errors[props.name]?.message }
                                </Form.Control.Feedback>
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="confirmEmail"
                            control={ control }
                            defaultValue={ initialData.confirmEmail }
                            render={ ( props ) => (
                              <FormGroup controlId={ props.name } className="dynamic-email-box">
                                <Form.Label>
                                  { t( 'bookingFunnel.funkReanovo.emailPopup.confirmEmailField' ) }
                                </Form.Label>
                                <Form.Control
                                  { ...props }
                                  type="email"
                                  className="bf-custom-input"
                                  placeholder={ t( 'bookingFunnel.funkReanovo.emailPopup.emailPlaceholder' ) }
                                  isInvalid={ errors[props.name] !== undefined }
                                />
                                <Form.Control.Feedback type="invalid">
                                  { errors[props.name]?.message }
                                </Form.Control.Feedback>
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <Controller
                            name="confirmationEmailData"
                            control={ control }
                            defaultValue={ initialData.confirmationEmailData }
                            render={ ( props ) => (
                              <FormGroup as={ Row } controlId={ props.name }>
                                <Col className="col-auto d-flex">
                                  <Form.Control
                                    { ...props }
                                    className="form-check-input m-0 wd-24"
                                    type="checkbox"
                                    isInvalid={ errors[props.name] !== undefined }
                                    onChange={
                                      ( e: React.ChangeEvent<HTMLInputElement> ) => props.onChange( e.target.checked )
                                    }
                                    checked={ props.value }
                                    onBlur={ props.onBlur }
                                  />
                                </Col>
                                <Form.Label className="checkbox-label">
                                  { saveForLaterSettings && saveForLaterSettings.confirmationEmailLabel
                                    ? (
                                      <div
                                        className="d-inline-block"
                                        dangerouslySetInnerHTML={
                                          { __html: `${saveForLaterSettings.confirmationEmailLabel}` }
                                        }
                                      >
                                      </div>
                                    ) : t( 'bookingFunnel.funkReanovo.emailPopup.confirmationEmailData' ) }
                                </Form.Label>
                                { errors[props.name] !== undefined && (
                                  <div className="col-sm-12 mt-3 d-inline-flex">
                                    <span className="form-check-input-error">
                                      { t( 'bookingFunnel.summary.checkBoxErr' ) }
                                    </span>
                                  </div>
                                ) }
                              </FormGroup>
                            ) }
                          />
                        </Col>
                        <Col sm={ 12 } className="mb-2">
                          <p>{ t( 'bookingFunnel.funkReanovo.required' ) }</p>
                        </Col>
                      </Row>
                      <Row className="mt-4 mx-0 d-flex justify-content-center">
                        <Button
                          id={ `${currentStep}_send_data` }
                          type="submit"
                          variant="primary"
                          disabled={ formState.isSubmitting }
                          className="mr-0 mb-0 bg-btn-primary"
                        >
                          { t( 'bookingFunnel.funkReanovo.emailPopup.btnSend' ) }
                          <img src={ markEmailIcon } className="ml-4"
                            alt={ t( 'bookingFunnel.funkReanovo.emailPopup.btnSend' ) }
                          />
                        </Button>
                      </Row>
                    </Form>
                  </div>
                </Fragment>
              ) }
            </Modal.Body>
          </Modal.Dialog>
        </div>
      </div>
    </Fragment>
  );
};
