import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDatePolicy, renderFieldLabel } from '../../core-hooks';
import { apiDateTimeFormat } from 'App/ui-utils';
import { DatePickerComponent } from './DatePickerComponent';
import { ContainerFieldItem, IBFProductVariable } from 'Services/widgets/interfaces';
import { blankLink } from 'config';
import {
  checkedUnderwriting,
  formatDefaultDate,
  formatInputDate,
  isRequiredField,
} from '../../../booking-funnel/booking-funnel-hooks';
import { isObject } from '../../../bf-hooks';
import { JsonObject } from '@cover42/protobuf-util';
import { useCoreActions } from '../../DynamicCore';
import { useAppLogger } from 'Services/logger';

export interface BirthDateFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: Boolean;
  fromDate?: string;
  toDate: string;
  stepItem?: ContainerFieldItem;
}

export const BirthDateField: React.FC<BirthDateFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  fromDate,
  toDate,
  stepItem,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const logger = useAppLogger();
  const actions = useCoreActions();
  const dayPickerRef = React.useRef<HTMLDivElement>( null );
  const popperRef = React.useRef<HTMLDivElement>( null );
  const fieldName = isShortName ?
    variable.name : `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const [ isOpenDatePicker, setOpenDatePicker ] = React.useState<boolean>( false );
  const { errors, setValue } = useFormContext();

  const closePopper = React.useCallback ( ( ): void => {
    setOpenDatePicker( false );
    dayPickerRef?.current?.focus();
  }, [] );

  const openDatePicker = React.useCallback ( ( ): void => {
    setOpenDatePicker( !isOpenDatePicker );
  }, [ isOpenDatePicker ] );

  const handleChangeBirthDate = ( date ) => {
    const valueDate: dayjs.Dayjs = dayjs( date );

    setDayDate( valueDate.format( 'DD' ) );
    setMonthDate( valueDate.format( 'MM' ) );
    setYearDate( valueDate.format( 'YYYY' ) );

    const savedDate = valueDate.format( apiDateTimeFormat );

    setValue( fieldName, savedDate, { shouldValidate: true } );
    setSelected( new Date( savedDate ) );

    openDatePicker();

    const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
    actions.recalculationPremium( isCheckedUnderwriting );
  };

  const getFromDate = React.useCallback( ( ): string => {
    if ( fromDate ) {
      return fromDate;
    }

    const defaultDate = dayjs( new Date() ).format();

    if ( isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return defaultDate;
  }, [ fromDate, variable.defaultValue ] );

  const getDefaultValue = React.useCallback( ( valField: string ): string => {
    if ( valField ) {
      const fromDateVal = dayjs( getFromDate(), formatDefaultDate, false );
      const toDateVal = dayjs( toDate, formatDefaultDate, false );
      const errMessage = t( 'base:forms.messages.dateError', {
        minDate: fromDateVal.format( formatInputDate ),
        maxDate: toDateVal.format( formatInputDate ),
      } );
      const formatDefaultVal = dayjs( valField ).format( formatInputDate );

      if ( fromDateVal.isAfter( valField, 'date' ) ) {
        logger.error( `${errMessage} Default date: ${formatDefaultVal}` );

        return fromDateVal.format();
      }

      if ( toDateVal.isBefore( valField, 'date' ) ) {
        logger.error( `${errMessage} Default date: ${formatDefaultVal}` );

        return toDateVal.format();
      }

      return valField;
    }

    return '';
  }, [ getFromDate, logger, t, toDate ] );

  const dateField = getDefaultValue( valueField );
  const fullDate = dateField ? dayjs( dateField, formatDatePolicy ) : null;

  const [ dayDate, setDayDate ] = React.useState<string>( fullDate ? fullDate.format( 'DD' ) : '' );
  const [ monthDate, setMonthDate ] = React.useState<string>( fullDate ? fullDate.format( 'MM' ) : '' );
  const [ yearDate, setYearDate ] = React.useState<string>( fullDate ? fullDate.format( 'YYYY' ) : '' );
  const [ selected, setSelected ] = React.useState<Date>(
    fullDate ? new Date( fullDate.format( formatDatePolicy ) ) : new Date( toDate ),
  );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        rules={ {
          required: isRequiredField( variable, stepItem ),
        } }
        defaultValue={ dateField }
        render={ ( props ) => (
          <FormGroup className={ `${stepItem?.classNameBox } dynamic-birthdate-box date-picker-field${errors[props.name]
            ? ' dynamic-date-error' : ''}` }
          controlId={ props.name }
          >
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Row
              className={
                `date-container select-container${!props.value ? ' form-select-empty' : '' }`
              }
            >
              <div
                className="col-md-12 input-group dynamic-input-group"
                ref={ popperRef }
                onClick={ openDatePicker }
              >
                <div className="dynamic-icon-select">
                  <span className="input-group-text"><i className="feather icon-search" /></span>
                </div>
                <Form.Control
                  name="day"
                  min="1"
                  max="31"
                  className="dynamic-day"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'TT' }
                  value={ dayDate }
                />
                <Form.Control
                  name="month"
                  min="1"
                  max="12"
                  className="dynamic-month"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'MM' }
                  value={ monthDate }
                />
                <Form.Control
                  name="year"
                  className="dynamic-year"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'jjjj' }
                  value={ yearDate }
                />
              </div>
              { isOpenDatePicker && (
                <DatePickerComponent
                  popperRef={ popperRef }
                  dayPickerRef={ dayPickerRef }
                  currentValue={ selected }
                  isOpenDatePicker={ isOpenDatePicker }
                  fromDate={ getFromDate() }
                  toDate={ toDate }
                  closePopper={ closePopper }
                  onChangeDate={ handleChangeBirthDate }
                />
              ) }
            </Row>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <span className="dynamic-birthdate-info mt-2">
              { t( 'bookingFunnel.personalDataFunk.birthDateInfo' ) }
            </span>
            <Form.Control.Feedback type="invalid" className="dynamic-error-message">
              <Fragment>
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: variable.label } ) }
              </Fragment>
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
