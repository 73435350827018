import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, IBFProductVariable } from 'Services/widgets/interfaces';
import { formatDatePolicy, renderFieldLabel } from '../../core-hooks';
import { blankLink } from 'config';
import { apiDateTimeFormat, defaultDateFormat } from 'App/ui-utils';
import { DatePickerComponent } from './DatePickerComponent';
import { checkedUnderwriting, isRequiredField } from '../../../booking-funnel/booking-funnel-hooks';
import { isObject } from '../../../bf-hooks';
import { JsonObject } from '@cover42/protobuf-util';
import { useCoreActions } from '../../DynamicCore';

export interface DateFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: Boolean;
  fromDate?: string;
  toDate: string;
  stepItem?: ContainerFieldItem;
  nameFieldToCheck?: string;
}

export const DateField: React.FC<DateFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  fromDate,
  toDate,
  stepItem,
  nameFieldToCheck,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const dayPickerRef = React.useRef<HTMLDivElement>( null );
  const popperRef = React.useRef<HTMLDivElement>( null );
  const fieldName = isShortName ?
    variable.name : `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const dateField = valueField ? valueField : '';
  const [ isOpenDatePicker, setOpenDatePicker ] = React.useState<boolean>( false );
  const { errors, setValue, getValues } = useFormContext();

  const fullDate = dateField ? dayjs( dateField, formatDatePolicy ) : null;

  const [ dayDate, setDayDate ] = React.useState<string>( fullDate ? fullDate.format( 'DD' ) : '' );
  const [ monthDate, setMonthDate ] = React.useState<string>( fullDate ? fullDate.format( 'MM' ) : '' );
  const [ yearDate, setYearDate ] = React.useState<string>( fullDate ? fullDate.format( 'YYYY' ) : '' );
  const [ selected, setSelected ] = React.useState<Date | undefined>(
    fullDate ? new Date( fullDate.format( formatDatePolicy ) ) : undefined,
  );

  const closePopper = React.useCallback ( ( ): void => {
    setOpenDatePicker( false );
    dayPickerRef?.current?.focus();
  }, [] );

  const openDatePicker = React.useCallback ( ( ): void => {
    setOpenDatePicker( !isOpenDatePicker );
  }, [ isOpenDatePicker ] );

  const handleChangeDate = ( selectedDate ): void => {
    const valueDate: dayjs.Dayjs = dayjs( selectedDate );

    setDayDate( valueDate.format( 'DD' ) );
    setMonthDate( valueDate.format( 'MM' ) );
    setYearDate( valueDate.format( 'YYYY' ) );

    const savedDate = valueDate.format( apiDateTimeFormat );

    setValue( fieldName, savedDate, { shouldValidate: true } );
    setSelected( new Date( savedDate ) );

    openDatePicker();

    const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
    actions.recalculationPremium( isCheckedUnderwriting );
  };

  const getFromDate = React.useCallback( ( ): string => {
    if ( isObject( variable.minValue ) ) {
      const objectDate = variable.minValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    if ( fromDate ) {
      return fromDate;
    }

    if ( isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    const defaultDate = dayjs( new Date() ).format();

    return defaultDate;
  }, [ fromDate, variable.defaultValue, variable.minValue ] );

  const getToDate = React.useCallback( ( ): string => {
    if ( isObject( variable.maxValue ) ) {
      const objectDate = variable.maxValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return toDate;
  }, [ toDate, variable.maxValue ] );

  const isValidDateField = ( dateVal: string ): boolean => {
    const currentVal = dayjs( dateVal, formatDatePolicy );
    const fromDateVal = dayjs( getFromDate(), formatDatePolicy );
    const toDateVal = dayjs( getToDate(), formatDatePolicy );

    if ( fromDateVal.isAfter( currentVal ) ) {
      return false;
    }

    if ( toDateVal.isBefore( currentVal ) ) {
      return false;
    }

    if ( !nameFieldToCheck ) {
      return true;
    }

    const dateToCheck: string = getValues( nameFieldToCheck );

    if ( !dateToCheck ) {
      return true;
    }

    const currentDate = dayjs( dateToCheck, formatDatePolicy );

    if ( currentVal.isAfter( currentDate ) ) {
      return false;
    }

    if ( currentVal.isSame( currentDate ) ) {
      return false;
    }

    return true;
  };

  const addClassChecked = ( errorField: string | undefined, valField: string ): string => {
    if ( errorField === undefined && valField ) {
      return 'dynamic-checked';
    }

    return errorField ? 'dynamic-date-error' : '';
  };

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if ( !isValidDateField( value ) ) {
              const errMessage = t( 'base:forms.messages.dateError', {
                minDate: dayjs( getFromDate() ).format( defaultDateFormat ),
                maxDate: dayjs( getToDate() ).format( defaultDateFormat ),
              },
              );
              return errMessage;
            }
          },
        } }
        defaultValue={ dateField }
        render={ ( props ) => (
          <FormGroup
            controlId={ props.name }
            className={ `${stepItem?.classNameBox!} 
            dynamic-date-box date-picker-field ${ addClassChecked( errors[props.name], props.value ) }` }
          >
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Row
              className={ `date-container select-container${!props.value ? ' form-select-empty' : '' }` }
            >
              <div
                className="col-md-12 input-group dynamic-input-group"
                ref={ popperRef }
                onClick={ openDatePicker }
              >
                <div className="dynamic-icon-select">
                  <span className="input-group-text"><i className="feather icon-search" /></span>
                </div>
                <Form.Control
                  name="day"
                  min="1"
                  max="31"
                  className="dynamic-day"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'TT' }
                  value={ dayDate }
                />
                <Form.Control
                  name="month"
                  min="1"
                  max="12"
                  className="dynamic-month"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'MM' }
                  value={ monthDate }
                />
                <Form.Control
                  name="year"
                  className="dynamic-year"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'jjjj' }
                  value={ yearDate }
                />
                <span className="icon-checked"></span>
              </div>
              { isOpenDatePicker && (
                <DatePickerComponent
                  popperRef={ popperRef }
                  dayPickerRef={ dayPickerRef }
                  currentValue={ selected }
                  isOpenDatePicker={ isOpenDatePicker }
                  fromDate={ getFromDate() }
                  toDate={ getToDate() }
                  closePopper={ closePopper }
                  onChangeDate={ handleChangeDate }
                />
              ) }
            </Row>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid" className="dynamic-error-message">
              { errors[props.name]?.message ? (
                <span
                  className="d-inline-block"
                  dangerouslySetInnerHTML={ { __html: errors[props.name]?.message } }
                >
                </span>
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
