import React, { Fragment } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ILeadData,
  IPolicyObject,
  IProductDataForInvoice,
  IProductInvoice,
  IProductInvoiceData,
  PremiumSettings,
  SubStepItem,
} from 'Services/widgets/interfaces';
import { PremiumFormulaVisibilities } from '@cover42/ts-contracts';
import {
  prepareDataForTariff,
  keyMultiInsuredObjects,
  generateVariables,
  generateRules,
  preparePayloadRule,
  preparePolicyPayload,
  calculateBillingInterval,
  premiumCalculationYearly,
} from '../../core-hooks';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { isEmpty, cloneDeep } from 'lodash';
import { evalFunction } from '../../../booking-funnel/functions';
import { useFormContext } from 'react-hook-form';
import { useAppLogger } from 'Services/logger';
import { UnderwritingResult } from 'Services/widgets/enums';
import { RecalculationTariff, useCoreActions } from '../../DynamicCore';
import { renderPrice, useLocale } from '../../../booking-funnel/booking-funnel-hooks';
import { oneTimePayment } from 'App/components/widgets/bf-hooks';
import { ViewLodingTariffResult } from 'App/components/widgets/booking-funnel/ui/ViewLodingTariffResult';
import { JSONPremiumShowsType } from 'App/components/widgets/booking-funnel/enum';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { PolicyEditData } from '../../PolicyEdit';

const CustomTariffInfoComponent = React.lazy( () =>
  import( './CustomTariffInfo' )
    .then( ( { CustomTariffInfo } ) => ( { default: CustomTariffInfo } ) ),
);

export interface CoreTariffInfoProps {
  lead: ILeadData;
  productCode: string;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isIgnoreOnRecalculation: boolean;
  recalculationTariff: RecalculationTariff;
  subSteps?: SubStepItem[];
  keyStep: string;
  policyStepNames?: string[];
  settings: PremiumSettings;
  showSavePopup?: ( isShow: boolean ) => void;
  showSaveForLaterButton?: boolean;
  showWelcomePopup?: ( isShow: boolean ) => void;
  isShowSavedInfo?: boolean;
}

export const keyCoreInvoiceResult = 'quote';

export interface PremiumCalculationItem {
  premiumData: IProductInvoiceData | null;
  error: string | null;
}

const defaultResult: PremiumCalculationItem = {
  premiumData: null,
  error: null,
};

export const CoreTariffInfo: React.FC<CoreTariffInfoProps> = ( props ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert, hideAlert } = useAppAlertService();
  const locale = useLocale();
  const service = useWidgetService();
  const logger = useAppLogger();
  const actions = useCoreActions();
  const loading = <div className="bf-loading dynamic-loading col-auto d-flex py-4"><LoadingSpinner /></div>;
  const {
    lead, productCode, productData, isIgnoreOnRecalculation, recalculationTariff,
    subSteps, keyStep, policyStepNames, settings,
    showSavePopup, showSaveForLaterButton, showWelcomePopup,
  } = props;

  const { isCheckedUnderwriting, recalculationTrigger } = recalculationTariff;
  const { formState } = useFormContext();

  const defaultTitle = settings.isCustomDesign ? t( 'bookingFunnel.dynamicCore.tariffInfo' ) : '';
  const isFirstRender = React.useRef<boolean | null>( null );
  const previousRecalculationTrigger = React.useRef<number | null>( null );
  const resultInvoiceData: IProductInvoiceData | null = lead[keyCoreInvoiceResult] || null;
  const initTariffResult = resultInvoiceData ? { premiumData: resultInvoiceData, error: null } : defaultResult;

  const [ tariffResultData, setTariffResultData ] = React.useState<PremiumCalculationItem>( initTariffResult );
  const [ statusInfo, setStatusInfo ] = React.useState<string>( '' );
  const [ titleMessage, setTitleMessage ] = React.useState<string>( defaultTitle );
  const triggerTime = React.useRef<Dayjs>( dayjs() );
  const previousIsLoadingPremiumData = React.useRef<boolean>( false );

  const {
    showZeroAmount,
    showItems,
    premiumShowsType,
    showYearlyCalculation,
    showNegativeAmount,
    ignoreRequiredFields,
  } = settings;

  const rulesInformer = React.useCallback( ( leadStore: ILeadData ): void => {
    if ( subSteps ) {
      const rules = generateRules( subSteps );

      setStatusInfo( '' );
      setTitleMessage( defaultTitle );

      if ( rules ) {
        let isSetInfo = false;
        actions.setRejected( false );
        rules.forEach( ( item ) => {
          const rejectionData = evalFunction( leadStore || [], item );

          if ( rejectionData ) {
            if ( isSetInfo ) {
              return;
            }

            if ( rejectionData['rejectionMessage'] ) {
              actions.setRejected( true );
              return;
            }

            if ( rejectionData['statusMessage'] ) {
              setStatusInfo( rejectionData['statusMessage'] );

              isSetInfo = true;
            }

            if ( rejectionData['titleMessage'] ) {
              setTitleMessage( rejectionData['titleMessage'] );

              isSetInfo = true;
            }
          }
        } );
      }
    }
  }, [ actions, defaultTitle, subSteps ] );

  const checkedRules = React.useCallback( async ( leadStore: ILeadData, isReset?: boolean ): Promise<void> => {
    try {
      const invoiceResult = leadStore[keyCoreInvoiceResult];

      if ( ( !invoiceResult && !isReset ) || ( !isCheckedUnderwriting && !isReset ) ) {
        return;
      }

      const workflowSlug = productData.stepsConfig && productData.stepsConfig.workflowSlug
        ? productData.stepsConfig.workflowSlug : null;

      if ( !workflowSlug ) {
        rulesInformer( leadStore );

        return;
      }

      const workflow = await service.getWorkflow( workflowSlug );
      if ( workflow && workflow.code ) {
        const preparePayload = preparePayloadRule( leadStore, productData, policyStepNames! );
        const response = await service.getUnderwritingResult( workflow.code, preparePayload );

        const { result, statusMessage, errorMessage } = response.underwritingResult;

        if ( result && result === UnderwritingResult.Warning ) {
          setStatusInfo( statusMessage );
          setTitleMessage( errorMessage );
        }

        if ( result && result === UnderwritingResult.Passed ) {
          setStatusInfo( '' );
          setTitleMessage( defaultTitle );
        }
      } else {
        rulesInformer( leadStore );
      }
    } catch( error ) {
      logger.error( error );
    }
  }, [ defaultTitle, isCheckedUnderwriting, logger, policyStepNames, productData, rulesInformer, service ] );

  const onLoadData = React.useCallback( ( isLoad: boolean ): void => {
    if ( actions.setLoadingPremiumData ) {
      actions.setLoadingPremiumData( isLoad );
      previousIsLoadingPremiumData.current = isLoad;
    }
  }, [ actions ] );

  const onReloadLead = React.useCallback( ( ): void => {
    actions.reloadLead();
  }, [ actions ] );

  React.useEffect( () => {
    let isMounted = true;

    if( !showItems ) {
      return;
    }

    const loadTariff = async () => {
      try {
        if ( isMounted && !previousIsLoadingPremiumData.current ) {
          hideAlert();

          const leadData: ILeadData = await service.getLead();
          const invoiceResult = leadData[keyCoreInvoiceResult] as IProductInvoiceData;

          checkedRules( leadData );

          let isValidFormState = formState.isValid;

          if ( ignoreRequiredFields ) {
            isValidFormState = true;
          }

          if ( invoiceResult && isValidFormState && isFirstRender.current === null ) {
            isFirstRender.current = true;
            setTariffResultData( {
              premiumData: invoiceResult,
              error: null,
            } );

            return;
          }

          if ( !isValidFormState ) {
            return;
          }

          if ( ( !isValidFormState && invoiceResult ) ||
          previousRecalculationTrigger.current === recalculationTrigger ) {
            return;
          }

          if ( !isEmpty( invoiceResult ) && invoiceResult['grossAmount'] > 0 ) {
            setTariffResultData( {
              premiumData: invoiceResult,
              error: null,
            } );
            return;
          }

          setTariffResultData( defaultResult );

          const tariffsData = preparePolicyPayload( leadData, policyStepNames!, productData.stepsConfig );

          if ( isIgnoreOnRecalculation && invoiceResult ) {
            setTariffResultData( defaultResult );
            return;
          }

          if ( isEmpty( tariffsData ) ) {
            setTariffResultData( defaultResult );
            return;
          }

          let tariffDataForMultiInsured: IPolicyObject[] = [];
          const multiInsuredObjects = leadData[keyMultiInsuredObjects];

          if ( multiInsuredObjects ) {
            const variables = generateVariables( multiInsuredObjects );

            tariffDataForMultiInsured = prepareDataForTariff(
              tariffsData,
              variables,
              multiInsuredObjects,
              false,
            );
          }

          const tariffData = prepareDataForTariff(
            tariffsData,
            productData.variables,
            productData.insuredObjects,
            false,
          );

          const policyObjects: IProductDataForInvoice = {
            policyObjects: [ ...tariffData, ...tariffDataForMultiInsured ],
          };

          onLoadData( true );

          const resultInvoice = await service.getProductInvoice( productCode, policyObjects );

          if ( resultInvoice ) {
            await service.savedInfo( keyCoreInvoiceResult, resultInvoice );

            setTariffResultData( {
              premiumData: resultInvoice,
              error: null,
            } );

            previousRecalculationTrigger.current = recalculationTrigger;

            checkedRules( { ...leadData, [keyCoreInvoiceResult]: resultInvoice }, true );
            onReloadLead();
          } else {
            setTariffResultData( {
              premiumData: null,
              error: t ( 'base:messages.noData' ),
            } );
          }

          onLoadData( false );
        }
      } catch( e ) {
        setTariffResultData( defaultResult );

        if ( previousIsLoadingPremiumData.current ) {
          onLoadData( false );
        }

        if ( e instanceof Error ) {
          const defaultErrorMessage = settings.defaultErrorMessage ?
            settings.defaultErrorMessage : t( 'base:messages.errorPremiumCalculation' );

          if( !settings.isCustomDesign && settings.defaultErrorMessage ) {
            setTariffResultData( {
              premiumData: null,
              error: defaultErrorMessage,
            } );
          } else {
            showAlert( {
              message: defaultErrorMessage,
              type: 'danger',
            } );
          }
        } else {
          throw e;
        }
      }
    };

    setTimeout( () => {
      loadTariff();
    }, 500 );

    return () => {
      isFirstRender.current = true;
      isMounted = false;
    };
  }, [ service, t, showAlert, productData.variables, productData.insuredObjects, productCode, recalculationTrigger,
    checkedRules, isIgnoreOnRecalculation, keyStep, policyStepNames, showItems, hideAlert, productData.stepsConfig,
    settings.defaultErrorMessage, settings.isCustomDesign, formState.isValid, ignoreRequiredFields, onLoadData,
    onReloadLead ] );

  React.useEffect( () => {
    if ( settings.isCustomDesign ) {
      return;
    }

    setTariffResultData( tariffResultData );

    const interval = setInterval( () => {
      const currentTime = dayjs();
      const diffSeconds = currentTime.diff( triggerTime.current, 'seconds' );

      if ( tariffResultData.premiumData === null && diffSeconds >= 20 ) {
        setTariffResultData( {
          premiumData: null,
          error: `<p class="text-center">${t ( 'base:messages.noData' )}</p>`,
        } );
        triggerTime.current = currentTime;
      }

    }, 20000 );

    return () => {
      clearInterval( interval );
    };
  }, [ productCode, settings.isCustomDesign, t, tariffResultData ] );

  const renderPriceYearly = React.useCallback ( ( priceItem: number, currency: string ): string => {
    const grossAmount = premiumCalculationYearly( lead, productData.insuredObjects, priceItem );

    return renderPrice( grossAmount, productCode, currency, locale );
  }, [ lead, locale, productCode, productData.insuredObjects ] );

  const filterInvoiceItems = React.useCallback ( ( invoiceItems: IProductInvoice[] ): IProductInvoice[] => {
    let cloneInvoiceItems = cloneDeep( invoiceItems );

    if ( !showZeroAmount ) {
      cloneInvoiceItems = cloneInvoiceItems.filter( ( f ) => f.grossAmount !== 0 );
    }

    if ( !showNegativeAmount ) {
      cloneInvoiceItems = cloneInvoiceItems.filter( ( f ) => f.grossAmount >= 0 );
    }

    return cloneInvoiceItems.filter( ( item ) => item.visibility === PremiumFormulaVisibilities.Public );
  }, [ showNegativeAmount, showZeroAmount ] );

  if( !showItems ) {
    return null;
  }

  if ( settings.isCustomDesign ) {
    return (
      <React.Suspense fallback={ loading }>
        <CustomTariffInfoComponent
          lead={ lead }
          premiumData={ tariffResultData?.premiumData || null }
          isShowSavedInfo={ false }
          showSavePopup={ showSavePopup }
          showSaveForLaterButton={ showSaveForLaterButton }
          showWelcomePopup={ showWelcomePopup }
          keyStep={ keyStep }
          className={ '' }
          statusInfo={ statusInfo }
          titleMessage={ titleMessage }
        />
      </React.Suspense>
    );
  }

  if ( tariffResultData?.premiumData === null && tariffResultData?.error === null ) {
    return <ViewLodingTariffResult />;
  }

  const { premiumData, error } = tariffResultData;

  if ( error !== null ) {
    return (
      <Card id="view-tariff" className="sticky-top z-idx-auto">
        <Card.Body>
          <div dangerouslySetInnerHTML={ { __html: `${error}` } }></div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Fragment>
      { premiumData !== null && (
        <Card id="view-tariff" className="sticky-top z-idx-auto">
          <Card.Body>
            { statusInfo && (
              <span className="tariff-head">{ statusInfo }</span>
            ) }
            { premiumShowsType === undefined ? (
              <Fragment>
                { premiumData.invoiceItems.length > 1 && (
                  <Fragment>
                    <Row className="mb-3 bf-tarif-info total-box">
                      <Col sm={ 12 } className="text-center mb-2">
                        <h5 className="mb-0">{ t( 'base:grossAmount' ) }</h5>
                      </Col>
                      <Col sm={ 12 } className="text-center">
                        <p className="mb-0 white-space-nowrap">
                          { `${renderPrice( premiumData.grossAmount, productCode, premiumData.currency, locale )}` }
                          { premiumData.invoiceItems[0].unit !== oneTimePayment && (
                            <sup>/ { t( `bookingFunnel.timeOptions.${
                              calculateBillingInterval( premiumData.invoiceItems[0] )
                            }` ) }
                            </sup>
                          ) }
                        </p>
                      </Col>
                    </Row>
                    { showYearlyCalculation && (
                      <Row className="mb-3 bf-tarif-info total-box">
                        <Col sm={ 12 } className="text-center">
                          <p className="mb-0 white-space-nowrap">
                            { renderPriceYearly( premiumData.grossAmount, premiumData.currency ) }
                            <sup>/ { t( 'bookingFunnel.timeOptions.year' ) }</sup>
                          </p>
                        </Col>
                      </Row>
                    ) }
                  </Fragment>
                ) }
                { filterInvoiceItems( premiumData.invoiceItems )
                  .sort( ( a, b ) => ( a.premiumFormulaId < b.premiumFormulaId ) ? -1 : 1 )
                  .map( ( item, idx ) => (
                    <Fragment key={ idx }>
                      <Row className="mb-3 bf-tarif-info">
                        <Col sm={ 12 } className="text-center mb-2">
                          <h5 className="mb-0">{ item.name }</h5>
                        </Col>
                        <Col sm={ 12 } className="text-center">
                          <p className="mb-0 white-space-nowrap">
                            { `${renderPrice( item.grossAmount, productCode, premiumData.currency, locale )}` }
                            { item.unit !== oneTimePayment && (
                              <sup>/ { t( `bookingFunnel.timeOptions.${calculateBillingInterval( item )}` ) }</sup>
                            ) }
                          </p>
                        </Col>
                      </Row>
                      { showYearlyCalculation && (
                        <Row className="mb-3 bf-tarif-info">
                          <Col sm={ 12 } className="text-center mb-2">
                            <h5 className="mb-0">{ item.name }</h5>
                          </Col>
                          <Col sm={ 12 } className="text-center">
                            <p className="mb-0 white-space-nowrap">
                              { renderPriceYearly( item.grossAmount, premiumData.currency ) }
                              <sup>/ { t( 'bookingFunnel.timeOptions.year' ) }</sup>
                            </p>
                          </Col>
                        </Row>
                      ) }
                    </Fragment>
                  ) ) }
              </Fragment>
            ) : (
              <Fragment>
                { ( premiumShowsType === JSONPremiumShowsType.ShowAllPremium ||
                premiumShowsType === JSONPremiumShowsType.ShowOnlyTotalPremium )
                && (
                  <Fragment>
                    <Row className="mb-3 bf-tarif-info total-box">
                      <Col sm={ 12 } className="text-center mb-2">
                        <h5 className="mb-0">{ t( 'base:grossAmount' ) }</h5>
                      </Col>
                      <Col sm={ 12 } className="text-center">
                        <p className="mb-0 white-space-nowrap">
                          { `${renderPrice( premiumData.grossAmount, productCode, premiumData.currency, locale )}` }
                          { premiumData.invoiceItems[0].unit !== oneTimePayment && (
                            <sup>/ {
                              t( `bookingFunnel.timeOptions.${
                                calculateBillingInterval( premiumData.invoiceItems[0] )
                              }` )
                            }
                            </sup>
                          ) }
                        </p>
                      </Col>
                    </Row>
                    { showYearlyCalculation && (
                      <Row className="mb-3 bf-tarif-info total-box">
                        <Col sm={ 12 } className="text-center">
                          <p className="mb-0 white-space-nowrap">
                            { renderPriceYearly( premiumData.grossAmount, premiumData.currency ) }
                            <sup>/ { t( 'bookingFunnel.timeOptions.year' ) }</sup>
                          </p>
                        </Col>
                      </Row>
                    ) }
                  </Fragment>
                ) }
                { ( premiumShowsType === JSONPremiumShowsType.ShowAllPremium ||
                  premiumShowsType === JSONPremiumShowsType.ShowOnlyDetailsPremium ) &&
                  filterInvoiceItems( premiumData.invoiceItems )
                    .sort( ( a, b ) => ( a.premiumFormulaId < b.premiumFormulaId ) ? -1 : 1 )
                    .map( ( item, idx ) => (
                      <Fragment key={ idx } >
                        <Row className="mb-3 bf-tarif-info">
                          <Col sm={ 12 } className="text-center mb-2">
                            <h5 className="mb-0">{ item.name }</h5>
                          </Col>
                          <Col sm={ 12 } className="text-center">
                            <p className="mb-0 white-space-nowrap">
                              { `${renderPrice( item.grossAmount, productCode, premiumData.currency, locale )}` }
                              { item.unit !== oneTimePayment && (
                                <sup>/ { t( `bookingFunnel.timeOptions.${calculateBillingInterval( item )}` ) }</sup>
                              ) }
                            </p>
                          </Col>
                        </Row>
                        { showYearlyCalculation && (
                          <Row className="mb-3 bf-tarif-info">
                            <Col sm={ 12 } className="text-center mb-2">
                              <h5 className="mb-0">{ item.name }</h5>
                            </Col>
                            <Col sm={ 12 } className="text-center">
                              <p className="mb-0 white-space-nowrap">
                                { renderPriceYearly( item.grossAmount, premiumData.currency ) }
                                <sup>/ { t( 'bookingFunnel.timeOptions.year' ) }</sup>
                              </p>
                            </Col>
                          </Row>
                        ) }
                      </Fragment>
                    ) ) }
              </Fragment>
            ) }
            { titleMessage }
          </Card.Body>
        </Card>
      ) }
    </Fragment>
  );
};
