import React from 'react';
import useScreenType from 'react-screentype-hook';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import * as qs from 'query-string';
import HashTabs from 'App/components/utils/HashTabs';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiErrorResponse, ILeadData, NavigatorItem, StepItem } from 'Services/widgets/interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { setCustomStyles } from 'App/ui-utils';
import {
  filterDocumentsByProductSlug,
  generateMultiObjects,
  generatePolicyObject,
  generateSteps,
  generateVariables,
  keyMultiInsuredObjects,
} from './core-hooks';
import { IDataFactorsAndVariables } from '../booking-funnel/BookingFunnel';
import { isEmpty, cloneDeep } from 'lodash';
import { SuccessPage } from './ui-components/SuccessPage';
import { InvalidLinkPage } from './ui-components/InvalidLinkPage';
import { LoadPage } from './ui-components/LoadPage';
import { keyInvoiceResult } from './ui-components/TariffInfo';
import { InvoiceResultCore, PolicyFieldItem, StepInfoItem } from './interfaces';
import { JSONComponentType } from '../booking-funnel/enum';
import { Configuration } from 'App/widget/types';
import { NavigatorType } from 'Services/widgets/enums';
import { JsonValue } from '@cover42/protobuf-util';
import { getParamUrlByName } from '../bf-hooks';

const PolicyStepComponent = React.lazy( () =>
  import( './steps/PolicyStep' )
    .then( ( { PolicyStep } ) => ( { default: PolicyStep } ) ),
);
const PersonalDataStepComponent = React.lazy( () =>
  import( './steps/PersonalDataStep' )
    .then( ( { PersonalDataStep } ) => ( { default: PersonalDataStep } ) ),
);
const SummaryStepComponent = React.lazy( () =>
  import( './steps/SummaryStep' )
    .then( ( { SummaryStep } ) => ( { default: SummaryStep } ) ),
);

export enum ActiveStepCore {
  PersonalData = 'personal-data',
  Summary = 'summary',
};

export enum ActiveSubStepCore {
  SummarySubStep1 = 'summarySubStep1',
  SummarySubStep2 = 'summarySubStep2',
};

export interface CoreActions {
  reload: ( updatedProduct?: IDataFactorsAndVariables ) => void;
  goToStep: ( step: string, isReloadLead: boolean ) => Promise<void>;
  goToPersonalStep: ( step: ActiveStepCore | string, subStep: string | null, isReloadLead: boolean ) => Promise<void>;
  showLoadPage: ( isShow: boolean ) => void;
  goToSummaryStep?: ( stepName: ActiveStepCore, subStep: ActiveSubStepCore, isReloadLead: boolean ) => Promise<void>;
  onSubmit: ( appNumber?: string ) => void;
  goToTopPage: () => void;
  reloadLead: () => Promise<void>;
  goToEditStep: ( step: string, groupName: string, isReloadLead: boolean ) => Promise<void>;
  recalculationPremium: ( isCheckedUnderwriting: boolean ) => void;
  setRejected: ( rejected: boolean ) => void;
  setValidForm: ( isValid: boolean ) => void;
  getStepsInfo: () => StepInfoItem[] | null;
  navigatorItem: () => NavigatorItem;
  isRejected: () => boolean;
  isValidForm: () => boolean;
  goToEditPersonalStep: (
    step: ActiveStepCore | string, subStep: string | null, groupName: string, isReloadLead: boolean
  ) => Promise<void>;
  updatedStoreData?: ( fields: PolicyFieldItem[], fieldValue: JsonValue ) => Promise<void>;
  setLoadingPremiumData?: ( loaded: boolean ) => void;
  isLoadingPremiumData?: ( ) => boolean;
}

export const DynamicCoreActionsContext: React.Context<CoreActions> =
React.createContext( undefined as any );

export const useCoreActions = (): CoreActions => {
  return React.useContext( DynamicCoreActionsContext );
};

export interface RecalculationTariff {
  recalculationTrigger: number;
  isCheckedUnderwriting: boolean;
}

export interface DynamicCoreProps {
  productCode: string;
  productName: string;
  tenantSlug: string;
  configuration?: Configuration;
}

const siteUrlSandbox = '/';

const DynamicCore: React.FC<DynamicCoreProps> = (
  { productCode, productName, tenantSlug, configuration },
) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const screenType = useScreenType();
  const history = useHistory();
  const location = useLocation();
  const service = useWidgetService();

  const { pathname, search } = location;
  const { hideAlert } = useAppAlertService();
  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );
  const [ lead, setLead ] = React.useState<ILeadData | null>( null );
  const [ productData, setProductData ] = React.useState<IDataFactorsAndVariables | null>( null );
  const [ reloadTrigger, setReloadTrigger ] = React.useState<number>( dayjs().valueOf() );
  const [ stepsInfo, setStepsInfo ] = React.useState<StepInfoItem[] | null>( null );
  const [ policyStepNames, setPolicyStepNames ] = React.useState<string[] | null>( null );
  const [ actionPersonalStep, setActionPersonalStep ] = React.useState<string | null>( null );
  const [ recalculationTariff, setRecalculationTariff ] = React.useState<RecalculationTariff>( {
    recalculationTrigger: dayjs().valueOf(),
    isCheckedUnderwriting: true,
  } );
  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const [ isInvalidLink, setInvalidLink ] = React.useState<boolean>( false );
  const [ isShowLoadPage, setIsShowLoadPage ] = React.useState<boolean>( false );
  const [ actionSummaryStep, setActionSummaryStep ] = React.useState<ActiveSubStepCore>(
    ActiveSubStepCore.SummarySubStep1 );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );
  const [ isValidForm, setIsValidForm ] = React.useState<boolean>( true );
  const [ navigator ] = React.useState<NavigatorItem>( { type: NavigatorType.Circles } );

  const mainSiteUrl = siteUrlSandbox;

  const lng = i18n.language;
  if ( lng === 'de' ) {
    dayjs.locale( 'de' );
  } else {
    dayjs.locale( 'en-gb' );
  }

  const getStepHash = React.useCallback( (): string | undefined => {
    const parsed = qs.parse( window.location.hash, { decode: false } );
    return parsed['step'];
  }, [] );

  const getFullUrl = React.useCallback( ( stepName: string, groupName?: string ): string => {
    let fullPath: string = `${pathname}${search}#step=${stepName}`;

    const leadCode = getParamUrlByName( 'leadCode' );
    if ( leadCode ) {
      fullPath = `${fullPath}&leadCode=${leadCode}`;
    }

    if ( groupName ) {
      fullPath = `${fullPath}&containerGroup=${groupName}`;
    }

    return fullPath;
  }, [ pathname, search ] );

  React.useEffect( () => {
    let isMounted = true;
    const loadData = async () => {
      let resultData: IDataFactorsAndVariables | null = null;
      const productInfo = await service.getProductByCode( productCode );
      try {
        const [
          initiateLeadData,
          customSettings,
          factorsData,
          insuredObjectsData,
          documents,
          productDocuments,
          storeLead,
        ] = await Promise.all( [
          service.initiateLead(),
          service.getBFCustomStylesSettings( productCode ),
          service.getProductFactors( productCode, false ),
          service.getInsuredObjects( productCode ),
          service.getStaticDocuments(),
          service.getProductDocuments( productCode ),
          service.getLead(),
        ] );

        let initiateLead = initiateLeadData;
        let storeLeadData = storeLead;

        const leadCode = getParamUrlByName( 'leadCode' );

        if ( leadCode ) {
          const result = await service.getLeadByCode( leadCode );

          if ( result.errorCode === 0 ) {
            initiateLead = {
              ...initiateLead,
              leadCode,
            };

            const { policy, quote } = result.data;

            if ( policy && policy.policyObjects && customSettings.stepsConfig ) {
              const policyObject = generatePolicyObject( policy.policyObjects, insuredObjectsData );

              if ( !isEmpty( policyObject ) ) {
                const multiInsuredObjects = generateMultiObjects( policy.policyObjects, insuredObjectsData );

                await service.savedInfo( keyMultiInsuredObjects, multiInsuredObjects );
              }
            }

            if ( quote && quote.grossAmount && quote.currency ) {
              const resultTariff: InvoiceResultCore = {
                netAmount: quote.netAmount,
                taxAmount: quote.taxAmount,
                grossAmount: quote.grossAmount,
                creditAmount: quote.creditAmount,
                currency: quote.currency,
              };

              await service.savedInfo( keyInvoiceResult, resultTariff );
            }

            await service.savedInfo( 'isEditLead', true );

            storeLeadData = await service.getLead();
          }

          if ( result.errorCode === 1 ) {
            setInvalidLink( true );
          }
        } else {
          const isEditLead = storeLeadData.isEditLead;

          if ( isEditLead ) {
            storeLeadData = await service.resetLead();
          }
        }

        if ( isMounted ) {
          if ( customSettings ) {
            if ( customSettings.styles ) {
              setCustomStyles( 'custom-css', customSettings.styles );
            }
          }

          if ( factorsData.length && insuredObjectsData.length && productInfo ) {
            const variableList = generateVariables( insuredObjectsData );
            const stepsData = customSettings.stepsConfig;
            const steps = generateSteps( stepsData?.steps as StepItem[] );
            const policyNames = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy )
              .map( ( item ) => item.nameStep );

            setPolicyStepNames( policyNames );
            setStepsInfo( steps );

            let staticDocuments = documents ? documents : [];

            if ( staticDocuments && productInfo ) {
              staticDocuments = filterDocumentsByProductSlug( staticDocuments, productInfo.slug );
            }

            resultData = {
              factors: factorsData,
              insuredObjects: insuredObjectsData,
              variables: variableList.sort( ( a, b ) => a.legacyBfOrder! - b.legacyBfOrder! ),
              leadData: initiateLead,
              isCustomLayout: customSettings.styles ? true : false,
              productName,
              productSlug: productInfo.slug,
              isSetupPayment: false,
              staticDocuments,
              productDocuments,
              documentSettings: customSettings.documentSettings,
              stepsConfig: customSettings.stepsConfig,
              configuration: configuration,
            };

            setProductData( resultData );
            setErrorMessage( null );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }

          if ( storeLeadData ) {
            setLead( storeLeadData );
          }
        }
      } catch( e ) {
        const apiError = e as unknown as ApiErrorResponse;
        if ( isMounted ) {
          setErrorMessage(
            `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${apiError.error.data.message}`,
          );
        }
      }

      return resultData;
    };

    const loadProductFactorsWithAllValues = async ( oldProductData: IDataFactorsAndVariables | null ) => {
      if ( !oldProductData ) {
        return;
      }

      try {
        const factorsData = await service.getProductFactors( productCode, true );

        if ( isMounted ) {
          if ( factorsData.length ) {
            setProductData( {
              ...oldProductData,
              factors: factorsData,
            } );
            setErrorMessage( null );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }
        }
      } catch( e ) {
        isMounted = false;
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setErrorMessage(
              `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${e.message}`,
            );
          }
        } else {
          throw e;
        }
      }
    };

    loadData().then(
      ( data: IDataFactorsAndVariables | null ) => { loadProductFactorsWithAllValues( data ); },
    );

    return () => {
      isMounted = false;
    };
  }, [ reloadTrigger, service, productCode, t, productName, getStepHash, configuration ] );

  React.useEffect( () => {
    if ( lead === null ) {
      return;
    }

    const siteHeader = document.querySelector( 'header[data-condition="false"]' );
    const heightHeader = siteHeader ? siteHeader.clientHeight : 0;
    const pricePanel = document.querySelector( '#price-panel' );

    if ( pricePanel ) {
      pricePanel.setAttribute( 'style', `top: ${heightHeader}px` );
    }
  }, [ lead ] );

  const coreActions = React.useMemo<CoreActions>( () => {
    return {
      reload: ( updatedProduct ): void => {
        if ( updatedProduct !== undefined ) {
          setProductData( updatedProduct );
        } else {
          setReloadTrigger( dayjs().valueOf() );
        }
      },
      goToStep: async ( stepName: string, isReloadLead: boolean ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        if ( stepName === ActiveStepCore.Summary
          && actionSummaryStep !== ActiveSubStepCore.SummarySubStep1 ) {
          setActionSummaryStep( ActiveSubStepCore.SummarySubStep1 );
        }

        scrollToTopPage();
      },
      goToPersonalStep: async ( stepName: string, subStep: string | null, isReloadLead: boolean ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        setActionPersonalStep( subStep );

        scrollToTopPage();
      },
      showLoadPage: ( isShow: boolean ): void => {
        setIsShowLoadPage( isShow );
      },
      goToSummaryStep: async (
        stepName: ActiveStepCore, subStep: ActiveSubStepCore, isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          setLead( null );

          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        setActionSummaryStep( subStep );

        scrollToTopPage();
      },
      onSubmit: (): void => {
        setIsSuccess( true );
      },
      goToTopPage: (): void => {
        scrollToTopPage();
      },
      reloadLead: async ( ): Promise<void> => {
        const leadData: ILeadData = await service.getLead();
        if ( leadData ) {
          setLead( leadData );
        }

        const cloneData = cloneDeep( recalculationTariff );

        setRecalculationTariff( cloneData );
      },
      goToEditStep: async (
        stepName: string,
        groupName: string,
        isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName, groupName ) );

        if ( groupName ) {
          setTimeout( () => {
            const productTypeId = document.getElementById( `${groupName}-box` )!;

            if ( productTypeId ) {
              productTypeId.scrollIntoView( { block: 'center' } );
            }
          }, 300 );
        }
      },
      recalculationPremium: async ( isCheckedUnderwriting: boolean ): Promise<void> => {
        if ( isCheckedUnderwriting ) {
          await service.resetUnderwritingResult();
        }

        setRecalculationTariff(
          {
            recalculationTrigger: dayjs().valueOf(),
            isCheckedUnderwriting,
          },
        );
      },
      setRejected: ( rejected: boolean ): void => {
        setIsRejected( rejected );
      },
      setValidForm: ( isValid: boolean ): void => {
        setIsValidForm( isValid );
      },
      getStepsInfo: ( ): StepInfoItem[] | null => {
        return stepsInfo;
      },
      navigatorItem: ( ): NavigatorItem => {
        return navigator;
      },
      isRejected: ( ): boolean => {
        return isRejected;
      },
      isValidForm: ( ): boolean => {
        return isValidForm;
      },
      goToEditPersonalStep: async (
        stepName: string, subStep: string | null, groupName: string, isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead();
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName, groupName ) );

        setActionPersonalStep( subStep );

        scrollToTopPage();
      },
    };
  }, [ actionSummaryStep, getFullUrl, hideAlert, history, isRejected,
    isValidForm, navigator, recalculationTariff, service, stepsInfo ] );

  const onClickTabsHandler = async (): Promise<void> => {
    if( isRejected ) {
      return;
    }

    hideAlert();

    setLead( null );
    coreActions.reloadLead();
  };

  const homePageHandler = ( isGoToPortal: boolean ): void => {
    hideAlert();

    setLead( null );
    setIsSuccess( false );
    setInvalidLink( false );
    setReloadTrigger( dayjs().valueOf() );

    if ( isGoToPortal ) {
      window.location.href = mainSiteUrl;
    } else {
      history.push( '/' );
    }
  };

  const scrollToTopPage = (): void => {
    const bookingFunnelPage = document.getElementById( 'booking-funnel-page' )!;
    if ( bookingFunnelPage ) {
      bookingFunnelPage.scrollIntoView( { block: 'start' } );
    }
  };

  if ( errorMessage !== null ) {
    return (
      <div className="m-5 d-flex justify-content-center">
        <p>{ errorMessage }</p>
      </div>
    );
  }

  const loading = <div className="bf-loading dynamic-loading col-auto d-flex py-4"><LoadingSpinner /></div>;

  if ( lead == null ) {
    return loading;
  }

  const classNameToDesktopMode = (): string => {
    let className = '';
    if ( screenType.isLargeDesktop || screenType.isDesktop || screenType.isTablet ) {
      className = 'dynamic-desktop-mode';
    }

    const editProduct = getParamUrlByName( 'containerGroup' );
    if ( editProduct ) {
      className = `${className} dynamic-edit-mode`;
    }

    return className;
  };

  return (
    <DynamicCoreActionsContext.Provider value={ coreActions }>
      <Row
        id="booking-funnel-page"
        className={
          `m-0 p-0 custom-layout-4 ${ classNameToDesktopMode() }`
        }
        hidden={ isSuccess || isInvalidLink || isShowLoadPage }
      >
        <Col sm={ 12 } className="bf-page-content max-w-1128 p-0">
          <HashTabs
            className="booking-funnel-wizard d-flex justify-content-center"
            variant="tabs"
            defaultActiveKey={ stepsInfo ? stepsInfo[0].nameStep : '' }
            id="step"
            onSelect={ () => onClickTabsHandler() }
          >
            { stepsInfo && stepsInfo.map( ( item, idx ) => (
              <Tab
                key={ idx }
                eventKey={ item.nameStep }
                disabled={ ( idx >= 0 && !lead[item.nameStep] ) || isRejected }
                title={
                  <span>{ idx + 1 }</span>
                }
              >
                { item.componentType === JSONComponentType.Policy && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <PolicyStepComponent
                      productCode={ productCode }
                      lead={ lead! }
                      productData={ productData }
                      screenType={ screenType }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.PersonalData && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <PersonalDataStepComponent
                      productCode={ productCode }
                      subStep={ actionPersonalStep }
                      lead={ lead }
                      productData={ productData }
                      screenType={ screenType }
                      tenantSlug={ tenantSlug }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.Summary && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <SummaryStepComponent
                      subStep={ actionSummaryStep }
                      productCode={ productCode }
                      lead={ lead }
                      productData={ productData }
                      screenType={ screenType }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                    />
                  </React.Suspense>
                ) }
              </Tab>
            ) ) }
          </HashTabs>
        </Col>
      </Row>
      { isSuccess && (
        <SuccessPage
          addedClassName={ classNameToDesktopMode }
          homePageHandler={ homePageHandler }
        />
      ) }
      { isInvalidLink && (
        <InvalidLinkPage
          addedClassName={ classNameToDesktopMode }
          homePageHandler={ homePageHandler }
        />
      ) }
      { isShowLoadPage && (
        <LoadPage
          addedClassName={ classNameToDesktopMode }
        />
      ) }
    </DynamicCoreActionsContext.Provider>
  );
};

export default DynamicCore;
