import React, { Fragment } from 'react';
import GenderOptions from 'App/components/widgets/genderOptions';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import { isCheckedByField, renderClassNameBox } from 'App/components/widgets/dynamic-core/core-hooks';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';

export interface GenderFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const GenderField: React.FC<GenderFieldProps> = (
  { fieldValue, fieldName, stepItem, isDisabled },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const { uiType, filterOptions, label: labelItem } = stepItem || {};
  const isUiTypeSelect: boolean = uiType === JSONItemUIType.Select || false;

  let genderlist = GenderOptions;

  if ( filterOptions ) {
    genderlist = genderlist.filter( ( item ) => filterOptions?.includes( item.value ) );
  }

  const fieldLabel = React.useMemo<string>( () => {
    if ( labelItem ) {
      return labelItem;
    }

    return t( 'bookingFunnel.personalData.title' );
  }, [ labelItem, t ] );

  return (
    <Fragment>
      <Form.Label className="bf-main-label">
        { fieldLabel }
        {
          isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
          ( <AsteriskRequiredField /> )
        }
      </Form.Label>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ { required: isCheckedByField( 'isRequired', stepItem! ) } }
        defaultValue={ fieldValue || '' }
        render={ ( props ) => (
          <FormGroup className={ `custom-btn-radio mb-2 ${renderClassNameBox( stepItem ) }` }>
            { isUiTypeSelect ? (
              <div
                className={ `${props.value ?
                  'date-container' : 'date-container form-select-empty' }` }
              >
                <Form.Control
                  { ...props }
                  as="select"
                  className="form-select"
                  placeholder={ t( 'selectValues.selectValue' ) }
                  isInvalid={ errors[props.name] !== undefined }
                  disabled={ isDisabled }
                >
                  <option value="">{ t( 'selectValues.selectValue' ) }</option>
                  { genderlist.map( ( o, idx ) =>
                    <option
                      key={ idx }
                      value={ o.value }
                    >
                      { t( `bookingFunnel.personalData.${o.value}` ) }
                    </option> ) }
                </Form.Control>
              </div>
            ) : (
              <div className="core-gender">
                { genderlist.map( ( o, idx ) => {
                  return (
                    <Fragment key={ idx }>
                      <FormControl
                        { ...props }
                        className="form-check-input"
                        type="radio"
                        id={ o.value }
                        value={ o.value }
                        checked={ props.value === o.value }
                        onBlur={ props.onBlur }
                        disabled={ isDisabled }
                      />
                      <Form.Label
                        className="btn btn-border-radio"
                        htmlFor={ o.value }
                      >
                        { t( `bookingFunnel.personalData.${o.value}` ) }
                      </Form.Label>
                    </Fragment>
                  );
                } ) }
              </div> ) }
            <Form.Control
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
