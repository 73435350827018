import React, { Fragment } from 'react';
import { CFDefaultValueType, FIELD_TYPE_DATETIME, FIELD_TYPE_NUMBER, isObject } from '../../../bf-hooks';
import {
  ContainerFieldItem,
  IBFProductVariable,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { ICFFactorType } from '../../../factor-service';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';
import { CoreFactorElement } from './CoreFactorElement';
import { CoreBaseElement } from './CoreBaseElement';
import { JsonObject } from '@cover42/protobuf-util';
import { JSONItemUIType } from '../../../booking-funnel/enum';
import { HiddenField } from '../../ui-elements/fields/HiddenField';
import { EmploymentStatus } from '../../ui-elements/fields/EmploymentStatus';
import { FieldDefaultValue } from 'App/widget/types';
import { TooltipCore } from './TooltipCore';

export interface CoreFieldPanelProps {
  formData: ITariffDataStep;
  factors: ICFFactorType[];
  variable: IBFProductVariable;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled: boolean;
}

export const CoreFieldPanel: React.FC<CoreFieldPanelProps> = ( {
  formData, factors, variable, stepItem, fieldDefaultValue, isDisabled,
} ) => {
  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const getFieldValue = ( field: IBFProductVariable ): CFDefaultValueType => {
    let nameField = `${field.name}_${field.groupName ? field.groupName : field.insuredObjectId}`;
    if ( stepItem && stepItem.groupName ) {
      nameField = `${nameField}_${stepItem.groupName}`;
    }

    if ( variable.typeId === FIELD_TYPE_NUMBER && formData && !isNaN( formData[nameField] ) ) {
      return formData[nameField];
    }

    if ( formData && formData[nameField] !== undefined && formData[nameField] !== null ) {
      return formData[nameField];
    }

    const { productFieldValue } = fieldDefaultValue || {};

    if ( variable.typeId === FIELD_TYPE_DATETIME && productFieldValue ) {
      return productFieldValue as string;
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && isObject( variable.defaultValue ) ) {
      const objectDate = variable.defaultValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return undefined;
  };

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltip: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const isProductType: boolean = stepItem?.uiType === JSONItemUIType.ProductType;
  const isJobStatus: boolean = stepItem?.uiType === JSONItemUIType.JobStatusType;

  return (
    <Fragment>
      { !isProductType && variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
        <Fragment>
          { variable.isHidden ? (
            <HiddenField
              valueField={ getFieldValue( variable ) }
              variable={ variable }
              stepItem={ stepItem }
            />
          ) : (
            <Fragment>
              { isJobStatus ? (
                <EmploymentStatus
                  valField={ getFieldValue( variable ) }
                  variable={ variable }
                  factor={ factors.find( ( f ) => f.id === variable.typeId )! }
                  showTooltip={ onShowTooltip }
                />
              ) : (
                <CoreFactorElement
                  valField={ getFieldValue( variable ) }
                  variable={ variable }
                  factor={ factors.find( ( f ) => f.id === variable.typeId )! }
                  showTooltip={ onShowTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) }
            </Fragment>
          ) }
        </Fragment>
      ) }
      { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
        <CoreBaseElement
          valField={ getFieldValue( variable ) }
          variable={ variable }
          showTooltip={ onShowTooltip }
          stepItem={ stepItem }
          fieldDefaultValue={ fieldDefaultValue }
          isDisabled={ isDisabled }
        />
      ) }
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </Fragment>
  );
};
