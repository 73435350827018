import React, { Fragment } from 'react';
import closeIcon from 'assets/bf-images/funk/close_green_icon.svg';
import { Modal } from 'react-bootstrap';
import { blankLink } from 'config';

export interface PopupCoreProps {
  tooltipInfo: string;
  onClose: () => void;
}

export const TooltipPopupCore: React.FC<PopupCoreProps> = ( props ) => {
  const { tooltipInfo, onClose } = props;

  return (
    <Fragment>
      <div className="modal-open">
        <div className="fade modal-backdrop show"></div>
        <div className="fade dynamic-tooltip-modal modal show" style={ { display: 'block' } }>
          <Modal.Dialog size="lg" centered>
            <Modal.Body>
              <a
                href={ blankLink }
                role='button'
                onClick={ ( e ) => {
                  e.preventDefault();
                  onClose();
                } }
              >
                <img src={ closeIcon } className="float-right" alt="Tooltip Help" />
              </a>
              <div
                className="my-4 mx-3 bf-default-color"
                dangerouslySetInnerHTML={ { __html: `${tooltipInfo}` } }
              >
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      </div>
    </Fragment>
  );
};
