import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { useAppAlertService, AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../../booking-funnel/BookingFunnel';
import { useCoreActions } from '../../../DynamicCore';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import {
  ICustomerItem,
  IInitiateAuth,
  ILeadData,
  IRespondAuth,
  IRespondAuthResponse,
  ITariffDataStep,
  SubStepItem,
} from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { ChallengeNames } from 'Services/widgets/enums';
import { useLoginDataValidationSchemaFunk } from '../../../validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressType, COREBFStep } from '../../../enums';
import { StepInfoItem } from '../../../interfaces';
import { personalDataCoreKey } from './RegistrationCoreStep';
import { CoreCirclesNavigationMobile } from '../../core-ui-components/CoreCirclesNavigationMobile';
import { CoreSavedDataPopup } from '../../core-ui-components/CoreSavedDataPopup';
import { keyAccountCode, keyCode, keyCustomFields } from '../../../core-hooks';
import { CoreWelcomePopup } from '../../core-ui-components/CoreWelcomePopup';

export interface LoginUserProps {
  productCode: string;
  currentStep: SubStepItem;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  tenantSlug: string;
  stepData: StepInfoItem;
  isParentStep: boolean;
  policyStepNames: string[];
}

export interface LoginData {
  username: string;
  password: string;
}

export interface LoginCoreState {
  responseAuthData: IRespondAuthResponse;
  loginData: IRespondAuth;
}

export const LoginUserCoreStep: React.FC<LoginUserProps> = ( propsStep ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert, hideAlert } = useAppAlertService();
  const service = useWidgetService();
  const loginDataValidationSchema = useLoginDataValidationSchemaFunk();
  const {
    productCode,
    currentStep,
    lead,
    productData,
    tenantSlug,
    stepData,
    isParentStep,
    policyStepNames,
  } = propsStep;
  const [ passwordShown, setPasswordShown ] = React.useState<boolean>( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ isShowWelcome, setShowWelcome ] = React.useState<boolean>( false );
  const [ userInfo, setUserInfo ] = React.useState<string>( '' );
  const [ customerData, setCustomerData ] = React.useState<ICustomerItem>( {} as ICustomerItem );
  const { saveForLaterButton } = stepData || {};
  const allData = { ...propsStep, customerData };

  const successResult = currentStep.settings && currentStep.settings.successResult;

  const togglePassword = React.useCallback ( ( ): void => {
    setPasswordShown( !passwordShown );
  }, [ passwordShown ] );

  const formContext = useForm<LoginData>( {
    mode: 'onChange',
    resolver: yupResolver( loginDataValidationSchema ),
  } );

  const { errors, formState } = formContext;

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    hideAlert();

    const cliendData: ITariffDataStep = {
      username: formData.username,
      password: formData.password,
    };

    const userData: IInitiateAuth = {
      authFlow: ChallengeNames.USER_PASSWORD_AUTH,
      authParameters: {
        USERNAME: cliendData.username,
        PASSWORD: cliendData.password,
      },
      tenantSlug,
    };

    try {
      const initAuth = await service.authorization( userData );
      if( initAuth ) {
        const responseCustomer = await service.getCustomerInfo();
        const account = responseCustomer.account;
        const customFields = account.hasOwnProperty( keyCustomFields ) ? account[keyCustomFields] : {};

        delete account[keyCustomFields];

        const updatedAccount = {
          ...account,
          ...customFields,
          addressType: AddressType.AccountAddress,
        };

        if ( account.hasOwnProperty( keyAccountCode ) ) {
          await service.savedInfo( keyAccountCode, account[keyAccountCode] as string );
        }

        if ( account.hasOwnProperty( keyCode ) ) {
          await service.savedInfo( keyAccountCode, account[keyCode] as string );
        }

        await service.savedInfo( personalDataCoreKey, updatedAccount );

        // Maintaining userInfo state for compatibility with the old configs
        setUserInfo( `${updatedAccount.firstName} ${updatedAccount.lastName}` );
        setCustomerData( { ...responseCustomer } );
        setShowWelcome( true );
      }
    } catch ( error ) {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ hideAlert, service, showAlert, t, tenantSlug ] );

  const onCloseSavedPopup = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( ( ): void => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  const addClassToField = ( errorField: string | undefined ): string => {
    if ( errorField !== undefined ) {
      return 'core-input-error';
    }

    return '';
  };

  const goToPreviousStep = React.useCallback ( ( ): void => {
    if( isParentStep ) {
      actions.goToPersonalStep( stepData.nameStep, null, true );
    } else {
      actions.goToStep( stepData.previousStep!, false );
    }
  }, [ actions, isParentStep, stepData ] );

  const onCloseWelcomePopup = React.useCallback ( async (): Promise<void> => {
    const result = await service.saveLoginData( true );
    if ( result.errorCode === 0 ) {
      actions.goToStep( stepData.nextStep!, true );
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, service, showAlert, stepData.nextStep, t ] );

  if ( productData === null ) {
    return <div className="bf-loading h-100 my-5"><LoadingSpinner /></div>;
  }

  if ( lead && lead.isLogin ) {
    actions.goToStep( stepData.nextStep!, false );
  }

  return (
    <FormProvider { ...formContext }>
      <div id="login-data-step">
        <Row className="mt-3 mx-0">
          <Col md={ 12 }>
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Row>
                { currentStep && (
                  <Fragment>
                    { currentStep.title && (
                      <Col className="p-0" md={ 12 }
                        dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                      >
                      </Col>
                    ) }
                    { currentStep.subTitle && (
                      <Col className="p-0" md={ 12 }
                        dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                      >
                      </Col>
                    ) }
                  </Fragment>
                ) }
                <Col id="alert-tariff-page" md={ 12 } className="mb-4 p-0">
                  <AppAlertUI />
                </Col>
                <Col md={ 12 } className="mb-4 p-0">
                  <Controller
                    name="username"
                    rules={ {
                      required: true,
                    } }
                    defaultValue={ '' }
                    render={ ( props ) => (
                      <FormGroup
                        className={ addClassToField( errors[props.name] ) }
                        controlId={ props.name }
                      >
                        <Form.Label>{ t( 'bookingFunnel.funkMesse.login.email' ) }</Form.Label>
                        <Form.Control
                          { ...props }
                          className="core-email-input"
                          type="email"
                          placeholder={ t( 'bookingFunnel.funkMesse.login.emailPlaceholder' ) }
                          isInvalid={ errors[props.name] !== undefined }
                        />
                        <Form.Control.Feedback type="invalid">
                          { errors[props.name]?.message }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
                <Col md={ 12 } className="mb-0 p-0">
                  <Controller
                    name="password"
                    rules={ {
                      required: true,
                    } }
                    defaultValue={ '' }
                    render={ ( props ) => (
                      <FormGroup
                        className={ addClassToField( errors[props.name] ) }
                        controlId={ props.name }
                      >
                        <Form.Label>{ t( 'bookingFunnel.funkMesse.login.password' ) }</Form.Label>
                        <Row className="bf-input-container m-0">
                          <div className="input-group core-input-group">
                            <Form.Control
                              { ...props }
                              type={ passwordShown ? 'text' : 'password' }
                              placeholder={ t( 'bookingFunnel.funkMesse.login.passwordPlaceholder' ) }
                              isInvalid={ errors[props.name] !== undefined }
                            />
                            <span className="core-icon-select">
                              { props.value && (
                                <Button
                                  type="button"
                                  title={
                                    t( `bookingFunnel.funkMesse.login.${passwordShown ? 'show' : 'hide' }Password` )
                                  }
                                  variant="link"
                                  className="m-0 custom-back-button"
                                  onClick={ togglePassword }
                                >
                                  { t( `bookingFunnel.funkMesse.login.${passwordShown ? 'show' : 'hide' }Password` ) }
                                </Button>
                              ) }
                            </span>
                          </div>
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          { errors[props.name]?.message }
                        </Form.Control.Feedback>
                      </FormGroup>
                    ) }
                  />
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center mt-0 mx-0">
                <CoreCirclesNavigationMobile lead={ lead } />
              </Row>
              <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4">
                { saveForLaterButton && saveForLaterButton.enabled && (
                  <Button
                    id={ `${stepData.nextStep}_save_for_later` }
                    type="button"
                    variant="primary"
                    disabled={ formState.isSubmitting }
                    onClick={ () => onSaved( true ) }
                    className="col-sm-12 mr-0"
                  >
                    { saveForLaterButton.buttonText ?
                      saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                  </Button>
                ) }
                <Button
                  id={ `${COREBFStep.LoginStep}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  className="col-sm-12 mr-0"
                >
                  { t( 'bookingFunnel.funkMesse.login.buttonLogin' ) }
                </Button>
                <Button
                  id={ `${COREBFStep.LoginStep}_back` }
                  type="button"
                  variant="link"
                  className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                  onClick={ goToPreviousStep }
                >
                  { t( 'bookingFunnel.previousBtn' ) }
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
        { isShowSavedStep && (
          <CoreSavedDataPopup
            currentStep={ COREBFStep.LoginStep }
            productCode={ productCode }
            productData={ productData! }
            onClose={ () => onCloseSavedPopup( ) }
            goNextStep={ goNextStep }
            policyStepNames={ policyStepNames }
          />
        ) }
        { isShowWelcome && (
          <CoreWelcomePopup
            userName={ userInfo }
            onClose={ () => onCloseWelcomePopup( ) }
            successResult={ successResult }
            allData={ allData }
          />
        ) }
      </div>
    </FormProvider>
  );
};
