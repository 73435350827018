import React, { Fragment } from 'react';
import { isBoolean } from 'lodash';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, PhoneInputSettings } from 'Services/widgets/interfaces';
import { AsteriskRequiredField } from 'App/components/widgets/AsteriskRequiredField';
import {
  isCheckedByField,
  renderClassNameBox,
  renderPlaceholder,
} from 'App/components/widgets/dynamic-core/core-hooks';
import { blankLink } from 'config';
import { TooltipCore } from '../../../core-ui-components/TooltipCore';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import PhoneInput, { Country, isValidPhoneNumber } from 'react-phone-number-input';
import de from 'react-phone-number-input/locale/de.json';
import en from 'react-phone-number-input/locale/de.json';

export interface PhoneInputAccountFieldProps {
  fieldValue: string;
  fieldName: string;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export const PhoneInputAccountField: React.FC<PhoneInputAccountFieldProps> = ( {
  fieldValue,
  fieldName,
  stepItem,
  isDisabled,
  isReadOnly,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const { settings } = stepItem || {};
  const { international, defaultCountry, countries } = settings as PhoneInputSettings || {};

  const lng = i18n.language;

  let conuntryList: Country[] | undefined = undefined;
  if ( countries && typeof countries === 'string' ) {
    conuntryList = countries.split( ',' ) as unknown as Country[];
  }

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltip: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltip );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const isValidatePhone = ( value: string ): string | boolean => {
    if ( !value ) {
      return true;
    }

    if ( !isValidPhoneNumber( value ) ) {
      const errMessage = t( 'base:forms.messages.fieldInvalid', { fieldLabel } );
      return errMessage;
    }

    return true;
  };

  const renderClassName = (): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    return `${classNameField}${readOnly}`;
  };

  const fieldLabel = React.useMemo<string>( () => {
    if ( stepItem && stepItem.label ) {
      return stepItem.label;
    }

    return t( `bookingFunnel.personalData.${fieldName}` );
  }, [ fieldName, stepItem, t ] );

  const getDefaultValue = (): string => {
    if ( fieldValue ) {
      return fieldValue;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string;
    }

    return '';
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled ) {
      const defaultValue = stepItem.defaultValue ? stepItem.defaultValue : fieldValue;

      setValue( fieldName, defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, fieldValue, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            return isValidatePhone( value );
          },
        } }
        defaultValue={ getDefaultValue() }
        render={ ( props ) => (
          <FormGroup className={ renderClassNameBox( stepItem ) } controlId={ props.name }>
            <Form.Label className={ `bf-main-label${stepItem!.tooltip ? ' tooltip-label' : '' }` }>
              { fieldLabel }
              {
                isCheckedByField( 'showAsterisk', stepItem! ) && isCheckedByField( 'isRequired', stepItem! ) &&
                ( <AsteriskRequiredField /> )
              }
              { stepItem && stepItem.tooltip && (
                <a
                  className="tooltip-info"
                  id={ `tooltip-${props.name}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    onShowTooltip( true, stepItem.tooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { conuntryList?.length === 1 ? (
              <Input
                type="text"
                className={ `form-control ${renderClassName()}` }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
                disabled={ isDisabled }
                international={ international }
                smartCaret={ true }
                limitMaxLength={ true }
                labels={ lng === 'en' ? en : de }
                defaultCountry={ defaultCountry ? defaultCountry as unknown as Country : undefined }
                countries={ conuntryList }
                value={ props.value }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
              />
            ) : (
              <PhoneInput
                type="text"
                className={ `${renderClassName()}${ errors[props.name] !== undefined ? ' is-invalid' : '' }` }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( fieldLabel, stepItem ) }
                disabled={ isDisabled }
                international={ international }
                smartCaret={ true }
                limitMaxLength={ true }
                labels={ lng === 'en' ? en : de }
                defaultCountry={ defaultCountry ? defaultCountry as unknown as Country : undefined }
                countries={ conuntryList }
                value={ props.value }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
              />
            ) }
            <Form.Control
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </Fragment>
  );
};
