import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_STRING,
  FIELD_TYPE_DATETIME,
  CFDefaultValueType,
  FIELD_TYPE_DURUNIT,
} from '../../../bf-hooks';
import { BooleanField } from '../../ui-elements/fields/BooleanField';
import { StringField } from '../../ui-elements/fields/StringField';
import { NumberField } from '../../ui-elements/fields/NumberField';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { BaseFields, InputType } from 'Services/widgets/enums';
import { CorePolicyStartDateField } from './fields/CorePolicyStartDateField';
import { DateUnitField } from './fields/DateUnitField';
import { DatePickedField } from './fields/DatePickedField';
import { FieldDefaultValue } from 'App/widget/types';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { CheckboxField } from '../../ui-elements/fields/CheckboxField';
import { RadioField } from '../../ui-elements/fields/RadioField';
import { PolicyStartDateField } from '../../ui-elements/fields/PolicyStartDateField';
import { BirthDateField } from '../../ui-elements/fields/BirthDateField';
import { DateField } from '../../ui-elements/fields/DateField';
import { HiddenField } from '../../ui-elements/fields/HiddenField';
import { isObject } from 'App/components/widgets/bf-hooks';
import { JsonObject } from '@cover42/ts-contracts';
import { PhoneInputField } from '../../ui-elements/fields/PhoneInputField';

export interface CoreBaseElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const CoreBaseElement: React.FC<CoreBaseElementProps> = (
  { valField, variable, showTooltip, stepItem, fieldDefaultValue, isDisabled },
) => {
  const isDefaultDateField = React.useCallback( ( nameField: string ): boolean => {
    const excludedFields: string[] = [
      BaseFields.PolicyStartDate,
      BaseFields.PolicyEndDate,
      BaseFields.BirthDateDe,
      BaseFields.BirthDateEn,
    ];

    if ( excludedFields.includes( nameField ) ) {
      return false;
    }

    return true;
  }, [ ] );

  const isBirthDateField = React.useCallback( ( nameField: string ): boolean => {
    const includedFields: string[] = [ BaseFields.BirthDateDe, BaseFields.BirthDateEn ];
    if ( includedFields.includes( nameField ) ) {
      return true;
    }

    return false;
  }, [ ] );

  const getFromDate = React.useCallback( ( ): string => {
    if ( isObject( variable.minValue ) ) {
      const objectDate = variable.minValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    return dayjs().subtract( 120, 'years' ).format();
  }, [ variable ] );

  const getToDate = React.useCallback( ( isBirthDate: boolean = false ): string => {
    if ( isObject( variable.maxValue ) ) {
      const objectDate = variable.maxValue as JsonObject;
      if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
        return objectDate.evaluated as string;
      }
    }

    let toDate = dayjs().add( 24, 'months' ).format();

    if ( isBirthDate ) {
      toDate = dayjs().subtract( 18, 'years' ).format();;
    }

    return toDate;
  }, [ variable.maxValue ] );

  const isCheckbox: boolean = stepItem?.uiType === JSONItemUIType.Checkbox;
  const isReactDayPicker: boolean = stepItem?.uiType === JSONItemUIType.ReactDayPicker || false;
  const isRadio: boolean = stepItem?.uiType === JSONItemUIType.Radio;

  return (
    <Fragment>
      { variable.isHidden ? (
        <HiddenField
          valueField={ valField }
          variable={ variable }
          fieldDefaultValue={ fieldDefaultValue }
        />
      ) : (
        <Fragment>
          { variable.typeId === FIELD_TYPE_STRING && (
            <Fragment>
              { stepItem && stepItem.inputType === InputType.PhoneInput ? (
                <PhoneInputField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) : (
                <StringField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) }
            </Fragment>
          ) }
          { !isReactDayPicker ? (
            <Fragment>
              { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyStartDate && (
                <CorePolicyStartDateField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  isDisabled={ isDisabled }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyEndDate && (
                <DatePickedField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate( false ) }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  nameFieldToCheck={ BaseFields.PolicyStartDate }
                  isDisabled={ isDisabled }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && isDefaultDateField( variable.name ) && (
                <DatePickedField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate( false ) }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  isDisabled={ isDisabled }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && isBirthDateField( variable.name ) && (
                <DatePickedField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate( true ) }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  isDisabled={ isDisabled }
                />
              ) }
            </Fragment>
          ) : (
            <Fragment>
              { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyStartDate && (
                <PolicyStartDateField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && variable.name === BaseFields.PolicyEndDate && (
                <DateField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate() }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  nameFieldToCheck={ BaseFields.PolicyStartDate }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && isDefaultDateField( variable.name ) && (
                <DateField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate() }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                />
              ) }
              { variable.typeId === FIELD_TYPE_DATETIME && isBirthDateField( variable.name ) && (
                <BirthDateField
                  valueField={ valField }
                  variable={ variable }
                  fromDate={ getFromDate() }
                  toDate={ getToDate( true ) }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                />
              ) }
            </Fragment>
          ) }
          { variable.typeId === FIELD_TYPE_DURUNIT && isDefaultDateField( variable.name ) && (
            <DateUnitField
              valueField={ valField }
              variable={ variable }
              showTooltip={ showTooltip }
              stepItem={ stepItem }
              isDisabled={ isDisabled }
            />
          ) }
          { variable.typeId === FIELD_TYPE_NUMBER && (
            <NumberField
              valueField={ valField }
              variable={ variable }
              showTooltip={ showTooltip }
              stepItem={ stepItem }
              fieldDefaultValue={ fieldDefaultValue }
              isDisabled={ isDisabled }
            />
          ) }
          { variable.typeId === FIELD_TYPE_BOOL && (
            <Fragment>
              { isCheckbox && (
                <CheckboxField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) }
              { isRadio && (
                <RadioField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) }
              { !isRadio && !isCheckbox && (
                <BooleanField
                  valueField={ valField }
                  variable={ variable }
                  showTooltip={ showTooltip }
                  stepItem={ stepItem }
                  fieldDefaultValue={ fieldDefaultValue }
                  isDisabled={ isDisabled }
                />
              ) }
            </Fragment>
          ) }
        </Fragment>
      ) }
    </Fragment>
  );
};
