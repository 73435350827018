/* eslint-disable no-console */

export enum TrackEventType {
  DropdownSelection = 'DROPDOWN_SELECTION',
  RadioSelection = 'RADIO_SELECTION'
}

const gtag = window['gtag'];
const hsq = window['_hsq'];
const ga = window['ga'];

// Utility functions to detect GA4, Universal Analytics, and HubSpot
export const isGA4Loaded = () => typeof gtag === 'function';
export const isUniversalAnalyticsLoaded = () => typeof ga === 'function';
export const isHubSpotLoaded = () => typeof hsq === 'object' && hsq.push;

export const trackStepLoadedInGA = ( stepNumber: number, stepName: string ) => {
  if ( isGA4Loaded() ) {
    gtag( 'event', 'step_loaded', {
      event_category: 'Booking Funnel',
      event_label: `Step ${stepNumber} - ${stepName}`,
      value: stepNumber,
    } );
  } else if ( isUniversalAnalyticsLoaded() ) {
    ga( 'send', 'event', 'Booking Funnel', 'step_view', `Step ${stepNumber}` );
  } else {
    console.warn( 'Google Analytics not found on the page.' );
  }
};

export const trackStepLoadedInHubSpot = ( stepNumber: number, stepName: string ) => {
  if ( isHubSpotLoaded() ) {
    hsq.push( [ 'trackEvent', {
      id: `step_loaded_${stepNumber}`,
      value: `Step ${stepNumber} - ${stepName}`,
      eventType: 'STEP_LOADED',
    } ] );
  } else {
    console.warn( 'HubSpot tracking not found on the page' );
  }
};

export const trackFactorSelectionGA = (
  stepNumber: number, stepName: string, trackEventType: TrackEventType, selectedName: string, selectedValue: string,
) => {
  if ( isGA4Loaded() ) {
    gtag( 'event', trackEventType.toLocaleLowerCase(), {
      event_category: 'Booking Funnel',
      event_label: `${stepName} - ${selectedName} selected`, // it is label
      value: selectedValue, // Optional, use this for numeric values // it is label, not key
      step: stepNumber, // Include the step number for analysis
      dropdown_field: selectedName, // The name of the dropdown, label field
      selected_value: selectedValue, // The selected option in the dropdown, label factor
    } );
  } else if ( isUniversalAnalyticsLoaded() ) {
    ga(
      'send',
      'event',
      'Booking Funnel',
      trackEventType.toLocaleLowerCase(),
      `Step ${stepNumber} - ${selectedName} selected, Value: ${selectedValue}`,
    );
  }
};

export const trackFactorSelectionInHubSpot = (
  stepNumber: number, stepName: string, trackEventType: TrackEventType, selectedName: string, selectedValue: string,
) => {
  if ( isHubSpotLoaded() ) {
    hsq.push( [ 'trackEvent', {
      id: `${trackEventType.toLocaleLowerCase()}_${stepNumber}_${selectedName}`,
      value: `${selectedName}: ${selectedValue}`,
      eventType: trackEventType,
      step: stepNumber,
      dropdown_field: selectedName, // it is label field
      selected_value: selectedValue, // it is label factor
    } ] );
  }
};
