import React, { Fragment } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { CFDefaultValueType, isObject } from '../../../bf-hooks';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import { blankLink } from 'config';
import { ContainerFieldItem } from 'Services/widgets/interfaces';
import { JSONItemUIType } from '../../../booking-funnel/enum';
import { isRequiredField } from '../../../booking-funnel/booking-funnel-hooks';
import { useCoreActions } from '../../DynamicCore';
import { isEmpty } from 'lodash';
import { checkedUnderwriting } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { ICFFactorType, ICFFactorTypeOption } from 'App/components/widgets/factor-service';
import { CoreSelectElement } from './fields/CoreSelectElement';
import { MultiSelectElement } from '../../ui-elements/fields/MultiSelectElement';
import { FieldDefaultValue } from 'App/widget/types';
import { renderClassNameBox, renderFieldLabel } from '../../core-hooks';
import { Separators } from 'Services/widgets/enums';
import { trackFactorSelectionGA, trackFactorSelectionInHubSpot, TrackEventType } from '../../analytics-hooks';

export type FieldType = 'radio' | 'select';

export interface CoreFactorElementProps {
  valField: CFDefaultValueType;
  variable: IBFProductVariable;
  factor: ICFFactorType;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const CoreFactorElement: React.FC<CoreFactorElementProps> = ( {
  valField,
  variable,
  factor,
  showTooltip,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};

  const isUITypeRadio = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.Radio;
  const isUITypeMultiSelect = stepItem && stepItem.uiType && stepItem.uiType === JSONItemUIType.MultiSelect;

  const factorValues = React.useMemo<ICFFactorTypeOption[]>( () => {
    if ( factor && factor.values ) {
      if ( stepItem && stepItem.sortedKeys ) {
        const sortedKeys: string[] = stepItem.sortedKeys ? stepItem.sortedKeys.split( Separators.Comma ) : [];

        if ( sortedKeys.length ) {
          const newFactors: ICFFactorTypeOption[] = [];
          const factors = factor.values;

          sortedKeys.forEach( ( keyName ) => {
            const foundItem = factors.find( ( f ) => ( f.key as string ).toLowerCase() === keyName.toLowerCase() );

            if ( foundItem ) {
              newFactors.push( foundItem );
            }
          } );

          return newFactors;
        }
      }

      return [ ...factor.values ].sort( ( a, b ) => {

        if ( stepItem && stepItem.sortByLabel ) {
          return a.name.localeCompare( b.name, undefined, { numeric: true, sensitivity: 'base' } );
        }

        const aKey = parseInt( a.key as string );
        const bKey = parseInt( b.key as string );

        return isUITypeRadio ? bKey - aKey : aKey - bKey;
      } );
    }
    return [];

  }, [ factor, isUITypeRadio, stepItem ] );

  const getDefaultValue = React.useCallback(
    ( items: ICFFactorTypeOption[], typeField: FieldType, keyItem?: JsonValue ):
    ICFFactorTypeOption[] | ICFFactorTypeOption | string => {
      if ( valField ) {
        if ( items.length && typeof valField === 'string' && isUITypeMultiSelect ) {
          const values = valField ? JSON.parse( valField ) : [];
          const factorList = items.filter( ( f ) => values.includes( String( f.key ) ) );

          return factorList ? factorList : '';
        }
        if ( isObject( valField ) && items.length && valField.hasOwnProperty( 'key' ) && typeField === 'radio' ) {
          return valField.key ? valField.key : valField;
        }

        if ( items.length && typeField === 'select' ) {
          const value = typeof valField === 'object' ? valField.key : valField;
          const findValue = items.find( ( i ) => i.key === value || i.name === value )!;

          return findValue ? findValue : '';
        }

        return valField;
      }

      if ( items.length && productFieldValue ) {
        return productFieldValue as string;
      }

      if ( items.length && stepItem && stepItem.defaultValue ) {
        const findValue = items.find( ( i ) => i.key === stepItem.defaultValue )!;
        if ( typeField === 'radio' ) {
          return findValue ? String( findValue.key ) : '';
        }

        return findValue ? findValue : '';
      }

      if ( items.length && keyItem ) {
        const findValue = items.find( ( i ) => i.key === keyItem )!;
        if ( typeField === 'radio' ) {
          return findValue ? String( findValue.key ) : '';
        }

        return findValue ? findValue : '';
      }

      return '';

    }, [ isUITypeMultiSelect, productFieldValue, stepItem, valField ] );

  const getMultiDefaultValue = React.useCallback( ( multiValue: ICFFactorTypeOption[] | string ): string => {
    if ( multiValue && typeof multiValue === 'string' ) {
      return multiValue;
    }

    if ( multiValue && multiValue.length ) {
      const values: string[] = [];
      const valueList = multiValue as ICFFactorTypeOption[];

      valueList.forEach( ( item ) => {
        values.push( item.key as string );
      } );

      return values.length ? JSON.stringify( values ) : '';
    }

    return '';

  }, [] );

  const onRecalculation = React.useCallback( ( factorItem: ICFFactorTypeOption ): void => {
    if ( isEmpty( errors ) ) {
      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting );

      const steps = actions.getStepsInfo();
      const currentStepInfo = actions.getCurrentStep ? actions.getCurrentStep() : '';
      const currentNameStep = currentStepInfo && currentStepInfo.nameStep ?
        currentStepInfo.nameStep : steps![0].nameStep;

      const selectedStep = steps?.find( ( item ) => item.nameStep === currentNameStep );
      if ( selectedStep && factorItem ) {
        const idx = steps?.findIndex( ( item ) => item.nameStep === currentNameStep ) || 0;

        trackFactorSelectionGA(
          idx+1,
          selectedStep.nameStep,
          TrackEventType.RadioSelection,
          factorItem.name,
          factorItem.key as string,
        );

        trackFactorSelectionInHubSpot(
          idx+1,
          selectedStep.nameStep,
          TrackEventType.RadioSelection,
          factorItem.name,
          factorItem.key as string,
        );
      }
    }
  }, [ actions, errors, stepItem ] );

  const isDisabledField = React.useCallback ( ( ): boolean | undefined => {
    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return isDisabled;
  }, [ isDisabled, stepItem ] );

  return (
    <Fragment>
      { factor && (
        <Fragment>
          { isUITypeRadio ?
            <Fragment>
              <Form.Label id={ `${variable.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                <div
                  className="d-inline-block"
                  dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                />
                { variable.bfTooltip && (
                  <a
                    id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                    href={ blankLink }
                    role='button'
                    onClick={ ( e ) => {
                      e.preventDefault();
                      showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                    } }
                  >
                    { t( 'bookingFunnel.tooltipHelp' ) }
                  </a>
                ) }
              </Form.Label>
              { variable.bfDescription && (
                <span className="gm-info f-12 mt-1">
                  { variable.bfDescription }
                </span>
              ) }
              <Controller
                name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                control={ control }
                rules={ { required: isDisabled ? false : isRequiredField( variable, stepItem ) } }
                defaultValue={ getDefaultValue( factorValues, 'radio', variable.defaultValue ) }
                render={ ( props ) => (
                  <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name}` +
                    `${factorValues.length === 3 ? ' btn-col-3' : ' btn-col-2'}`
                  }
                  >
                    { factorValues.map( ( f, idx ) => {
                      return (
                        <Fragment key={ idx }>
                          <div className="box-radio">
                            <FormControl
                              { ...props }
                              className="form-check-input"
                              type="radio"
                              disabled={ isDisabledField() }
                              id={ `option_${ props.name + '_' + f.key}` }
                              value={ String( f.key ) }
                              checked={ props.value === String( f.key ) }
                              onChange={ ( e ) => {
                                props.onChange( e );

                                onRecalculation( f );
                              } }
                              onBlur={ props.onBlur }
                            />
                            <Form.Label
                              className="btn btn-border-radio"
                              htmlFor={ `option_${ props.name + '_' + f.key}` }
                            >
                              { f.name }
                            </Form.Label>
                          </div>
                        </Fragment>
                      );
                    } ) }
                    <Form.Control
                      id={ `error-messages-${props.name}` }
                      type="hidden"
                      isInvalid={ errors[props.name] !== undefined }
                    />
                    <FormControl.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: variable.label,
                      } ) }
                    </FormControl.Feedback>
                  </FormGroup>
                ) }
              />
            </Fragment> : (
              <Controller
                name={ `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}` }
                control={ control }
                rules={ { required: isDisabled ? false : isRequiredField( variable, stepItem ) } }
                defaultValue={ getDefaultValue( factorValues, 'select', variable.defaultValue ) }
                render={ ( props ) => (
                  <FormGroup
                    controlId={ props.name }
                    className={
                      `${ renderClassNameBox( stepItem ) }${errors[props.name] ? ' core-input-error' : '' }`
                    }
                  >
                    <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                      <div
                        className="d-inline-block"
                        dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                      />
                      { variable.bfTooltip && (
                        <a
                          id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                          href={ blankLink }
                          role='button'
                          onClick={ ( e ) => {
                            e.preventDefault();
                            showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                          } }
                        >
                          { t( 'bookingFunnel.tooltipHelp' ) }
                        </a>
                      ) }
                    </Form.Label>
                    { variable.bfDescription && (
                      <span className="gm-info f-12 mt-1">
                        { variable.bfDescription }
                      </span>
                    ) }
                    { isUITypeMultiSelect ? (
                      <Fragment>
                        <MultiSelectElement
                          nameField={ props.name }
                          selectValue={ props.value }
                          listValues={ factorValues }
                          stepItem={ stepItem! }
                          isDisabled={ isDisabledField() }
                        />
                        <Form.Control
                          { ...props }
                          value={ getMultiDefaultValue( props.value ) }
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <CoreSelectElement
                          nameField={ props.name }
                          selectValue={ props.value }
                          listValues={ factorValues }
                          stepItem={ stepItem! }
                          isDisabled={ isDisabledField() }
                        />
                        <Form.Control
                          type="hidden"
                          isInvalid={ errors[props.name] !== undefined }
                        />
                      </Fragment>
                    ) }
                    <FormControl.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: variable.label,
                      } ) }
                    </FormControl.Feedback>
                  </FormGroup>
                ) }
              />
            )
          }
        </Fragment>
      ) }
    </Fragment>
  );
};
