import React, { Fragment } from 'react';
import { blankLink } from 'config';
import { JsonValue } from '@cover42/protobuf-util';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem, PhoneInputSettings } from 'Services/widgets/interfaces';
import { checkedUnderwriting, isRequiredField } from '../../../booking-funnel/booking-funnel-hooks';
import { AsteriskRequiredField } from '../../../AsteriskRequiredField';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';
import { isBoolean } from 'lodash';
import { renderFieldLabel, renderPlaceholder } from '../../core-hooks';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import PhoneInput, { Country, isValidPhoneNumber } from 'react-phone-number-input';
import de from 'react-phone-number-input/locale/de.json';
import en from 'react-phone-number-input/locale/de.json';

export interface PhoneInputFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const PhoneInputField: React.FC<PhoneInputFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue } = useFormContext();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const { settings } = stepItem || {};
  const { international, defaultCountry, countries } = settings as PhoneInputSettings || {};

  const lng = i18n.language;

  let conuntryList: Country[] | undefined = undefined;
  if ( countries && typeof countries === 'string' ) {
    conuntryList = countries.split( ',' ) as unknown as Country[];
  }

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    if ( productFieldValue ) {
      return productFieldValue as string;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string || '';
    }

    return stringValue !== null ? stringValue as string : '';
  };

  const isValidatePhone = ( value: string ): string | boolean => {
    if ( !value ) {
      return true;
    }

    if ( !isValidPhoneNumber( value ) ) {
      const errMessage = t( 'base:forms.messages.fieldInvalid', { fieldLabel: variable.label } );
      return errMessage;
    }

    return true;
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    return `${classNameField}${readOnly}`;
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            return isValidatePhone( value );
          },
        } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup className={ stepItem?.classNameBox! } controlId={ props.name }>
            <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </Form.Label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            { conuntryList?.length === 1 ? (
              <Input
                type="text"
                className={ `form-control ${renderClassName()}` }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( variable.label, stepItem ) }
                disabled={ isDisabled }
                international={ international }
                smartCaret={ true }
                limitMaxLength={ true }
                labels={ lng === 'en' ? en : de }
                defaultCountry={ defaultCountry ? defaultCountry as unknown as Country : undefined }
                countries={ conuntryList }
                value={ props.value }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
                onBlur={ ( ) => {
                  const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                  actions.recalculationPremium( isCheckedUnderwriting );
                } }
              />
            ) : (
              <PhoneInput
                type="text"
                className={ `${renderClassName()}${ errors[props.name] !== undefined ? ' is-invalid' : '' }` }
                readOnly={ isReadOnlyInput() }
                placeholder={ renderPlaceholder( variable.label, stepItem ) }
                disabled={ isDisabled }
                international={ international }
                smartCaret={ true }
                limitMaxLength={ true }
                labels={ lng === 'en' ? en : de }
                defaultCountry={ defaultCountry ? defaultCountry as unknown as Country : undefined }
                countries={ conuntryList }
                value={ props.value }
                onChange={ ( e ) => {
                  props.onChange( e );
                } }
                onBlur={ ( ) => {
                  const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
                  actions.recalculationPremium( isCheckedUnderwriting );
                } }
              />
            ) }
            <Form.Control
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
