import React, { Fragment, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { parseHashUrl } from '../../../bf-hooks';
import { useCoreActions } from '../../DynamicCore';
import { CoreNavItem } from '../../ui-components/MobileNavCore';
import { prepareMobilNav } from '../../core-hooks';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';
import { blankLink } from 'config';
import { ILeadData } from 'Services/widgets/interfaces';
import { NavigatorType } from 'Services/widgets/enums';

export interface CirclesNavDataProps {
  defaultActiveKey?: string;
  lead: ILeadData;
  showAllSteps?: boolean;
}

export const CoreCirclesNavigationMobile: React.FC<CirclesNavDataProps> = ( {
  defaultActiveKey, lead, showAllSteps,
} ) => {
  const location = useLocation();
  const urlHash: string = location.hash.substring( 1 );
  const hashParams = parseHashUrl( urlHash );
  const actions = useCoreActions();
  const stepsInfo = actions.getStepsInfo();
  const navigator = actions.navigatorItem();
  const isValidForm = actions.isValidForm();

  let itemList: CoreNavItem[] = [];

  if ( stepsInfo ) {
    const navList = prepareMobilNav( stepsInfo );
    itemList = navList.filter( ( i ) => i.componentType !== JSONComponentType.System )
      .map( ( item, idx ) => {
        return {
          ...item,
          numberStep: idx+1,
        };
      } );
  }

  const getCurrentStep = React.useCallback( (): CoreNavItem => {
    let currentHash = defaultActiveKey || itemList[0].hashStep;

    if ( hashParams && hashParams[0] ) {
      currentHash = hashParams[0].value;
    }

    const currentItem = itemList.find( ( c ) => c.hashStep === currentHash )!;
    return currentItem;
  }, [ defaultActiveKey, hashParams, itemList ] );

  const currentStep = getCurrentStep();

  const onClickItem = React.useCallback( ( stepName: string, disabledLink: boolean ) => {
    if ( disabledLink || !isValidForm ) {
      return;
    }

    actions.goToStep( stepName, true );
  }, [ actions, isValidForm ] );

  const isActiveItem = React.useCallback( ( nameHash: string ) => {
    const params = hashParams.filter( ( param ) => param.value === nameHash );

    if ( params.length > 0 ) {
      return true;
    }

    if ( hashParams.length === 0 ) {
      return nameHash === itemList[0].hashStep;
    }

    return false;
  }, [ hashParams, itemList ] );

  const mobileNavItems = React.useMemo<ReactElement[] | null>( () => {
    if ( !itemList ) {
      return null;
    }

    const isEditLead = lead['isEditLead'];

    return itemList.map( ( item, idx ): ReactElement => {
      let isDisabled: boolean = false;
      if ( lead && !lead[item.hashStep!] && !isEditLead ) {
        isDisabled = true;
      }

      const index = idx+1;
      if ( currentStep ) {
        if ( currentStep.numberStep < index && !showAllSteps && !isEditLead ) {
          isDisabled = true;
        }
      }

      const isActive: boolean = isActiveItem( item.hashStep );
      const classDisabled = !isActive && isDisabled ? 'disabled-link' : '';
      return (
        <Link
          to={ blankLink }
          id={ `bf-menu-item-${item.numberStep}` }
          title={ `Step ${item.numberStep}` }
          key={ item.numberStep }
          onClick={ ( e ) => {
            e.preventDefault();
            onClickItem( item.hashStep, isDisabled );
          } }
          className={ `${isActive ? 'active' : classDisabled }` }
        >
          <span>{ index }</span>
        </Link>
      );
    } );
  }, [ currentStep, isActiveItem, itemList, lead, onClickItem, showAllSteps ] );

  if ( !currentStep || navigator.type !== NavigatorType.Circles ) {
    return <></>;
  }

  return (
    <Fragment>
      { mobileNavItems && (
        <div id="mobile-steps">
          <div className={ `d-flex justify-content-center ${NavigatorType.Circles}` }>
            { mobileNavItems }
          </div>
        </div>
      ) }
    </Fragment>
  );
};
